import { html, css } from 'UX';
import { Fetcher } from 'Utils';
import App from 'App';
import SelectMultiple from 'Components/SelectMultiple.js';

const IMG_SELECT_WIDTH = 40;
const IMG_SELECT_HEIGHT = 40;
const IMG_WIDTH = 80;
const IMG_HEIGHT = 80;

class SelectComponents extends SelectMultiple {
  static get styles() {
    return [
      super.styles,
      css`      
        .flex {
          display:flex;
          gap:10px;
          align-items:flex-start;
          height:100%;
        }

        .tags {
          padding:10px;
          height:100%;
          width:100px;
          display:flex;
          flex-direction:column;
          gap:5px;
        }

        sl-tag {
          cursor:pointer;
        }

        .items {
          width:100%;
          border-left:1px solid var(--sl-color-gray-300);
        }

        .hidden {
          display:none;
        }
      `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/products_components';
  }

  sortLoadedItems(items) {
    if (!items || !items.length) return [];
    items.sort((a, b) => {
      if (a.title_fr < b.title_fr) return -1;
      if (a.title_fr > b.title_fr) return 1;
      return 0;
    });
    return items;
  }

  async loadTags() {
    const response = await Fetcher.get(`private/admin/tags`);
    this.tags = response?.data;
    this.tags.sort((a, b) => {
      if (a.title_fr < b.title_fr) return -1;
      if (a.title_fr > b.title_fr) return 1;
      return 0;
    });
  }

  async visibleCallback() {
    super.visibleCallback();
    await this.loadTags();
    this.requestUpdate();
  }

  getItemTitle(item) {
    let key = `title_${App.config.lg}`;
    const title = item[key] || item.title_fr;
    return html`${title}`;
  }

  getFirstImage(item) {
    if (!item.images?.length) return;   
    return html`<img-proxy slot="prefix" width="${IMG_WIDTH}" height="${IMG_HEIGHT}" src="${item.images[0].src}"></img-proxy>`;
  }

  getFirstImageSelect(item) {
    if (!item.images?.length) return;
    return html`<img-proxy slot="prefix" width="${IMG_SELECT_WIDTH}" height="${IMG_SELECT_HEIGHT}" src="${item.images[0].src}"></img-proxy>`;
  }

  tagFilter(ev) {
    const currentTagEl = ev.target;
    const variantSelected = 'primary';
    const variantUnselected = 'neutral';
    const tagId = currentTagEl.tagId;
    if (!tagId) {
      // all tag
      this.shadowRoot.querySelectorAll('.tags sl-tag').forEach((tag) => {
        tag.variant = variantUnselected;
      });
      currentTagEl.variant = 'primary';
      this.displayedItems = [ ...this.loadedItems ];
      this.requestUpdate();
      return;
    }

    // reset "all" tag
    this.shadowRoot.querySelectorAll('.tags sl-tag[all="true"]').forEach((tag) => {
      tag.variant = variantUnselected;
    });


    if (currentTagEl.variant === variantSelected) {
      currentTagEl.variant = variantUnselected;

      // check if all tags are unselected
      let allTagsUnselected = true;
      this.shadowRoot.querySelectorAll('.tags sl-tag').forEach((tag) => {
        if (tag.variant === variantSelected) {
          allTagsUnselected = false;
        }
      });

      if (allTagsUnselected) {
        const allTag = this.shadowRoot.querySelector('.tags sl-tag');
        allTag.variant = variantSelected;
        this.displayedItems = [ ...this.loadedItems ];
        this.requestUpdate();
        return;
      }
    } else {
      currentTagEl.variant = variantSelected;
    }

    // get tagId of all tags having primary variant
    const selectedTags = [];
    this.shadowRoot.querySelectorAll('.tags sl-tag').forEach((tag) => {
      if (tag.variant === variantSelected) {
        selectedTags.push(tag.tagId);
      }
    });

    const displayedItems = [];
    for (let item of this.loadedItems) {
      item.tags = item.tags || [];
      let foundMatchingTag = false;
      for (const tag of item.tags) {
        if (selectedTags.includes(tag._id)) {
          foundMatchingTag = true;
        }
      }
      if (!foundMatchingTag) {
        item = { ...item };
        item.hide = true;
      }

      displayedItems.push(item);
    }

    this.displayedItems = displayedItems;
    this.requestUpdate();
  }

  displayItems() {
    if (!this.displayedItems?.length) return '';

    return html`
      <div class="flex">
        ${this.tags && this.tags.length > 0 
          ? html`
          <div class="tags">
            <sl-tag variant="primary" all="true" @click=${this.tagFilter}>Tous</sl-tag>
            ${this.tags.map((tag) => html`
              <sl-tag  
                variant="neutral" 
                .tagId=${tag._id} 
                @click=${this.tagFilter}
              >${tag.title_fr}</sl-tag> `
            )}
          </div>`
          : ''
        }
        <div class="items">
          ${this.displayedItems.map((item) => html`
            <sl-option value="${item._id.toString()}" class="${item.hide ? 'hidden': ''}">
              ${this.getFirstImageSelect(item)}
              <div class="item">
                <div>${this.getItemTitle(item)}</div>
              </div>
            </sl-option>
          </div>
        </div>
      `)}
    `;
  }
  
  render() {
    // Do not remove that, because select initiale value will not work without it
    if (!this.displayedItems) return html`<sl-select size="${this.size}" placeholder="..."></sl-select>`;
    if (!this.displayedItems.length) return html`Aucun composant disponible, veuillez en ajouter.`;
    
    return html`
      <sl-select placement="bottom" placeholder="${this.placeholder}"
        size="${this.size}"
        @sl-change=${this._onChange}
        value=${this.value}
        ${this.name ? `name="${this.name}"` : ''}
        multiple
        clearable
      >${this.displayItems()}
      </sl-select>
    `;
  }

}

customElements.define('select-product-components', SelectComponents);