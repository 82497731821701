import { html, css } from 'UX';
import { Fetcher } from 'Utils';
import App from 'App';
import Sortable from 'sortablejs';

import '../components/select.js';
import DrawerForm from 'Components/DrawerForm.js';

const IMG_WIDTH = 40;
const IMG_HEIGHT = 40;


class AdminProductEdit extends DrawerForm {
  static get styles() {
    return [
      super.styles,
      css`
        .grid3 {
          width:100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap:5px;
        }

        .input_with_translate {
          width:100%;
          gap:5px;
          display: grid;
          grid-template-columns: 1fr 75px;
          height:56px;
          overflow:hidden;
        }

        .seeme {
          position: absolute;
          right:10px;
          top:5px;
          color:red;
          cursor:pointer;
          z-index:1;
        }

        .select_components {
          display: grid;
          width:100%;
          gap:10px;
          grid-template-columns: 0.99fr;
          position:absolute;
        }

        .select_components m-icon {
          font-size: 30px;
          margin-top: 4px;
          cursor:pointer;
        }

        table.components {
          width:97%;
          border-collapse: collapse;
          text-align:left;
          margin-left:auto;
          margin-right:auto;
          font-size:0.85em;
        }

        table.components td {
          padding: 10px;
          border:1px solid var(--sl-color-neutral-300);
        }

        table.components tbody tr:nth-child(odd) td {
          background-color: var(--sl-color-neutral-50);
        }

        table.components td:first-child {
          padding: 0;
          width:40px;
        }

        table.components td.icon {
          width:30px;
          padding:0;
          text-align:center;
        }

        img-proxy {
          margin:5px;
        }
        `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/products';
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this.urlVar = 'idp';
    this.eventUpdated = 'product-updated';

    this._logEnable = false;
    this._i18nResources = {
      english:{
        translation: {
          cancel:'Cancel',
          add:'Add a product',
          edit:'Edit',
          save:'Save'
        },
      },
      french:{
        translation: {
          cancel:'Annuler',
          add:'Ajouter un produit',
          edit:'Modifier',
          save:'Enregistrer'
        }
      }
    }
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    this.initSortable();
  }

  getDiscountPercentage(item) {
    const originalPrice = parseFloat(item.price);
    const discountedPrice = parseFloat(item.price_promo);
    return (((originalPrice - discountedPrice) / originalPrice) * 100).toFixed(2);
  }

  getItemTitle() {
    return this.item.title_fr;
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    } else if (response.reason === 'MIN_VALUE') {
      msg = `Valeur minimum de ${response.minValue}`;
    } else if (response.reason === 'LOWER_THAN_PRICE') {
      msg = 'Le prix promotion doit être inférieur au prix normal';
    } else if (response.reason === 'PROMO_PRICE_REQUIRED') {
      msg = 'Le prix promotion est requis si le produit est en promotion';
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();

    if (input.textarea?.setCustomValidity) input.textarea.setCustomValidity(msg);
    if (input.textarea?.reportValidity) input.textarea.reportValidity();
  }

  async translate(ev) {
    
    const target = ev.target;
    target.loading = true;
    target.disabled = true;

    const payload = {
      title: this.modal.querySelector('[name="title_fr"]').value,
      desc: this.modal.querySelector('[name="desc_fr"]').value
    }

    const response = await Fetcher.post('private/admin/products/translate', payload);
    target.loading = false;
    target.disabled = false;
    if (!response) return;

    this.modal.querySelector('[name="title_en"]').value = response.data.title;
    this.modal.querySelector('[name="desc_en"]').value = response.data.desc;
  }

  seeme() {
    window.open(`/public/shop/product/${this.item._id}`);
  }

  getComponentFirstImage(component) {
    if (!component.images?.length) return;
    return html`<img-proxy width="${IMG_WIDTH}" height="${IMG_HEIGHT}" src="${component.images[0].src}"></img-proxy>`;
  }

  getComponentTitle(component) {
    const key = `title_${App.config.lg}`;
    return component[key] || component.title_fr;
  }

  getComponentDescription(component) {
    const key = `desc_${App.config.lg}`;
    return component[key] || component.desc_fr;
  }
  
  moveComponentUp(index) {
    if (index > 0) {
      const components = [...this.item.components];
      const temp = components[index - 1];
      components[index - 1] = components[index];
      components[index] = temp;
      this.item.components = components;
    }
    this.requestUpdate();
    this.compareFields();
  }

  moveComponentDown(index) {
    if (index < this.item.components.length - 1) {
      const components = [...this.item.components];
      const temp = components[index + 1];
      components[index + 1] = components[index];
      components[index] = temp;     
      this.item.components = components;
      this.requestUpdate();
      this.compareFields();
    }
  }

  deleteComponent(index) {
    if (index >= 0 && index < this.item.components.length) {
      const components = [...this.item.components];  
      components.splice(index, 1);
      this.item.components = components;
      this.requestUpdate();
      this.compareFields();
    }
  }

  editComponent(index) {
    const component = this.item.components[index];
    window.open('/private/admin/products?idc=' + component._id, '_blank');
  }

  initSortable() {
    const el = this.shadowRoot.querySelector('tbody');
    if (el) {
      Sortable.create(el, {
        animation: 150,
        onEnd: (evt) => {
          const [movedItem] = this.item.components.splice(evt.oldIndex, 1);
          this.item.components.splice(evt.newIndex, 0, movedItem);
          this.compareFields();
        }
      });
    }
  }

  renderComponents() {
    if (!this.item?.components) return;

    return html`
      <table class="components">
        <tbody>
          ${this.item.components.map((component, index) => html`
            <tr>
              <td>${this.getComponentFirstImage(component)}</td>
              <td>
                <b>${this.getComponentTitle(component)}</b><br/>
              </td>
              <td class="icon">
                <sl-dropdown>
                  <m-icon slot="trigger" name="more_vert"></m-icon>  
                  <sl-menu>
                    <sl-menu-item ?disabled=${index === 0} @click=${() => this.moveComponentUp(index)}>
                      <m-icon slot="prefix" name="keyboard_arrow_up"></m-icon>
                      Monter
                    </sl-menu-item>
                    <sl-menu-item ?disabled=${index === this.item.components.length - 1} @click=${() => this.moveComponentDown(index)}>
                      <m-icon slot="prefix" name="keyboard_arrow_down"></m-icon>
                      Descendre
                    </sl-menu-item>
                    <sl-divider></sl-divider>
                    <sl-menu-item @click=${() => this.editComponent(index)}>
                      <m-icon slot="prefix" name="edit"></m-icon>
                      Modifier
                      <m-icon slot="suffix" name="open_in_new"></m-icon>
                    </sl-menu-item>
                    <sl-menu-item  @click=${() => this.deleteComponent(index)}>
                      <m-icon slot="prefix" name="delete"></m-icon>
                      Supprimer
                    </sl-menu-item>
                  </sl-menu>
              </td>
            </tr>
          `)}
        </tbody>
      </table>
    `;
  }

  getTranslatePayload() {
    const payload = {
      title: this.modal.querySelector('[name="title_fr"]').value,
      desc: this.modal.querySelector('[name="desc_fr"]').textarea.value,
    }

    return payload;
  }

  onTranslated(ev) {
    this.modal.querySelector('[name="title_en"]').value = ev.detail.title;
    this.modal.querySelector('[name="desc_en"]').value = ev.detail.desc;
  }

  async handleImagesChanged(event) {
    this._log.debug('handleImagesChanged', event.detail);
    this.item.images = event.detail;
    this.compareFields();
  }

  async handleComponentsChanged(event) {
    this._log.debug('handleComponentsChanged', event.detail);
    this.item.components = event.detail;
    this.requestUpdate();
    this.compareFields();
  }


  render() {

    if (!this.item) return '';

    this._log.debug('render', this.item);

    return html`
      <modal-drawer name="modal-product-edit" @onHide=${this.onHide}>
        <style>${this.constructor.styles}</style>
        <form>
          <m-icon class="seeme" name="preview" nogradient title="Voir le produit" @click=${this.seeme}></m-icon>
          <sl-tab-group class="main">
            <sl-tab slot="nav" panel="text">Descriptif</sl-tab>
            <sl-tab slot="nav" panel="properties">Propriétés</sl-tab>
            <sl-tab slot="nav" panel="photos">Images</sl-tab>
            <sl-tab slot="nav" panel="components">Composants</sl-tab>

            <sl-tab-panel name="text">
              <scrollable-component scrollbar-visibility="always" >
                <sl-tab-group>
                  <sl-tab slot="nav" panel="french">🇫🇷 Français</sl-tab>
                  <sl-tab slot="nav" panel="english">🇺🇸 Anglais</sl-tab>

                  <sl-tab-panel name="french">
                    <sl-input size="small" name="title_fr" defaultValue="" value="${this.item.title_fr}" placeholder="Titre"></sl-input><br/>
                    <markdown-editor resize="auto" size="small" name="desc_fr" defaultValue="" value=${this.item.desc_fr} label="Description">${this.item.desc_fr}</markdown-editor>
                  </sl-tab-panel>

                  <sl-tab-panel name="english">
                    <div class="input_with_translate">
                      <sl-input size="small" name="title_en" defaultValue="" value="${this.item.title_en}" placeholder="Title"></sl-input>
                      <button-translate .getPayload=${this.getTranslatePayload} @translated=${this.onTranslated}></button-translate>
                    </div>
                    <markdown-editor resize="auto" size="small" name="desc_en" defaultValue="" value=${this.item.desc_en} label="Description">${this.item.desc_en}</markdown-editor>
                    <br/>
                  </sl-tab-panel>
                </sl-tab-group>
              </scrollable-component>
            </sl-tab-panel>

            <sl-tab-panel name="properties">
              <scrollable-component scrollbar-visibility="always">
                <div class="grid3">
                  <sl-input name="price" class="number" label="Prix" type="number" defaultValue="1" value=${this.item.price} min="0" help-text="En euros" size="small"></sl-input>
                  <sl-input name="price_promo" class="number" label="Prix promotion" type="number" defaultValue="" min="0" value=${this.item.price_promo} help-text="En euros" size="small"></sl-input>
                </div>
                <br/>
                <div class="grid3">
                  <sl-input name="qte" class="number" label="Quantité en stock" type="number" defaultValue="1" value=${this.item.qte} min="0" size="small"></sl-input>
                  <sl-input name="weight" class="number" label="Poids" type="number" value=${this.item.weight} min="0" defaultValue="1" size="small" help-text="En grammes"></sl-input>
                </div>
                <br/>
                <div class="grid3">
                  <sl-input name="length" class="number" label="Longueur" type="number" min="0" defaultValue="" value=${this.item.length} size="small" help-text="En centimetres"></sl-input>
                  <sl-input name="width" class="number" label="Largeur" type="number" min="0" defaultValue="" value=${this.item.width} size="small"></sl-input>
                  <sl-input name="height" class="number" label="Hauteur" type="number" min="0" defaultValue="" value=${this.item.height} size="small"></sl-input>
                </div>

                <sl-divider></sl-divider>
                <sl-switch 
                  name="enable"
                  size="small"
                >Afficher sur le site</sl-switch>
                <br/><br/>

                <sl-switch 
                  name="display_as_new"
                  size="small"
                  help-text="Afficher dans la section nouveautés"
                >Nouveauté</sl-switch>

                <br/><br/>
                <sl-switch 
                  name="promo" 
                  size="small"
                >En promotion</sl-switch>
              </scrollable-component>

            </sl-tab-panel>

            <sl-tab-panel name="components">
              <scrollable-component scrollbar-visibility="always">
                <select-product-components
                  size="small"
                  name="components"
                  placeholder="Choisissez dans la liste" 
                  multiple 
                  .items=${this.item.components}
                  @items-changed=${this.handleComponentsChanged} 
                ></select-product-components>
                <br/>
                ${this.renderComponents()}
              </scrollable-component>
            </sl-tab-panel>

            <sl-tab-panel name="photos">
              <scrollable-component scrollbar-visibility="always">
                <image-uploader 
                  name="images"
                  .value=${this.item.images}
                  @value-changed=${this.handleImagesChanged}
                ></image-uploader>
              </scrollable-component>
            </sl-tab-panel>

          </sl-tab-group>
        </form>
        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" disabled @click="${this.handleSubmit}">${this._i18n('save')}</sl-button>
      </modal-drawer>
    `;
  }

}

customElements.define('admin-product-edit', AdminProductEdit);