import { PageElement, html, css } from 'UX';
import { Session } from 'Utils';

import './user/account';
import './admin';
import './doc/api';
import './doc/faq';
import './doc/videos';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`

        m-icon[name="menu"] {
          font-size:30px;
          margin-right:5px;
        }

        .grid {
          display:grid;
          grid-template-columns: repeat(6, 1fr);
          margin-right:20px;
          gap:20px;
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
    this._i18nResources = {
      english:{
        translation: {
        },
      },
      french:{
        translation: {
        }
      }
    }
  }

  renderMyAccount() {
    return html`
      <section-header size="medium" micon="account_circle" aroute="/private/user/account">Mon compte</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="id_card" aroute="/private/user/account#personalinfos">Informations personnelles</box-styled>
        <box-styled layout="${this.layout}" micon="password" aroute="/private/user/account#security_auth">Authentification</box-styled>
        <box-styled layout="${this.layout}" micon="acute" aroute="/private/user/account#security_activity">Activité</box-styled>
        <box-styled layout="${this.layout}" micon="notifications" aroute="/private/user/account#notifications">Notifications</box-styled>
      </div>
      <br/>
    `;
  }

  renderAdmin() {
    if (!Session.isBoss()) return '';
    return html`
      <section-header size="medium" micon="manufacturing">Administration du site</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="animated_images" aroute="/private/doc/videos">Tutoriels vidéos</box-styled>
        <box-styled layout="${this.layout}" micon="storefront" aroute="/private/admin/products">Gestion des créations</box-styled>
        <box-styled layout="${this.layout}" micon="bring_your_own_ip" aroute="/private/admin/website/sessions">Sessions en cours</box-styled>
        <box-styled layout="${this.layout}" micon="settings_backup_restore" aroute="/private/admin/website/backup_restore">Sauvegarde et restauration</box-styled>
      </div>
      <br/>
    `;
  }

  renderPublicDoc() {
    return html`
      <section-header size="medium" micon="description">Documentations</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="quiz" aroute="/private/doc/faq">FAQ</box-styled>
        <box-styled layout="${this.layout}" micon="chat_error" aroute="/public/errors">Messages d'erreur</box-styled>
      </div>
      <br/>
    `
  }


  render() {
    return html`
      <section-header micon="deployed_code_account">Mon espace</section-header>
      <br/>
      <br/>
      ${this.renderMyAccount()}<br/>
      ${this.renderAdmin()}<br/>
      ${this.renderPublicDoc()}<br/>
      
      <!--
      <section-header size="big"><m-icon nogradient slot="left" name="deployed_code_account"></m-icon>Big title</section-header><br/><br/>
      <section-header size="medium"><m-icon nogradient slot="left" name="deployed_code_account"></m-icon>Medium title</section-header><br/><br/>
      <section-header size="normal"><m-icon nogradient slot="left" name="deployed_code_account"></m-icon>Normal title</section-header><br/><br/>
      <section-header size="small"><m-icon nogradient slot="left" name="deployed_code_account"></m-icon>Small title</section-header><br/><br/>
      <section-header size="vsmall"><m-icon nogradient slot="left" name="deployed_code_account"></m-icon>Very small</section-header><br/><br/>
      -->
      <br/><br/><br/><br/>
    `;
  }

}

customElements.define('page-user-home', Page);