import { BaseElement, html, css } from 'UX';
import { Fetcher, Session } from 'Utils';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import localStyles from './styles.js';
import dayjs from 'dayjs';
import App from 'App';

class SecurityActivity extends BaseElement {
  static get styles() {
    return [
      localStyles,
      css`
        .box {
          line-height:30px;
          width:100%;
          border-top:1px solid var(--sl-color-gray-200);
          border-bottom:1px solid var(--sl-color-gray-200);
        }

        .flex {
          display:flex;
          justify-content:space-between;
        }

        .title {
          font-weight:bold;
        }

        table {
          width:100%;
          font-size:14px;
        }

        thead td {
          font-weight:bold;
        }

        table td {
          text-overflow:ellipsis;
          vertical-align: baseline;
        }

        table td:first-child {
          white-space:nowrap;
        }


        m-icon[name="check"] {
          color:green;
          font-size:20px;
          line-height:15px;
        }

        m-icon[name="warning"] {
          color:darkorange;
          font-size:20px;
          line-height:15px;
        }

        a {
          text-decoration:none;
          color:var(--sl-color-primary-500);
        }

        a:hover {
          text-decoration:underline;
        }

        m-icon[name="cached"] {
          color:var(--sl-color-gray-400);
        }

        m-icon[name="cached"]:hover {
          color:var(--sl-color-primary-500);
        }

        table m-icon {
          float:left;
          margin-right:5px;
        }

        /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
        @media (max-width: 670px) {
          table {
            font-size:0.9em;
            width:96vw;
          }
        }

      `
    ];
  }

  static get properties() {
    return {
      session: { type: Object },
      activity: { type: Object }
    }
  }

  constructor() {
    super();
    this.session = null;
    this.activity = null;
    this._i18nResources = {
      english:{
        translation: {
          title: 'Activity',
          current_session: 'Current session',
          ip_address:'IP Address',
          date:'Date',
          date_start: 'Start date',
          last_access: 'Last access',
          events:'Events',
          no_event:'No event found.',
          refresh:'Refresh',
          events_list:{
            LOGIN: 'Login',
            LOGIN_ERROR: 'Login error',
            LOGOUT: 'Logout',
            UPDATE_PASSWORD: 'Update password',
            CODE_TO_TOKEN_ERROR: 'Token error',
            UPDATE_PROFILE: 'Update personnals informations',
            VERIFY_EMAIL: 'Verify email',
            SEND_VERIFY_EMAIL: 'Send verify email',
            REGISTER: 'Register'
          }
        }
      },
      french:{
        translation: {
          title: 'Activité',
          current_session: 'Session en cours',
          ip_address:'Adresse IP',
          date:'Date',
          date_start: 'Date de début',
          last_access: 'Dernier accès',
          events:'Evenements',
          no_event:'Aucun événement trouvé.',
          refresh:'Rafraîchir',
          events_list:{
            LOGIN: 'Connexion',
            LOGIN_ERROR: 'Echec de connexion',
            LOGOUT: 'Déconnexion',
            UPDATE_PASSWORD: 'Changement de mot de passe',
            CODE_TO_TOKEN_ERROR: 'Erreur de token',
            UPDATE_PROFILE: 'Modifications des informations personnelles',
            VERIFY_EMAIL: 'Vérification de l\'email',
            SEND_VERIFY_EMAIL: 'Envoi de l\'email de vérification',
            REGISTER: 'Inscription'
          }
        }
      }
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.refresh();
    
  }

  async refresh(ev) {
    let target;
    if (ev) {
      target = ev.target.tagName === 'M-ICON' ? target = ev.target.parentElement : target = ev.target;
      target.loading = true;
    }
    this.session = await Session.get();
    const response = await Fetcher.get('private/user/account/device_activity');
    if (response && response.data) {
      this.activity = response.data;

      const ignoreEvents = ['CODE_TO_TOKEN', 'REFRESH_TOKEN', 'REFRESH_TOKEN_ERROR' ];
      this.activity.events = this.activity.events.filter(event => !ignoreEvents.includes(event.type));
    }

    if (ev) {
      setTimeout(() => {
        if (target) target.loading = false;
      }, 1000);
    }
    //this.requestUpdate();
  }

  async handleSubmitInfos(ev) {
  }

  formatTimestamp(timestamp) {
    if (App.config.language === 'french') {
      return dayjs(timestamp).locale('fr').format('DD/MM/YYYY HH:mm:ss');
    } else {
      return dayjs(timestamp).format('MM/DD/YYYY HH:mm:ss');
    }
  }

  formatEvent(id) {
    if (id === 'LOGIN') {
      return '<m-icon name="check"></m-icon> '+this._i18n(`events_list.${id}`);
    }

    if (id === 'LOGIN_ERROR') {
      return '<m-icon name="warning"></m-icon> '+this._i18n(`events_list.${id}`);
    }
  
    if (id === 'LOGOUT') {
      return '<m-icon name="check"></m-icon> '+this._i18n(`events_list.${id}`);
    }

    if (id === 'CODE_TO_TOKEN_ERROR') {
      return '<m-icon name="warning"></m-icon> '+this._i18n(`events_list.${id}`);
    }

    return '<m-icon name="check"></m-icon> '+this._i18n(`events_list.${id}`);
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  render() {
    if (!this.session || !this.activity) return;

    
    return html`
      <section-header size="medium" micon="acute">
        ${this._i18n('title')}
        <sl-button slot="right" variant="text" size="small" title="${this._i18n('refresh')}" @click="${this.refresh}"><m-icon name="cached"></sl-button>
      </section-header>
      <br/><br/>
      <form>
        <section-header size="normal" bold>${this._i18n('current_session')}</section-header><br/>
        <table>
          <thead>
            <tr>
              <td>${this._i18n('ip_address')}<//td>
              <td>${this._i18n('date_start')}</td>
              <td>${this._i18n('last_access')}</td>
            </tr>
          </thead>
          <tbody>
          ${this.activity.sessions.map(session => html`
            <tr>
              <td><a-href-confirm href="https://www.abuseipdb.com/check/${session.ipAddress}" target="abuseipdb">${session.ipAddress}</a-href-confirm></td>
              <td>${this.formatTimestamp(session.start)}</td>
              <td>${this.formatTimestamp(session.lastAccess)}</td>
            </tr>
          `)}
          </tbody>
        </table>
        <br/><br/>
        <section-header size="normal" bold>${this._i18n('events')}</section-header>
        <table>
          <thead>
            <tr>
              <td>${this._i18n('ip_address')}</td>
              <td>${this._i18n('date')}<//td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
          ${this.activity.events.filter(event => event.type !== 'CODE_TO_TOKEN').length === 0 ? html`
            <tr>
              <td colspan="3">${this._i18n('no_event')}</td>
            </tr>
          ` : this.activity.events.filter(event => event.type !== 'CODE_TO_TOKEN').map(event => html`
            <tr>
              <td><a-href-confirm href="https://www.abuseipdb.com/check/${event.ipAddress}" target="abuseipdb">${event.ipAddress}</a-href-confirm></td>
              <td>${this.formatTimestamp(event.time)}</td>
              <td>${unsafeHTML(this.formatEvent(event.type))}</td>
            </tr>
          `)}
          </tbody>
        </table>
      </form>
      <br/><br/>
    `
  }

}

customElements.define('form-user-account-security-activity', SecurityActivity);