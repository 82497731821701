import '../../pages/public/index.js';
import '../../pages/private/index.js';

import { Session } from 'Utils';
import { v4 as uuidv4 } from 'uuid';

const publicRoutes = {
  '/public':                              { component: 'page-home',                         title:'ArtGoOne' },
  '/public/notifications':                { component: 'page-notifications',                title:'Notifications' },
  '/public/user/logout':                  { component: 'page-user-logout',                  title:'Déconnexion' },
  '/public/forbidden':                    { component: 'error-forbidden',                   title:'Accès refusé' },
  '/public/errors':                       { component: 'error-list',                        title:'Messages d\'erreur' },
  '/public/shop':                         { component: 'page-shop',                         title:'La boutique' },
  '/public/shop/product/:id':             { component: 'page-shop-product',                 title:'' },
};

const authenticatedRoutes = {
  '/private':                             { component: 'page-user-home',                    title:'Mon espace', action:isAuthenticated },
  '/private/user/account':                { component: 'page-user-account',                 title:'Mon compte', action:isAuthenticated },
  '/private/doc/api':                     { component: 'page-doc-api',                      title:'Documentation API', action:isBoss },
  '/private/doc/faq':                     { component: 'page-doc-faq',                      title:'FAQ' },
  '/private/doc/videos':                  { component: 'page-doc-videos',                   title:'Vidéos', action:isBoss },
}

const adminRoutes = {
  '/private/admin':                       { component: 'page-admin-home',                   title:'Administration', action:isBoss },
  '/private/admin/customers':             { component: 'page-admin-customers',              title:'Clients', action:isBoss },
  '/private/admin/products':              { component: 'page-admin-products',               title:'Produits', action:isBoss },
  '/private/admin/components':            { component: 'page-admin-product-components',     title:'Composants', action:isBoss },
  '/private/admin/website/sessions':      { component: 'page-admin-website-sessions',       title:'Sessions', action:isBoss },
  '/private/admin/website/backup_restore':{ component: 'page-admin-website-backup_restore', title:'Sauvegarde & restauration', action:isBoss },
}

function menuToRoutes(menu, parentPath  = '') {
  return Object.entries(menu).flatMap(([path, item]) => {
    const fullPath = parentPath+path;
    const route = { path: fullPath };
    if (item.title) route.title = item.title;
    if (item.component) route.component = item.component;
    if (item.redirect) route.redirect = item.redirect;
    if (item.action) route.action = item.action;
    route.animate = true;

    if (item.pages) {
      const childRoutes = menuToRoutes(item.pages, fullPath);
      return [route, ...childRoutes];
    } else {
      return [route];
    }
  });
}

async function isAuthenticated(ctx, cmd) {
  if (!Session.isAuthenticated()) {
    return cmd.component('error-forbidden');
  }
  return true;
}

async function isBoss(ctx, cmd) {
  if (!Session.isAuthenticated() || !Session.isBoss()) {
    return cmd.component('error-forbidden');
  }
  return true;
}

// because we can not specify title of the webapp while installing it on IOS
if (ENV === 'dev') {
  authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne DD';
} else if (ENV === 'preprod') {
  authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne PP';
} else {
  authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne';
}

const routes = menuToRoutes(publicRoutes);
routes.animate = true;

// redirect / to /public
routes.unshift({ path: '/', redirect: '/public' });

// handle 404
routes.push({ path: '(.*)', title: 'Page non trouvée', component: 'error-not-found'});

function getOrCreateDeviceID() {
  let deviceID = localStorage.getItem('deviceID');
  if (!deviceID) {
      deviceID = uuidv4();
      localStorage.setItem('deviceID', deviceID);
  }
  return deviceID;
}

window.addEventListener('session-refreshed', ev => {
  let routes;
  if (Session.isAuthenticated()) {
    routes = menuToRoutes(authenticatedRoutes);
  }

  if (Session.isBoss()) {
    routes = menuToRoutes(adminRoutes);
  }
});


const config = {
  basePath: '/',
  routes,
  language:localStorage.getItem('language') || 'french',
  deviceId: getOrCreateDeviceID(),
  localKeys:{
    theme:'theme',
    language:'language'
  },
  get lg() {
    if (config.language === 'french') {
      return 'fr';
    } else {
      return 'en';
    }
  } 

}


export default config;
