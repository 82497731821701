import { css } from 'lit-element';

export default css`
  :host {
    display:none;
  }

  form {
    padding-left:10px;
    flex:1;
    overflow:hidden;
  }

  h3 {
    border-bottom:1px solid var(--sl-color-gray-200);
  }

  .warning {
    background-color:var(--sl-color-warning-100);
    padding:10px;
    font-size:0.9em;
    line-height:30px;
  }

  .warning m-icon {
    font-size:30px;
    float:left;
    margin-right:10px;
  }

  .comment {
    font-size:1.2em;
    color:var(--sl-color-neutral-500);
  }

  .label-on-left {
    --label-width: 3.75rem;
    --gap-width: 1rem;
  }

  .label-on-left + .label-on-left {
    margin-top: var(--sl-spacing-medium);
  }

  .label-on-left::part(form-control) {
    display: grid;
    grid: auto / var(--label-width) 1fr;
    gap: var(--sl-spacing-3x-small) var(--gap-width);
    align-items: center;
  }

  .label-on-left::part(form-control-label) {
    text-align: right;
    font-size: var(--sl-font-size-medium);
  }

  .label-on-left::part(form-control-help-text) {
    grid-column-start: 2;
  }

`;
