import { html, css } from 'UX';
import TableBasic from 'Components/TableBasic.js';
import { Fetcher } from 'Utils';

class BackupRestorePage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        .red {
          color:var(--sl-color-danger-500);
          font-weight:bold;
          text-align:center;
          margin:20px;
        }
      `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/website/backup_restore';
    this.urlIdParam = 'idb';
    this.itemTitleFieldName = 'name';

    this._i18nResources = this._i18nMergeResources({
      english: {
        translation: {
          title:'Backup & Restore',
          empty:'No backup available',
          backup:'Backup',
          backup_upload:'Upload a backup',
          restore:'Restore',
          restore_confirm:'Confirm restoration',
          file:'file',
          backup_confirm:'Confirm backup creation',
          download:'Download'
        },
      },
      french: {
        translation: {
          title:'Sauvegarde & Restauration',
          empty:'Aucune sauvegarde disponible',
          backup:'Sauvegarder',
          backup_upload:'Téléverser une sauvegarde',
          restore_confirm:'Confirmer la restauration',
          restore:'Restaurer',
          file:'fichier',
          backup_confirm:'Confirmer la création de la sauvegarde',
          download:'Télécharger'
        }
      }
    });
  }

  renderNoItem() {
    if (this.items && this.items.length) return;
    return html`
      <div class="center">
        <p>${this._i18n('empty')}</p>
      </div>
    `;
  }

  _getItemTitle(item) {
    return item.name;
  }

  _getTableHeader() {
    const count = this.items.length;
    const text = count > 1 ? `${this._i18n('file')}s` : this._i18n('file');
    return ` ${count} ${text}`;
  }


  backupConfirm() {
    this.modalBackup = this.modalBackup || this.shadowRoot.getElementById('modal-backup');
    this.modalBackup.show();
  }

  async doBackup(ev) {
    this.modalBackup.loadingButtons();
    await Fetcher.post(this.apiEndpoint);
    this.modalBackup.resetButtons();
    this.modalBackup.hide();
    this.loadItems();
  }

  restoreConfirm(ev, item) {
    this.item = item;
    this.modalRestore = this.modalRestore || this.shadowRoot.getElementById('modal-restore');
    this.modalRestore.querySelector('.itemTitle').textContent = item.name;
    this.modalRestore.show();
  }

  async doRestore() {
    this.modalRestore.loadingButtons();
    await Fetcher.put(`${this.apiEndpoint}/${this.item.name}`);
    this.modalRestore.resetButtons();
    this.modalRestore.hide();
    this.loadItems();
  }

  sendBackup() {
    this.shadowRoot.getElementById('fileInput').click();
  }

  downloadBackup(ev, item) {
    const url = `/api/v1/${this.apiEndpoint}/${item.name}`;
    document.location.href = url;
  }

  async _handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await Fetcher.upload('private/admin/upload/backup', formData);
      this.loadItems();
    }
  }

  _renderHeader() {
    return html`
      <section-header size="vsmall">
        ${this.loading 
          ? this._renderLoader()
          : html`
            ${this._getTableHeader()}
            <div slot="right">
              <sl-button variant="primary" size="small" @click=${this.backupConfirm}>${this._i18n('backup')}</sl-button>
              <sl-button variant="primary" size="small" @click=${this.sendBackup}>${this._i18n('backup_upload')}</sl-button>
            </div>`
        }
      </section-header>`
  }

  formatSize(size) {
    const units = ['octets', 'Ko', 'Mo', 'Go', 'To'];
    let unitIndex = 0;
    let formattedSize = size;

    while (formattedSize >= 1024 && unitIndex < units.length - 1) {
      formattedSize /= 1024;
      unitIndex++;
    }

    return `${formattedSize.toFixed(2)} ${units[unitIndex]}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    const euro = String.fromCharCode(8364);

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th>Fichier</th>
            <th>Taille</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${item.name}</td>
            <td>${this.formatSize(item.size)}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this.downloadBackup(ev, item)}">
                    <m-icon slot="prefix" name="download"></m-icon>
                    ${this._i18n('download')}
                  </sl-menu-item>
                  <sl-menu-item @click="${(ev) => this.restoreConfirm(ev, item)}">
                    <m-icon slot="prefix" name="repartition"></m-icon>
                    ${this._i18n('restore')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._i18n('del')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderNoItem() {
    if (this.loading || this.items?.length) return;
    return html`<div class="center">
      <br/><br/>
      ${this._i18n('empty')}
      <br/><br/>
    </div>`;
  }

  render() {    
    return html`
      <section-header backroute="/private" micon="settings_backup_restore">${this._i18n('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderDeleteModal()}

      <input type="file" id="fileInput" accept=".zip" style="display: none;" @change="${this._handleFileSelect}">

      <modal-dialog id="modal-backup" modal>
        ${this._i18n('backup_confirm')} ?
        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.doBackup}>${this._i18n('backup')}</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-restore" modal>
        ${this._i18n('restore_confirm')} &laquo;<span class="itemTitle"></span>&raquo;?
        <div class="red">
          Attention !!!!!<br/><br/>
          Cette action écrasera les données actuelles du site<br/>
          pour les remplacer par celles de la sauvegarde.<br/>
        </div>
        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.doRestore}>${this._i18n('restore')}</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('page-admin-website-backup_restore', BackupRestorePage);