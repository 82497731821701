import { FullPageElement, html, css } from 'UX';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

class PageHome extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      css`        

      .background_home {
        background: linear-gradient(90deg, rgb(87 83 64) 0%, rgb(253 198 116) 50%, rgb(72 73 54) 100%);
        min-height:30vh;
        display:flex;
        position:relative;
      }

      .background_home_img {
        position:absolute;
        display:flex;
        min-height:30vh;
        width:100%;
        background-size: cover;
        background-image: url('assets/img/visuals/home3.webp');
        opacity: 0;
        animation: fadeIn 5s forwards;
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .big_title {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, transparent 00%, #111111bb 20%, #111111bb 80%, transparent 100%);
        padding:10px;
        width:100%;
        color:white;
        text-transform:uppercase;
        text-shadow: 1px 1px 1px #000000;
        font-size:2em;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:top;
        flex-direction:column;
        filter: drop-shadow(0px 0px 5px #000000);
      }

      .big_title div {
        overflow: hidden;
        margin: 0 auto;
        /*
        white-space: nowrap;
        animation: typing 2.5s steps(40, end);
        */
      }

      .subtitle {
        padding:10px;
        flex:1;
      }

      .subtitle b {
        font-size:1.2em;
        line-height:1.8em;
      }

      sl-carousel::part(base) {
        gap:10px;
      }
      
      sl-carousel-item a {
        position:absolute;
        top:0px;
        left:0px;
        right:0px;
        background-color:#000000aa;
        backdrop-filter:blur(3px);
        color:white;
        text-align:center;
        padding:10px;
        color:white;
        font-size:1em;
        text-decoration:none;
        cursor:pointer;
      }

      sl-carousel-item a:hover {
        text-decoration:underline;
      }

      .center {
        text-align:center;
        padding-top:20px;
        padding-bottom:30px;
      }

      u {
        font-size:1.2em;
      }

      .grid {
        display:grid;
        grid-template-columns: repeat(3, 1fr); /* Crée 3 colonnes de taille égale */
        margin:10px;
      }

      @media (max-width: 670px) {
        .big_title {
          font-size:1.4em;
        }

        .subtitle {
          font-size:0.8em;
        }

        .background_home {
          min-height: 25vh;
        }

        .background_home_img {
          min-height: 25vh;
          background-position: center;
        }

      }

      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          main:{
            title:`Co-creators of quantum tools<br/>with magnetic bio-resonance`,
            title2:`Handmade creation of shape waves based on sacred geometry, 
              Allowing to resonate with our true nature of BEING consciousness.
              <br/>
              Rebalance, re-harmonize our energy field,<br/>our sphere of consciousness, by vibratory/wave effect.<br/>
              <br/>
              Realization in resin according to the principles of orgone,<br/>
              effectiveness extended to any biological system<br/>
              (places, humans, animals, plants, crystals)<br/>
              <br/>
              Incorporation at the heart of the structure:<br/>
              resin, natural ceramic, neodymium magnets (rare earths),<br/>scalar antennas, lithotherapy, metals, natural pigments, plant elements...

              <br/><br/><strong>Looking forward to sharing !</strong>`,

          },
          carousel:{
            shop:'Our creations',
            blog:'The blog',
          },
        }
      },
      french:{
        translation: {
          main:{
            title:`Co-créateurs d'outils quantiques<br/>à bio-resonnance magnétique`,
            title2:`<br/><b>Fabrication artisanale d'ondes de forme</b><br/>
              Basées sur la géométrie sacrée, ces créations font résonner notre nature véritable d'ÊTRE conscience.
              Rééquilibrer, ré-harmoniser notre champ énergétique, notre sphère de conscience, par effet vibratoire/ondulatoire.<br/>
              <br/>
              <b>Créations en résine selon les principes de l'orgone</b><br/>
              Efficacité élargie à tout système biologique : lieux, humains, animaux, végétaux, cristaux<br/>
              <br/>
              <b>Incorporation au coeur des structures</b><br/>
              Résine, céramique naturelle, aimants néodyme (terres rares), antennes scalaires, lithothérapie, métaux, pigments naturels, éléments végétaux, ..<br/>
              `,
          },
          carousel:{
            shop:'Nos créations',
            blog:'Le blog',
          },
        }
      }
    }
  }
  
  visibleCallback() {
    setTimeout(() => {
      this.shadowRoot.querySelector('.background_home').classList.add('img');
    }, 1000);
  }

  render_blog() {
    const articles = [
      {
        img: 'assets/products/plateau ISSIS gros plan dessus2 .JPG',
        title: 'Plateau Vitalisant ISIS',
      },
      {
        img: 'assets/products/fleur de vie gros plan3.JPG',
        title: 'Fleur de vie',
      },
      {
        img: 'assets/products/lotus gros plan4.JPG',
        title: 'Lotus',
      },
      {
        img: 'assets/products/pyramide Christ présentation1.JPG',
        title: 'Pyramide Christ',
      },
    ]

    return articles.map(article => unsafeHTML(`
      <sl-carousel-item class="carousel_item" style="position:relative">
        <img src="${article.img}"/>
        <!--<a href="${article.link}">${article.title}</a>-->
        <a>${article.title}</a>
        <!--<div style="position:absolute;bottom:0px;background-color:#00000099;color:white;left:0px;right:0px;text-align:center;padding:5px;font-size:0.8em;">${article.date}</div> -->
      </sl-carousel-item>
    `));
  }

  render() {
    return html`
      <div class="background_home">
        <div class="background_home_img"></div>
        <div class="big_title">
            <div>${unsafeHTML(this._i18n('main.title'))}</div>
        </div>
      </div>

      <div class="subtitle">${unsafeHTML(this._i18n('main.title2'))}</div>
      <div class="grid">
        <a href="/public/shop">
          <box-styled>
            <m-icon slot="icon" name="storefront"></m-icon>
            <div>${unsafeHTML(this._i18n('carousel.shop'))}</div>
          </box-styled>
        </a>
      </div>
      <!--
      <br/>
      
      <div class="content">
        <sl-carousel pagination mouse-dragging loop autoplay autoplay-interval="5000">
          ${this.render_blog()}
        </sl-carousel>
      </div>
      -->
    `;
  }


}

customElements.define('page-home', PageHome);