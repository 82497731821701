import { html, css } from 'UX';
import { Fetcher } from 'Utils';
import App from 'App';
import Sortable from 'sortablejs';

import '../tags/select.js';
import DrawerForm from 'Components/DrawerForm.js';

const IMG_WIDTH = 40;
const IMG_HEIGHT = 40;


class AdminProductComponentEdit extends DrawerForm {
  static get styles() {
    return [
      super.styles,
      css`
        .input_with_translate {
          width:100%;
          gap:5px;
          display: grid;
          grid-template-columns: 1fr 75px;
          height:56px;
          overflow:hidden;
        }

        .select_components {
          display: grid;
          width:100%;
          gap:10px;
          grid-template-columns: 0.99fr;
          position:absolute;
        }

        .select_components m-icon {
          font-size: 30px;
          margin-top: 4px;
          cursor:pointer;
        }

        table.components {
          width:97%;
          border-collapse: collapse;
          text-align:left;
          margin-left:auto;
          margin-right:auto;
          font-size:0.85em;
        }

        table.components td {
          padding: 10px;
          border:1px solid var(--sl-color-neutral-300);
        }

        table.components tbody tr:nth-child(odd) td {
          background-color: var(--sl-color-neutral-50);
        }

        table.components td:first-child {
          padding: 0;
          width:40px;
        }

        table.components td.icon {
          width:30px;
          padding:0;
          text-align:center;
        }

        img-proxy {
          margin:5px;
        }
        `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/products_components';
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this.urlVar = 'idp';
    this.eventUpdated = 'product-component-updated';

    this._logEnable = false;
    this._i18nResources = {
      english:{
        translation: {
          cancel:'Cancel',
          add:'Add a component',
          edit:'Edit',
          save:'Save'
        },
      },
      french:{
        translation: {
          cancel:'Annuler',
          add:'Ajouter un composant',
          edit:'Modifier',
          save:'Enregistrer'
        }
      }
    }
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    } else if (response.reason === 'MIN_VALUE') {
      msg = `Valeur minimum de ${response.minValue}`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();

    if (input.textarea?.setCustomValidity) input.textarea.setCustomValidity(msg);
    if (input.textarea?.reportValidity) input.textarea.reportValidity();
  }

  async translate(ev) {
    
    const target = ev.target;
    target.loading = true;
    target.disabled = true;

    const payload = {
      title: this.modal.querySelector('[name="title_fr"]').value,
      desc: this.modal.querySelector('[name="desc_fr"]').value
    }

    const response = await Fetcher.post('private/admin/products/translate', payload);
    target.loading = false;
    target.disabled = false;
    if (!response) return;

    this.modal.querySelector('[name="title_en"]').value = response.data.title;
    this.modal.querySelector('[name="desc_en"]').value = response.data.desc;
  }


  getItemTitle() {
    return this.item.title_fr;
  }

  getTranslatePayload() {
    const payload = {
      title: this.modal.querySelector('[name="title_fr"]').value,
      desc: this.modal.querySelector('[name="desc_fr"]').textarea.value,
    }

    return payload;
  }

  onTranslated(ev) {
    this.modal.querySelector('[name="title_en"]').value = ev.detail.title;
    this.modal.querySelector('[name="desc_en"]').value = ev.detail.desc;
  }

  async handleImagesChanged(event) {
    this._log.debug('handleImagesChanged', event.detail);
    this.item.images = event.detail;
    this.compareFields();
  }

  async handleTagsChanged(event) {
    this.item.tags = event.detail;
    this._log.debug('handleTagsChanged', this.item.tags);
    this.compareFields();
    this.requestUpdate();
  }


  render() {

    if (!this.item) return '';

    this._log.debug('render', this.item);

    return html`
      <modal-drawer @onHide=${this.onHide}>
        <style>${this.constructor.styles}</style>
        <form>
          <sl-tab-group class="main">
            <sl-tab slot="nav" panel="text">Descriptif</sl-tab>
            <sl-tab slot="nav" panel="photos">Images</sl-tab>

            <sl-tab-panel name="text">
              <scrollable-component scrollbar-visibility="always" >
                <sl-tab-group>
                  <sl-tab slot="nav" panel="french">🇫🇷 Français</sl-tab>
                  <sl-tab slot="nav" panel="english">🇺🇸 Anglais</sl-tab>

                  <sl-tab-panel name="french">
                    <sl-input size="small" name="title_fr" defaultValue="" value="${this.item.title_fr}" placeholder="Titre"></sl-input><br/>
                    <sl-textarea resize="auto" size="small" name="desc_fr" defaultValue="" value=${this.item.desc_fr} placeholder="Description">${this.item.desc_fr}</sl-textarea>
                  </sl-tab-panel>

                  <sl-tab-panel name="english">
                    <div class="input_with_translate">
                      <sl-input size="small" name="title_en" defaultValue="" value="${this.item.title_en}" placeholder="Title"></sl-input>
                      <button-translate .getPayload=${this.getTranslatePayload} @translated=${this.onTranslated}></button-translate>
                    </div>
                    <sl-textarea resize="auto" size="small" name="desc_en" defaultValue="" value=${this.item.desc_en} placeholder="Description">${this.item.desc_en}</sl-textarea>
                    <br/>
                  </sl-tab-panel>

                  <br/>
                  <select-tags
                    name="tags"
                    size="small"
                    placeholder="Tags" 
                    multiple 
                    .items=${this.item.tags}
                    @items-changed=${this.handleTagsChanged} 
                  ></select-tags>

                </sl-tab-group>
              </scrollable-component>
            </sl-tab-panel>

            <sl-tab-panel name="photos">
              <scrollable-component scrollbar-visibility="always">
                <image-uploader 
                  name="images"
                  .value=${this.item.images}
                  @value-changed=${this.handleImagesChanged}
                ></image-uploader>
              </scrollable-component>
            </sl-tab-panel>

          </sl-tab-group>
        </form>
        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" disabled @click="${this.handleSubmit}">${this._i18n('save')}</sl-button>
      </modal-drawer>
    `;
  }

}

customElements.define('admin-product-component-edit', AdminProductComponentEdit);