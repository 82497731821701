import { PageElement, html, css } from 'UX';
import { RunAsPwa, Notify } from 'Utils';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          margin-left:10px;
          margin-right:10px;
        }
        sl-button#installButton {
          width:300px;
        }

        h4 {
          text-decoration:underline;
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english: {
        translation: {
          title:'FAQ'
        },
      },
      french: {
        translation: {
          title:'FAQ'
        }
      }
    };

    this.deferredPrompt = null; // Ajout pour stocker l'événement beforeinstallprompt
    this.onBeforeInstallPrompt = this.onBeforeInstallPrompt.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  }

  onBeforeInstallPrompt(e) {
    alert('onBeforeInstallPrompt');
    // Empêcher le navigateur d'afficher la demande d'installation
    e.preventDefault();
    // Stocker l'événement pour pouvoir le déclencher plus tard
    this.deferredPrompt = e;
  }

  isIOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
      // iPad sur iOS 13 détecté comme Macintosh
      || (userAgent.includes("Mac") && "ontouchend" in document);
  }

  async install() {
    try {
      if (RunAsPwa()) {
        Notify.error('Vous utilisez déjà l\'application !');
        return;
      }

      if (this.deferredPrompt) {
        // Afficher la demande d'installation
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          this.shadowRoot.querySelector('modal-dialog').show();
        } else {
          Notify.error('Vous avez refusé l\'installation.');
        }
        this.deferredPrompt = null;
      } else {
        if (this.isIOS()) {
          if (!RunAsPwa()) {
            alert("Pour installer cette application sur votre appareil iOS, appuyez sur l'icône de partage et sélectionnez 'sur l'écran d'accueil' (en bas des options).");
          }
        } else {
          alert('Appareil non supporté');
        }
      }
    } catch(e) {
      alert(e.message);
    }
  }

  render() {
    return html`
      <modal-dialog label="Installation terminée">
        <style> ${this.constructor.styles} </style>
        <p>
          L'installation s'est bien déroulée.<br/>
          <br/>
          Vous pouvez maintenant paramétrer vos <a-route href="/private/user/account#notifications">notifications</a>
        </p>
        <br/><br/>
        <div class="buttons">
          <a-route href="/private/user/account#notifications"><sl-button variant="primary">Mes notifications</sl-button></a-route>
        </div>
      </modal-dialog>

      <section-header backroute="/private" micon="quiz">${this._i18n('title')}</section-header>

      <br/>
      <a name="#installpwa"></a>
      <h4>Pourquoi et comment installer le site sur mon mobile ?</h4>
      Le site artgoone est une "application web progressive" (PWA) qui peut et doit être installée sur votre mobile afin de bénéficier des <b>notifications push</b>.
      Les notifications push sont des messages envoyés depuis nos serveur vers votre terminal mobile (smartphone, tablette), que vous soyez en train de naviguer 
      sur le site, ou pas. <br/>
      <br/>
      Après installation de site sur votre terminal, vous pourrez souscrire à différents canaux de notifications (nouveautés du site, nos nouvelles publications, ..)<br/>
      <br/>
      <sl-button variant="primary" id="installButton" @click="${this.install}">Installer le site sur mon terminal</sl-button>
    `;
  }
}

customElements.define('page-doc-faq', Page);