import { LitElement, html, css } from 'lit-element';
import { Router } from 'Router';

class ARoute extends LitElement {
  static get styles() {
    return css`
      a {
        color: var(--sl-color-neutral-600);
        text-decoration: none;
      }

      a:hover {
        color: var(--sl-color-primary-500);
      }
    `
  }

  static get properties() {
    return {
      href: { type: String },
      title: { type: String, reflect: true }
    };
  }

  constructor() {
    super();
    this.href = '';
    this.title = '';
  }

  navigate(e) {
    e.preventDefault();    
    
    //const href = this.href[0] === '/' ? this.href : App.config.basePath+this.href;
    const href = this.href[0] === '/' ? this.href : window.location.pathname+'/'+this.href;
    if (e.ctrlKey) {
      const win = window.open(href, '_blank');
      if (win) {
        win.focus();
      }
      return;
    }
    Router.go(href);
    window.scrollTo(0, 0);
  }

  render() {
    return html`
      <a href="${this.href}" @click="${this.navigate}" aria-label="${this.title}">
        <slot part="text"></slot>
      </a>
    `;
  }
}

customElements.define('a-route', ARoute);