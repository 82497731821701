import { Session } from 'Utils';

class RoutesManager {
  constructor(router) {

    this.router = router;
    this.buildRoutes = this.buildRoutes.bind(this);

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isBoss = this.isBoss.bind(this);


    this.publicRoutes = {
      '/public':                              { component: 'page-home',                         title:'ArtGoOne' },
      '/public/notifications':                { component: 'page-notifications',                title:'Notifications' },
      '/public/user/logout':                  { component: 'page-user-logout',                  title:'Déconnexion' },
      '/public/forbidden':                    { component: 'error-forbidden',                   title:'Accès refusé' },
      '/public/errors':                       { component: 'error-list',                        title:'Messages d\'erreur' },
      '/public/errors/404':                   { component: 'error-not-found',                   title:'Page introuvable' },
      '/public/tests/section_header':         { component: 'page-test-section-header',          title:'Test section-header' },
      '/public/shop':                         { component: 'page-shop',                         title:'La boutique' },
      '/public/shop/product/:id':             { component: 'page-shop-product',                 title:'' },
    };

    this.authenticatedRoutes = {
      '/private':                             { component: 'page-user-home',                    title:'Mon espace', action:this.isAuthenticated },
      '/private/user/account':                { component: 'page-user-account',                 title:'Mon compte', action:this.isAuthenticated },
      '/private/doc/api':                     { component: 'page-doc-api',                      title:'Documentation API', action:this.isBoss },
      '/private/doc/faq':                     { component: 'page-doc-faq',                      title:'FAQ' },
      '/private/doc/videos':                  { component: 'page-doc-videos',                   title:'Vidéos', action:this.isBoss },
    }

    this.adminRoutes = {
      '/private/admin':                       { component: 'page-admin-home',                   title:'Administration', action:this.isBoss },
      '/private/admin/customers':             { component: 'page-admin-customers',              title:'Clients', action:this.isBoss },
      '/private/admin/products':              { component: 'page-admin-products',               title:'Produits', action:this.isBoss },
      '/private/admin/components':            { component: 'page-admin-product-components',     title:'Composants', action:this.isBoss },
      '/private/admin/website/sessions':      { component: 'page-admin-website-sessions',       title:'Sessions', action:this.isBoss },
      '/private/admin/website/backup_restore':{ component: 'page-admin-website-backup_restore', title:'Sauvegarde & restauration', action:this.isBoss },
    }

    // because we can not specify title of the webapp while installing it on IOS
    if (ENV === 'dev') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne DD';
    } else if (ENV === 'preprod') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne PP';
    } else {
      this.authenticatedRoutes['/private/doc/faq'].title = 'ArtGoOne';
    }

    this.buildRoutes();
    window.addEventListener('session-refreshed', this.buildRoutes);
  }

  async buildRoutes() {

    if (this.routes) return;

    let routes = this.menuToRoutes(this.publicRoutes);
    routes.animate = true;

    if (Session.isAuthenticated()) {
      routes = routes.concat(this.menuToRoutes(this.authenticatedRoutes));
    }

    if (Session.isBoss()) {
      await import('Components/admin.js');
      routes = routes.concat(this.menuToRoutes(this.adminRoutes));
    }

    // redirect / to /public
    routes.unshift({ path: '/', redirect: '/public' });

    // handle 404
    routes.push({ path: '(.*)', title: 'Page non trouvée', action: (ctx, cmd) => {
      if (ctx.pathname.includes('admin')) {
        return cmd.component('error-forbidden');
      }
      return cmd.component('error-not-found');
    }});

    this.routes = routes;
    this.router.setRoutes(this.routes);
  }


  menuToRoutes(menu, parentPath  = '') {
    return Object.entries(menu).flatMap(([path, item]) => {
      const fullPath = parentPath+path;
      const route = { path: fullPath };
      if (item.title) route.title = item.title;
      if (item.component) route.component = item.component;
      if (item.redirect) route.redirect = item.redirect;
      if (item.action) route.action = item.action;
      route.animate = true;

      if (item.pages) {
        const childRoutes = menuToRoutes(item.pages, fullPath);
        return [route, ...childRoutes];
      } else {
        return [route];
      }
    });
  }

  isAuthenticated(ctx, cmd) {
    if (!Session.isAuthenticated()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isBoss(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isBoss()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }
}

export default RoutesManager;
