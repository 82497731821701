
function get(name, defaultValue) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name) || defaultValue;
}

function set(name, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(name, value);
  refresh(url);
}

function refresh(url) {
  const previousUrl = window.history.state ? window.history.state.url : null;

  // Remplacer l'URL seulement si elle est différente de la dernière URL poussée
  if (url.origin + url.pathname + url.search !== previousUrl) {
    window.history.replaceState({ url: url.origin + url.pathname + url.search }, '', url);
  }
}

function del(name) {
  const url = new URL(window.location.href);
  url.searchParams.delete(name);
  refresh(url);
}

export default {
  get,
  set,
  del
}