import { LitElement, html, css } from 'lit-element';

class BoxStyled extends LitElement {
  static get styles() {
    return css`
      :host {
        display:block;
        width:100%;
        padding:10px;
      }

      .main {
        display:flex;
        flex:1;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        text-align:center;
        min-height:83px;
        padding:10px;
        padding-bottom:5px;
        padding-top:5px;
        border-radius:3px;  
        cursor:pointer;
        gap:10px;
        border:1px solid transparent;
        transition: all 0.2s;
      }

      .main.row {
        flex-direction:row;
        justify-content:flex-start;
        text-align:left;
        min-height:45px;
      }

      .neuro {
        box-shadow: var(--neuromorph-box-shadow);
      }

      .neuro:hover {
        box-shadow: var(--neuromorph-box-shadow-hover);
        color:var(--sl-color-primary-600);
        /*border:1px solid var(--sl-color-primary-200);*/
        background-color:var(--sl-color-primary-100);
      }

      .neuro.nobgchange:hover {
        background-color:transparent;
      }

      .warning {
        background-color:var(--sl-color-warning-100);
      }

      ::slotted([slot="icon"]) {
        font-size:2.5em;
        font-weight:200;
      }

      m-icon {
        font-size:2em;
      }

      /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
      @media (max-width: 670px) {
        :host {
          font-size:0.9em;
        }
      }

 
    `;
  }

  static get properties() {
    return {
      style: { type: String },
      layout: { type: String },
      micon: { type: String },
      aroute: { type: String },
      hoverNoBackgroundChange: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.style = 'neuro';
    this.layout = '';
    this.micon = '';
    this.aroute = '';
    this.hoverNoBackgroundChange = false;
  }

  render() {
    const icon = this.micon ? html`<m-icon name="${this.micon}"></m-icon>` : '';
    const content = html`
      <div class="main ${this.style} ${this.layout} ${this.hoverNoBackgroundChange ? 'nobgchange' : ''}">
        <slot name="icon">${icon}</slot>
        <slot></slot>
      </div>
    `;

    return this.aroute ? html`<a-route href="${this.aroute}">${content}</a-route>` : content;
  }
}

customElements.define('box-styled', BoxStyled);

