import { html } from 'UX';

import '../tags/select.js';
import DrawerForm from 'Components/DrawerForm.js';


class AdminTagEdit extends DrawerForm {
  
  constructor() {
    super();
    this.apiEndpoint = 'private/admin/tags';
    this.urlVar = 'idt';
    this.eventUpdated = 'tag-updated';

    this._logEnable = false;
    this._i18nResources = {
      english:{
        translation: {
          cancel:'Cancel',
          add:'Add a component',
          edit:'Edit',
          save:'Save'
        },
      },
      french:{
        translation: {
          cancel:'Annuler',
          add:'Ajouter un composant',
          edit:'Modifier',
          save:'Enregistrer'
        }
      }
    }
  }

  getItemTitle() {
    return this.item.title_fr;
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer name="modal-tag-edit" @onHide=${this.onHide}>
        <style>${this.constructor.styles}</style>
        <form>
          <sl-tab-group class="main">
            <sl-tab slot="nav" panel="text">Tag</sl-tab>

            <sl-tab-panel name="text">
              <sl-input size="small" name="title_fr" initial-value="" placeholder="Tag"></sl-input>
            </sl-tab-panel>
          </sl-tab-group>
        </form>

        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.handleSubmit}">${this._i18n('save')}</sl-button>
      </modal-drawer>

    `;
  }

}

customElements.define('admin-tag-edit', AdminTagEdit);