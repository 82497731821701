import { PageElement, css } from 'UX';
import { UrlParams, Fetcher } from 'Utils';
import { diff } from 'just-diff';

class DrawerForm extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --sl-input-label-font-size-small: var(--sl-font-size-medium);
        }

        form {
          position:relative;
        }

        sl-tab-group.main::part(tabs) {
          overflow:hidden;
        }

        sl-tab::part(base) {
          padding: 0px;
          padding-left:10px;
          padding-right:10px;
          padding-bottom:5px;
          padding-top:5px;
          border-radius:0px; 
          overflow:hidden;
          font-size:0.85em;
        }

        sl-tab-group.main sl-tab::part(base) {
          padding-bottom:10px;
          padding-top:10px;
        }

        sl-tab-group::part(active-tab-indicator) {
          bottom: calc(-1* var(--track-width) + 2px);
        }

        sl-tab-panel::part(base) {
          padding-right:10px;
        }

        sl-switch {
          font-size: 14px;
        }

        scrollable-component {
          height:calc(100vh - 200px);
          --scrollbar-width: 10px;
          --content-padding: 10px;
          --viewport-overflow-x: hidden;
        }

        .number {
          --label-width: 70px;
          --gap-width: 1rem;
        }

        .number + .number {
          /*margin-top: var(--sl-spacing-medium);*/
        }

        .number::part(form-control) {
          display: grid;
          grid: auto / var(--label-width) 1fr;
          gap: var(--sl-spacing-3x-small) var(--gap-width);
          align-items: center;
        }

        .number::part(form-control-label) {
          text-align: left;
        }

        .number::part(form-control-help-text) {
          grid-column-start: 2;
        }

        .number::part(form-control-input) {
          width:80px;
          float: right;
        }

        sl-dropdown {
          text-align:left;
        }

        sl-menu-item m-icon {
          font-size:1.5em;
        }

        sl-menu-item m-icon[name="delete"] {
          color:var(--sl-color-danger-500);
        }

        sl-menu-item::part(base) {
          font-size:0.9em;
          padding:2px;
          padding-left:5px;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        m-icon[name="more_vert"] {
          cursor:pointer;
        }

      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object, reflect:true }
    }
  }

  constructor() {
    super();
    this._logEnable = false;
    this.compareFields = this.compareFields.bind(this);
  }

  compareFields(ev) {
    // reset reportValidity etc
    this.fields.forEach((el) => {
      if (el.setCustomValidity) el.setCustomValidity('');
      if (el.textarea?.setCustomValidity) el.textarea.setCustomValidity('');
    });

    clearTimeout(this.timerCompareField);
    this.timerCompareField = setTimeout(() => {
      const data = this.getFieldsValue();
      const result = diff(data, this.initialData);
      this._log.warn('compareFields: compare result', result);
      if (result.length === 0) {
        this.modal.disableButtons();
      } else {
        this.modal.resetButtons();
      }
    }, 100);
  }

  async updated(changedProperties) {
    if (changedProperties.has('item')) {
      this.show();
      if (this.item._id) {
        await this.edit();
      } else {
        await this.add();
      }
    }
  }

  async show() {
    //  update url
    this.item._id ? UrlParams.set(this.urlVar, this.item._id) : UrlParams.del(this.urlVar);
    this.modal = this.shadowRoot.querySelector('modal-drawer');

    const fields = [
      'sl-input',
      'sl-textarea',
      'sl-switch',
      'markdown-editor',
      'select-product-components',
      'select-tags',
      'image-uploader'
    ].join(', ');


    this.fields = this.modal.querySelectorAll(fields);
    this.modal.show();
    await this.updateComplete;
    
    this.initialData = this.getFieldsValue();
    this._log.debug('show: initial data', this.initialData);

    this.listenFields();
    
    // specific
    this.markdownEditor = this.shadowRoot.querySelector('markdown-editor');
    this.imageUploader = this.shadowRoot.querySelector('image-uploader');
    if (this.imageUploader) this.imageUploader.show();
  }

  async add() {
    this.resetFields();
    this.modal.label=this._i18n('add');
  }

  async edit() {
    this.modal.label=`${this._i18n('edit')} ${this.getItemTitle()}`;
    this.forceFormFieldsValues();
  }

  forceFormFieldsValues() {
    // Specific fields i have problem with render()
    this.fields.forEach((el) => {
      if (el.tagName === 'SL-SWITCH') {
        //.checked=${this.item.promo} => ${this.item.promo ? 'checked' : ''} does not work so ..
        el.checked = this.item[el.name];
        return;
      }
    });
  }

  toggleFormFields(enable) {
    this.fields.forEach((el) => {
      el.disabled = !enable;
      if (el.textarea) el.textarea.disabled = !enable;
    });
  }

  resetFields() {
    this.fields.forEach((el) => {
      el.value = el.getAttribute('defaultValue');
    });
  }

  listenFields() {
    if (this.fieldsAlreadyListened) return;
    this.fieldsAlreadyListened = true;
    this.fields.forEach((el) => {
      el.addEventListener('input', this.compareFields );
      el.addEventListener('sl-change', this.compareFields );
    });
  }

  cleanImageUrls(images) {
    if (images && images.length) {
      for (const image of images) {
        if (image.src.includes('imgp')) {
          image.src = image.src.replace(`${STATIC_PUBLIC_URL}/imgp/[^\/]+/`, '/');
          image.src = image.src.split('@')[0];
        }
      }
    }
  }

  getFieldsValue() {
    const data = {};

    this.fields.forEach((el) => {
      let value = el.value;
      const tagName = el.tagName.toLowerCase();
      if (el.type === 'number') {
        value = value ? parseFloat(value) : null;
      } else if (tagName === 'sl-switch') {
        value = el.checked;
      } else if (tagName === 'markdown-editor') {
        value = el.textarea.value;
      } else if (tagName === 'image-uploader') {
        value = [ ...el.value || [] ]
      } else if (tagName.match(/^select-/)) {
        value = [ ...el.items || []]
      }

      this._log.debug('getFieldsValue', el.name, value);
      data[el.name] = value;
    });

    this.cleanImageUrls(data.images);

    return data;
  }

  async onHide() {
    UrlParams.del(this.urlVar);
    this.imageUploader && this.imageUploader.hide();
  }

  handleSubmitError(input, response) {
    // override me
  }

  async handleSubmit() {
    this.modal.loadingButtons();
    this.toggleFormFields(false);
    
    const data = this.getFieldsValue();

    const response = await Fetcher.postOrPut(this.apiEndpoint, data, this.item?._id);
    if (!response) {
      this.toggleFormFields(true);
      this.modal.resetButtons();
      return;
    }

    if (response.error === 'EVALIDATION_FAILED') {
      this.toggleFormFields(true);
      const input = this.modal.querySelector(`[name="${response.field}"]`);

      const tabPanel = input.closest('sl-tab-panel');
      tabPanel.closest('sl-tab-group').show(tabPanel.name);
      const tabPanelParent = tabPanel.closest('sl-tab-group').closest('sl-tab-panel');
      if (tabPanelParent) {
        tabPanelParent.closest('sl-tab-group').show(tabPanelParent.name);
      }
      await this.updateComplete;
      this.handleSubmitError(input, response);
      this.modal.resetButtons();
      return;
    }

    this.toggleFormFields(true);
    this.modal.resetButtons();
    this.modal.hide();

    if (this.eventUpdated) {
      const event = new CustomEvent(this.eventUpdated, { detail: response.data });
      window.dispatchEvent(event);
    } else {
      console.warn('eventUpdated not defined');
    }
  }

}

export default DrawerForm;