import { BaseElement, html, css } from 'UX';
import { Session } from 'Utils';
import { Router } from 'Router';
import App from 'App';

class SessionIcon extends BaseElement {
  static get properties() {
    return {
      logged: { type: Boolean },
    };
  }
  
  static get styles() {
    return css`
      :host {
        display: block;
        margin-right:5px;
        --size:30px;
      }

      sl-button.round::part(base) {
        border-radius:50%;
        height:var(--size);
        width:var(--size);
      }

      sl-button.round::part(label) {
        padding:0px;
        display:flex;
        justify-content:center;
        align-items:center;
      }

      m-icon {
        font-size: 30px;
      }

      sl-card::part(body) {
        width:170px;
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:10px;
        margin:20px !important;
        margin-top:0px;
        margin-left:30px !important;
        margin-right:30px !important;
        padding:0px;
      }

      .big {
        font-size:50px;
        border-radius:50%;
        margin:0px;
        padding:0px;
      }

      .identity {
        font-size:14px;
        margin:0px;
        padding:0px;
        line-height:15px;
      }

      .white {
        color:white;
      }

      p {
        line-height:20px;
      }

      .buttons {
        display:flex;
        gap:10px;
        justify-content:space-between;
      }
    `;
  }

  constructor() {
    super();
    this.logged = false;
    this.resizeTimeout = null;
    this.handleResize = this.handleResize.bind(this);
    this.handleSessionRefreshed = this.handleSessionRefreshed.bind(this);
    this._i18nResources = {
      english:{
        translation: {
          my_account:'My Account',
          button_logout:'Logout',
          logout_confirm_label:'Logout',
          logout_confirm_question:'Are you sure you want to logout ?',
          logout_confirm_yes:'Yes, logout',
          cancel:'Cancel'
        }
      },
      french:{
        translation: {
          my_account:'Mon compte',
          button_logout:'Se déconnecter',
          logout_confirm_label:'Déconnexion',
          logout_confirm_question:'Êtes-vous sûr de vouloir vous déconnecter ?',
          logout_confirm_yes:'Oui, me déconnecter',
          cancel:'Annuler'
        }
      }
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('session-refreshed', this.handleSessionRefreshed);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('session-refreshed', this.handleSessionRefreshed);
  }

  async handleSessionRefreshed() {
    this.session = await Session.get();
    this.requestUpdate();
  }

  async firstUpdated() {
    this.session = await Session.get();
    if (!this.session) return;
    if (Session.isAuthenticated()) {
      //this.emailFirstLetter = session.email.charAt(0).toUpperCase();
      this.logged = true;
    }
    this.requestUpdate();
  }

  handleResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => this.requestUpdate(), 200);
  }

  handleLoginClick() {
    let lg = App.config.language;
    if (lg === 'french') lg='fr';
    if (lg === 'english') lg='en';
    window.location.href = `/auth/login?kc_locale=${lg}&dark=${App.config.isDark}&url=${window.location.pathname}`;
    //&kc_locale=en
    /*
    if (window.location.hostname.includes('www')) {
      window.location.href = '/auth/login';
    } else {
      // this url is used to redirect to an intermediate page that 
      // will clean up cookies and localstorage on the keycloak url
      // not sure if it's still needed
      const currentPort = window.location.port;
      let url = '/auth/loginr?';

      if (currentPort !== '80' && currentPort !== '443') {
        url += `p=${currentPort}`;
      }

      if (window.location.hostname.includes('pr2pr0d')) {
        url += '&pp=1';
      }

      window.location.href = url;
    }
    */
  }

  handleAccountClick() {
    this.hideDropdown();
    Router.go('/private/user/account');
  }

  hideDropdown() {
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.dropdown.hide();
  }

  handleLogout() {
    this.hideDropdown();
    this.dialog = this.dialog || this.shadowRoot.querySelector('#modal-logout') || document.querySelector('#modal-logout');
    this.dialog.show();
  }

  handleConfirmLogout() {
    let url = Session.getLogoutUrl();
    
    const currentPort = window.location.port;
    if (currentPort.length) {
      url += ':' + currentPort;
    }

    url+='/auth/logout';

    if (!this.session.ith) {
      console.error('handleConfirmLogout: id_token_hint is missing !');
      return;
    }
    
    url+= '&id_token_hint='+this.session.ith;
    window.location.href = url;
  }


  render() {

    if (typeof this.session === undefined) {
      return;
    }

    const btLoginContent = window.innerWidth > 660 
      ? html`<sl-button size="small" title="Se connecter" pill @click="${this.handleLoginClick}">Connexion</sl-button>` 
      : html`<sl-button size="small" title="Se connecter" variant="text" pill @click="${this.handleLoginClick}" class="round"><m-icon nogradient class="white" name="account_circle"></m-icon></sl-button>`;


    return html`
      ${this.logged
        ? html`
          <sl-dropdown>
            <m-icon slot="trigger" clickable name="account_circle" class="white round"></m-icon>
            <sl-card>
              <m-icon name="account_circle" class="big"></m-icon>
              <div class="identity">${this.session.firstname} ${this.session.lastname}</div>
              <div class="identity">${this.session.email}</div>
              <sl-button size="medium" variant="text" @click="${this.handleAccountClick}">${this._i18n('my_account')}</sl-button>
              <sl-button size="medium" variant="primary" @click="${this.handleLogout}">${this._i18n('button_logout')}</sl-button>
            </sl-card>
          </sl-dropdown>
          <modal-dialog label="${this._i18n('logout_confirm_label')}" id="modal-logout">
            <div>
              <p><br/>${this._i18n('logout_confirm_question')}</p>
            </div>
            <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
            <sl-button slot="bt2" variant="primary" @click="${this.handleConfirmLogout}">${this._i18n('logout_confirm_yes')}</sl-button>
          </modal-dialog>
        `
        : html`${btLoginContent}`
      }
    `;
  }
}

customElements.define('session-icon', SessionIcon);