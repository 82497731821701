import { LitElement } from 'lit-element';
import i18next from 'i18next';
import App from 'App';
import { Logger } from 'Utils';

class BaseElement extends LitElement {
  static get properties() {
    return {
      _lang: { type: String },
    }
  }

  constructor() {
    super();
    this._handleScroolbar = false;
    this._logEnable = false;
    this._logLevel = 'debug';
    this._lang = i18next.language;
    this._i18nResources = null;
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
    this._handleIntersection = this._handleIntersection.bind(this);
  }

  _createLogger() {
    this._log = new Logger(this.constructor.name, this.logLevel, this._logEnable);
  }
  
  _i18init() {
    //console.log(this.constructor.name, 'init i18n', this._i18nResources);
    this._i18nInstance = i18next.createInstance();
    this._i18nInstance.init({
        resources: this._i18nResources,
        fallbackLng: App.config.language,
        /*
        detection: {
          order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
          caches: ['localStorage', 'cookie']
        },
        */
        interpolation: {
          escapeValue: false
        }
      });
  }

  connectedCallback() {
    super.connectedCallback();
    this._createLogger();
    if (this._i18nResources) {
      window.addEventListener('language-changed', this._onLanguageChanged);
      this._i18init();
    }
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this._onLanguageChanged);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.observer = new IntersectionObserver(this._handleIntersection, {
      root: null, // Utiliser le viewport comme root
      threshold: 0.1 // Déclencher lorsque 10% de l'élément est visible
    });
    this.observer.observe(this);
  }

  _handleIntersection(entries) {
    entries.forEach(async entry => {
      if (entry.isIntersecting) {
        await this.visibleCallback();
      } else {
        await this.hiddenCallback();
      }
    });
  }

  async visibleCallback() {
    // override me
  }

  async hiddenCallback() {
    // override me
  }

  _onLanguageChanged(ev) {
    this._lang = ev.detail.language;
    this._i18nInstance.changeLanguage(this._lang);
  }

  _i18n(key) {
    if (!this._i18nInstance) return key;
    const txt = this._i18nInstance.t(key);
    if (txt === key) {
      console.warn(`${this.constructor.name}: translation not found for key '${key}' '${this._lang}'`);
      //console.log(JSON.stringify(this._i18nResources, null, 4));
    }
    return txt;
  }
}

export default BaseElement;