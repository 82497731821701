import { html, css } from 'UX';
import App from 'App';
import { Fetcher } from 'Utils';

import './edit.js';
import TableBasic from 'Components/TableBasic.js'

const IMG_WIDTH = 80;
const IMG_HEIGHT = 80;


class AdminProductComponentList extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`      
        m-icon {
          cursor:pointer;
        }

        m-icon[name="more_vert"] {
          padding-top:2px;
          font-size: 20x;
        }

        m-icon[name="visibility"] {
          color:var(--sl-color-primary-500);
          font-size:20px;
        }

        m-icon[name="visibility_off"] {
          color:red;
          font-size:20px;
        }

        table td:first-child {
          padding: 0;
          width:80px;
        }

        table td.icon {
          width:30px;
          padding:0;
          text-align:center;
        }

        table td {
          position: relative;
          height: 70px;
          vertical-align: top;
        }

        .center {
          text-align:center;
        }

        img-proxy {
          margin:5px;
        }

        .description_tag {
          position:relative;
          height:100%;
        }

        .tags {
          max-height: 24px;
          padding-top: 3px;
          bottom: 0px;
          position: absolute;
          overflow: hidden;
          display:flex;
          gap:5px;
        }
      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/products_components';
    this.urlIdParam = 'idc';
    this.itemModel = {
      images:[]
    }
    this.itemTitleFieldName = 'title_fr';
    this.eventUpdatedName = 'product-component-updated';

    this._i18nResources = this._i18nMergeResources({
      english:{
        translation: {
          add:'Add a component',
          empty:'No component available',
          product:'component',
          image:'Image',
          title:'Title',
        },
      },
      french:{
        translation: {
          add:'Ajouter un composant',
          empty:'Aucun composant disponible',
          product:'composant',
          image:'Image',
          title:'Titre',
        }
      }
    });
  }

  async visibleCallback() {
    await this.loadTags();
    await super.visibleCallback();
  }
    
  async loadTags() {
    const response = await Fetcher.get(`private/admin/tags`);
    this.tags = response?.data;
    this.tags.sort((a, b) => {
      if (a.title_fr < b.title_fr) return -1;
      if (a.title_fr > b.title_fr) return 1;
      return 0;
    });
  }

  getItemDescription(item) {
    const key = `desc_${App.config.lg}`;
    return item[key] || item.desc_fr;
  }

  getItemTags(item) {
    // find tag in this.tags (_id)
    if (!item.tags?.length) return;
    const tags = item.tags.map(myTag => {
      const tag = this.tags.find(t => myTag._id === t._id);
      if (!tag) return;
      return html`<sl-tag variant="primary" size="small">${tag.title_fr}</sl-tag>`;
    });
    return tags;
  }


  getFirstImage(item) {
    if (!item.images?.length) return;
    return html`<img-proxy width="${IMG_WIDTH}" height="${IMG_HEIGHT}" src="${item.images[0].src}"></img-proxy>`;
  }

  _getItemTitle(item) {
    const key = `title_${App.config.lg}`;
    return item[key] || item.title_fr;
  }

  _renderEditComponent() {
    return html`<admin-product-component-edit id="modal-edit"></admin-product-component-edit>`;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? 'composants' : 'composant';
    return `${totalCount} ${totalText}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <th>&nbsp;Image</th>
            <th>&nbsp;Titre</th>
            <th>Description</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${this.getFirstImage(item)}</td>
            <td class="title">${this._getItemTitle(item)}</td>
            <td>
              <div class="description_tag">
                <div class="description">${this.getItemDescription(item)}</div>
                <div class="tags">${this.getItemTags(item)}</div>
              </div>
            </td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._i18n('modify')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._i18n('del')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
          <ul>
            <li>Cette action est irréversible, réservée aux développeurs et administrateurs.</li>
            <li>Ce composant n'apparaîtra plus sur les créations auxquelles il est affecté.</li>
          </ul>
        </div>

        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._i18n('delete')}</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('page-admin-product-components-list', AdminProductComponentList);