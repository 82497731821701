export default `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   viewBox="0 0 870 210"
   version="1.1"
   id="svg1"
   sodipodi:docname="logo_full.svg"
   inkscape:version="1.3.1 (9b9bdc1480, 2023-11-25, custom)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <sodipodi:namedview
     id="namedview1"
     pagecolor="#1e1e1e"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     inkscape:zoom="0.29857056"
     inkscape:cx="2074.8864"
     inkscape:cy="363.39819"
     inkscape:window-width="1920"
     inkscape:window-height="1012"
     inkscape:window-x="0"
     inkscape:window-y="0"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer2"
     showgrid="false" />
  <defs
     id="defs1">
    <linearGradient
       id="linearGradient157"
       inkscape:collect="always">
      <stop
         style="stop-color:#0000ff;stop-opacity:1;"
         offset="0"
         id="stop157" />
      <stop
         style="stop-color:#54ffd2;stop-opacity:0.78823531;"
         offset="0.58203059"
         id="stop159" />
      <stop
         style="stop-color:#eef02b;stop-opacity:0;"
         offset="1"
         id="stop158" />
    </linearGradient>
    <linearGradient
       id="linearGradient151"
       inkscape:collect="always">
      <stop
         style="stop-color:#ff5693;stop-opacity:1;"
         offset="0"
         id="stop150" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1;"
         offset="1"
         id="stop151" />
    </linearGradient>
    <linearGradient
       id="linearGradient147"
       inkscape:collect="always">
      <stop
         style="stop-color:#ff5693;stop-opacity:1;"
         offset="0"
         id="stop148" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1;"
         offset="1"
         id="stop149" />
    </linearGradient>
    <linearGradient
       id="linearGradient117"
       inkscape:collect="always">
      <stop
         style="stop-color:#00d4da;stop-opacity:1;"
         offset="0"
         id="stop118" />
      <stop
         style="stop-color:#0000ff;stop-opacity:1;"
         offset="1"
         id="stop119" />
    </linearGradient>
    <linearGradient
       id="linearGradient19"
       inkscape:collect="always">
      <stop
         style="stop-color:#ffffff;stop-opacity:1;"
         offset="0.31"
         id="stop20" />
      <stop
         style="stop-color:#f2ca00;stop-opacity:1;"
         offset="1"
         id="stop19" />
    </linearGradient>
    <radialGradient
       inkscape:collect="always"
       xlink:href="#linearGradient19"
       id="radialGradient20"
       cx="105.43918"
       cy="104.7163"
       fx="105.43918"
       fy="104.7163"
       r="100.97213"
       gradientTransform="matrix(1,0,0,0.9951555,0,0.50729859)"
       gradientUnits="userSpaceOnUse" />
    <radialGradient
       inkscape:collect="always"
       xlink:href="#linearGradient117"
       id="radialGradient119"
       cx="105.53912"
       cy="-11.038699"
       fx="105.53912"
       fy="-11.038699"
       r="65.098328"
       gradientTransform="matrix(1,0,0,0.86602543,0,-1.478905)"
       gradientUnits="userSpaceOnUse" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient147"
       id="linearGradient149"
       x1="57.430447"
       y1="65.618057"
       x2="129.17389"
       y2="65.618057"
       gradientUnits="userSpaceOnUse" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient151"
       id="linearGradient150"
       x1="57.430916"
       y1="66.209984"
       x2="129.17387"
       y2="66.209984"
       gradientUnits="userSpaceOnUse" />
    <radialGradient
       inkscape:collect="always"
       xlink:href="#linearGradient157"
       id="radialGradient158"
       cx="105.70988"
       cy="104.7163"
       fx="105.70988"
       fy="104.7163"
       r="101.25143"
       gradientTransform="matrix(1,0,0,0.99241039,0,0.79475538)"
       gradientUnits="userSpaceOnUse" />
  </defs>
  <g
     inkscape:groupmode="layer"
     id="layer2"
     inkscape:label="Calque 2">
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:123.472px;font-family:Monospace;-inkscape-font-specification:Monospace;fill:#00ffff;fill-opacity:1;stroke:#eef02b;stroke-width:4.13099"
       x="228.8024"
       y="140.77399"
       id="text1"
       inkscape:label="text1"
       inkscape:highlight-color="#ffffff"><tspan
         id="tspan1"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:123.472px;font-family:Calibri;-inkscape-font-specification:Calibri;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:4.131"
         x="228.8024"
         y="140.77399"
         sodipodi:role="line"><tspan
           style="fill:#009bff"
           id="tspan4">ART</tspan><tspan
           style="font-size:70.5556px"
           id="tspan2"> </tspan><tspan
           style="fill:#ff00ff"
           id="tspan5">GO</tspan><tspan
           style="font-size:70.5556px"
           id="tspan3"> </tspan><tspan
           style="fill:#ffff00"
           id="tspan6">ONE</tspan></tspan></text>
    <path
       style="fill:url(#radialGradient20);fill-opacity:1;stroke:url(#radialGradient158);stroke-width:4.131;stroke-dasharray:none;stroke-opacity:1"
       d="m 105.59359,4.2333335 c 0,101.2733965 -101.124731,99.6925565 -101.124731,99.6925565 0,0 101.124731,1.58084 101.124731,101.27338 0,-99.69254 100.70222,-99.69254 100.70222,-101.27338 0,0 -100.70222,1.45638 -100.70222,-99.6925565 z"
       id="path12"
       sodipodi:nodetypes="ccccc" />
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer3"
     inkscape:label="Calque 3">
    <path
       sodipodi:type="star"
       style="fill:url(#radialGradient119);fill-opacity:1;stroke:#00ffff;stroke-width:1.6646;stroke-dasharray:none;stroke-opacity:1"
       id="path13"
       inkscape:flatsided="false"
       sodipodi:sides="3"
       sodipodi:cx="105.53912"
       sodipodi:cy="7.7535682"
       sodipodi:r1="73.504478"
       sodipodi:r2="36.752243"
       sodipodi:arg1="0.52359878"
       sodipodi:arg2="1.5707963"
       inkscape:rounded="0"
       inkscape:randomized="0"
       d="m 169.19587,44.505808 -63.65675,3e-6 -63.656742,-4e-6 31.828369,-55.128359 31.828373,-55.128358 31.82838,55.128356 z"
       inkscape:transform-center-y="-17.516135"
       transform="matrix(0.95562418,0.00139854,-0.00139854,0.95562418,5.371634,114.96448)"
       inkscape:transform-center-x="0.051398328" />
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer4"
     inkscape:label="Calque 4">
    <path
       sodipodi:type="spiral"
       style="fill:none;fill-rule:evenodd;stroke:url(#linearGradient149);stroke-width:1.52945;stroke-dasharray:none;stroke-opacity:0.667713"
       id="path15"
       sodipodi:cx="100.50168"
       sodipodi:cy="73.562973"
       sodipodi:expansion="2.0799999"
       sodipodi:revolution="2.9954808"
       sodipodi:radius="50.684982"
       sodipodi:argument="-14.053452"
       sodipodi:t0="0"
       d="m 100.50168,73.562973 c 0.0105,-0.125388 0.25613,-0.0047 0.28842,0.0242 0.413,0.370003 -0.0309,0.998128 -0.39075,1.195245 -1.130569,0.619262 -2.372542,-0.414818 -2.731886,-1.45727 -0.780929,-2.26546 1.163629,-4.412363 3.266856,-4.918087 3.78573,-0.910284 7.13962,2.290443 7.76857,5.844086 1.00914,5.701702 -3.80473,10.569618 -9.206745,11.295088 C 91.475072,86.623438 84.781867,79.832247 83.988736,72.177347 82.87493,61.42744 92.013144,52.593909 102.33091,51.763414 116.22416,50.645124 127.51644,62.50468 128.35294,75.900015 129.44299,93.355439 114.48378,107.42796 97.592019,108.2383 76.151731,109.26684 58.974727,90.826123 58.223399,70.015338 57.310754,44.736259 78.694773,24.304164 103.30416,22.955527"
       transform="matrix(0.77170893,8.1515005e-5,-8.2003889e-4,0.93618798,24.429923,2.670954)" />
    <path
       sodipodi:type="spiral"
       style="fill:none;fill-rule:evenodd;stroke:url(#linearGradient150);stroke-width:1.52945;stroke-dasharray:none;stroke-opacity:0.667713"
       id="path19"
       sodipodi:cx="100.50168"
       sodipodi:cy="73.562973"
       sodipodi:expansion="2.0799999"
       sodipodi:revolution="2.9688632"
       sodipodi:radius="49.752663"
       sodipodi:argument="-14.053452"
       sodipodi:t0="0"
       d="m 100.50168,73.562973 c 0.0105,-0.125388 0.25613,-0.0047 0.28842,0.0242 0.413,0.370003 -0.0309,0.998127 -0.39075,1.195245 -1.130568,0.619261 -2.372541,-0.414818 -2.731885,-1.45727 -0.780929,-2.265459 1.163629,-4.412362 3.266855,-4.918085 3.78573,-0.910284 7.13961,2.290442 7.76857,5.844084 1.00914,5.701699 -3.80473,10.569613 -9.206745,11.295083 -8.02107,1.077201 -14.714271,-5.713987 -15.507402,-13.368884 -1.113806,-10.749903 8.024405,-19.583431 18.342167,-20.413925 13.89324,-1.118289 25.18552,10.741261 26.02202,24.136591 1.09005,17.455417 -13.86915,31.527926 -30.760909,32.338266 C 76.151741,109.26682 58.974744,90.826116 58.223417,70.01534 57.428215,47.989298 73.833414,28.908379 94.944851,24.121603"
       transform="matrix(-0.77170893,-8.1515005e-5,8.2003889e-4,-0.93618798,180.60621,205.26524)" />
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer5"
     inkscape:label="Calque 5" />
</svg>


`
