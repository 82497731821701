import { PageElement, html, css } from 'UX';
import { Notify } from 'Utils';
import App from 'App';

class ErrorList extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding:20px;
        }

        sl-button {
          width:300px;
          margin-right:10px;
        }

        sl-button::part(label) {
          font-weight:bold;
        }

        .flex {
          display:flex;
          justify-content:flex-start;
          align-items:center;
          margin-bottom:10px;
        }

        .flex div {
          width:500px;
          font-size:14px;
        }

        @media (max-width: 670px) {
          sl-button {
            width:100%;
            margin-bottom:10px;
          }

          .flex {
            flex-direction:column;
          }

          .flex div {
            width:100%;
          }
        }

      `
    ];
  }

  constructor() {
    super();

    this.codes = {};
    // sort by alphabetical order
    Object.keys(Notify.codes).sort().forEach(key => {
      this.codes[key] = Notify.codes[key];
    });

    this._i18nResources = {
      english:{
        translation: {
          title:'Error Messages',
          text:`These errors can appear when the application encounters a problem with the server.
                Click the buttons to display the associated errors.`,
        }
      },
      french:{
        translation: {
          title:`Messages d'erreur`,
          text:`Ces erreurs peuvent apparaîtrelorsque l'application rencontre un problème avec le serveur.
                Cliquez les boutons pour faire apparaître les erreurs associées.`,
        }
      }
    }
  }

  handleClick(ev) {
    Notify.errorCode(ev.target.innerText);
  }

  render() {
    return html`
      <section-header backroute="/private" micon="bug_report">${this._i18n('title')}</section-header>
      <div class="container">
        ${this._i18n('text')}<br/><br/>
        <br/><br/>
        ${Object.keys(this.codes).map((key) => {
          return html`
            <div class="flex">
              <sl-button variant="danger" size="small" pill @click="${this.handleClick}">${key}</sl-button>
              <div>${this.codes[key][App.config.language].help}</div>
            </div>
          `
        })}
        <br/><br/>
      </div>
    `;
  }


}

customElements.define('error-list', ErrorList);