import { PageElement, BaseElement, html, css } from 'UX';

class YoutubeVideo extends BaseElement {
  static properties = {
    videoId: { type: String }
  };

  static styles = css`
    iframe {
      width: 100%;
      height: 100%;
    }
  `;

  constructor() {
    super();
    this.videoId = ''; // ID par défaut (il peut être défini dynamiquement)
  }

  render() {
    return html`
      <iframe
        src="https://www.youtube.com/embed/${this.videoId}"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    `;
  }
}

customElements.define('youtube-video', YoutubeVideo);

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`

        .videos {
          display: grid;
          width:100%;
          grid-template-columns: repeat(3, minmax(320px, 1fr));
          font-size:0.9em;
        }

        .video {
          display: flex;
          flex-direction: column;
          position:relative;
          text-align: center;
          position:relative;
          border-radius:8px;
          margin:10px;
          box-shadow: var(--neuromorph-box-shadow);
          transition: box-shadow 0.3s;
          overflow:hidden;
        }

        .video:hover {
          box-shadow: var(--neuromorph-box-shadow-hover);
        }

        .video div.title {
          padding-top:7px;
          padding-bottom:3px;
        }

        .video div.date {
          font-size:0.8em;
          padding-bottom:5px;
        }

        /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
        @media (max-width: 670px) {
          .videos {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english: {
        translation: {
          title:'Video tutorials',
        },
      },
      french: {
        translation: {
          title:'Tutoriels vidéos',
        }
      }
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  render() {
    return html`
      <section-header backroute="/private" micon="animated_images">${this._i18n('title')}</section-header>
      <br/>
      <div class="videos">
        <div class="video">
          <youtube-video videoId="EyqgHYxw0yw"></youtube-video>
          <div class="title"><a href="https://www.youtube.com/watch?v=EyqgHYxw0yw" target="youtube">Gestion des composants</a></div>
          <div class="date">23/08/2024</div>
        </div>
        <div class="video">
          <youtube-video videoId="cKAlOPd4gkg"></youtube-video>
          <div class="title"><a href="https://www.youtube.com/watch?v=cKAlOPd4gkg" target="youtube">Gestion des créations</a></div>
          <div class="date">23/08/2024</div>
        </div>
        <div class="video">
          <youtube-video videoId="dX2v6mOzzNo"></youtube-video>
          <div class="title"><a href="https://www.youtube.com/watch?v=dX2v6mOzzNo" target="youtube">Tags, composants &amp; créations</a></div>
          <div class="date">30/08/2024</div>
        </div>
        <div class="video">
          <youtube-video videoId="euW5XR2JdDA"></youtube-video>
          <div class="title"><a href="https://www.youtube.com/watch?v=euW5XR2JdDA" target="youtube">Sauvegardes &amp; restaurations</a></div>
          <div class="date">01/09/2024</div>
        </div>
      </div>
    `;
  }
}

customElements.define('page-doc-videos', Page);