import { css } from 'lit-element';
import BaseElement from './BaseElement.js';
import pageStyles from './pageStyles.js';
import { Session } from 'Utils';

class FullPageElement extends BaseElement {
  static get properties() {
    return {
      _lang: { type: String }
    }
  }

  static get styles() {
    return [
      pageStyles,
      css`
        :host {
          display:block;
        }
      `

    ]
  }

  firstUpdated() {
    super.firstUpdated();
    this._pagePagePaddingTop();
  }

  _fixPagePaddingTop() {
    
    let fixed = false;

    if (Session.isAuthenticated()) {
      let el = this.shadowRoot.querySelector('div.content_page');
      if (el) {
        el.classList.add('collapsed');
        //console.log('fix', el);
      }

      el = this.shadowRoot.querySelector('div.content_large');
      if (el) {
        el.classList.add('collapsed');
        //console.log('fix', el);
      }
    }
  }

  _pagePagePaddingTop() {
    this._fixPagePaddingTop();
    //clearTimeout(this._timerScroll);
    //this._timerScroll = setTimeout(() => { this._fixPagePaddingTop(); }, 1);
  }

}

export default FullPageElement;