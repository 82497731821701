import { PageElement, html, css } from 'UX';

import './products/list.js';
import './components/index.js';
import './tags/index.js';
import UrlParams from '../../../../shared/Utils/UrlParams.js';

class AdminProductPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        /*
        sl-breadcrumb-item::part(label) {
          font-size:2.5em;
          line-height:40px;
          font-family:Calibri;
          text-transform:uppercase;
          font-weight:600;
        }
        sl-breadcrumb-item::part(separator) {
          font-size:1.8em;
          margin-top:-6px;
        }
        */
      `
    ]
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          title:'Creation management',
          creation:'Creations',
          components:'Components',
          tags:'Tags',
        }
      },
      french:{
        translation: {
          title:'Gestion des créations',
          creation:'Créations',
          components:'Composants',
          tags:'Tags',
        }
      }
    }
  }

  onTabClick(e) {
    UrlParams.del('idp');
    UrlParams.del('idc');
    UrlParams.del('idt');
  }

  visibleCallback() {
    if (UrlParams.get('idc')) {
      this.shadowRoot.querySelector('sl-tab-group').show('components');
    }
  }

  render() {
    return html`
        <section-header backroute="/private" micon="storefront">${this._i18n('title')}</section-header>
        <br/>

        <sl-tab-group>
          <sl-tab slot="nav" panel="products" @click=${this.onTabClick}>${this._i18n('creation')}</sl-tab>
          <sl-tab slot="nav" panel="components" @click=${this.onTabClick}>${this._i18n('components')}</sl-tab>
          <sl-tab slot="nav" panel="tags" @click=${this.onTabClick}>${this._i18n('tags')}</sl-tab>

          <sl-tab-panel name="products">
            <br/>
            <page-admin-products-list></page-admin-products-list>
          </sl-tab-panel>

          <sl-tab-panel name="components">
            <br/>
            <page-admin-product-components-list></page-admin-product-components-list>
          </sl-tab-panel>

          <sl-tab-panel name="tags">
            <br/>
            <page-admin-tags-list></page-admin-tags-list>
          </sl-tab-panel>

        </sl-tab-group>
      </div>
    `;
  }
}

customElements.define('page-admin-products', AdminProductPage);
