import { BaseElement, html, css } from 'UX';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { Fetcher, Session, Notify } from 'Utils';
import localStyles from './styles.js';

class FormPersonalInfos extends BaseElement {
  static get styles() {
    return [
      //super.styles,
      localStyles,
      css`
        :host {
          display:none;
        }

        .tabcontent {
          width:100%;
        }

        .buttons {
          margin-top:40px;
          text-align:right;
        }
      `
    ];
  }
  
  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          title: 'Personal Informations',
          mandatory_fields: 'All fields are mandatory.',
          input_firstname:'Firstname',
          input_lastname:'Lastname',
          button_save: 'Save',
          errors:{
            EEMPTY_LASTNAME: 'The lastname is mandatory',
            EEMPTY_FIRSTNAME: 'The firstname is mandatory',
          },
          message_success:'Your personal informations have been updated.'
        }
      },
      french:{
        translation: {
          title: 'Informations personnelles',
          mandatory_fields: 'Tous les champs sont obligatoires.',
          input_firstname:'Prénom',
          input_lastname:'Nom',
          button_save: 'Enregistrer',
          errors:{
            EEMPTY_LASTNAME: 'Le nom est obligatoire',
            EEMPTY_FIRSTNAME: 'Le prénom est obligatoire',
          },
          message_success:'Vos informations personnelles ont été mise à jour.'
        }
      }
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.sessionData = await Session.get();
    this.requestUpdate();
  }

  toggleForm(form, readonly) {
    const elements = getFormControls(form);
    for (let i = 0, len = elements.length; i < len; ++i) {
      //elements[i].readonly = readonly;
      elements[i].disabled = readonly;
    }
  }

  async handleSubmitInfos(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    const form = this.shadowRoot.querySelector('form');
    this.toggleForm(form, true);

    const bt = this.shadowRoot.querySelector('#bt_submit_info');
    bt.disabled = true;
    bt.loading = true;

    const elements = getFormControls(form);
    const data = {};
    for (let i = 0, len = elements.length; i < len; ++i) {
      data[elements[i].name] = elements[i].value;
    }

    const response = await Fetcher.post('private/user/account/update', data);

    bt.loading = false;
    bt.disabled = false;
    this.toggleForm(form, false);

    if (!response) {
      return;
    }

    if (!response.ok) {

      const lastNameField = this.shadowRoot.querySelector('sl-input[name="lastname"]');
      const firstNameField = this.shadowRoot.querySelector('sl-input[name="firstname"]');

      setTimeout(() => {
        if (response.error === 'EEMPTY_LASTNAME') {
          lastNameField.setCustomValidity(this._i18n('errors.EEMPTY_LASTNAME'));
          lastNameField.reportValidity();
        }

        if (response.error === 'EEMPTY_FIRSTNAME') {
          firstNameField.setCustomValidity(this._i18n('errors.EEMPTY_FIRSTNAME'));
          firstNameField.reportValidity();
        }
      }, 1);

      return;
    }

    Notify.success({message:this._i18n('message_success')});

    const event = new CustomEvent('session-force-refresh');
    window.dispatchEvent(event);

  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  render() {
    if (!this.sessionData) return;
    return html`
      <section-header size="medium" micon="id_card">${this._i18n('title')}</section-header>
      <br/>
      <form @submit="${this.handleSubmitInfos}">
        <div class="comment">${this._i18n('mandatory_fields')}</div><br/><br/><br/>
        <sl-input class="label-on-left" size="small" label="${this._i18n('input_firstname')}" name="firstname" minlength="1" value="${this.sessionData.firstname}" spellcheck="false"></sl-input>
        <sl-input class="label-on-left" size="small" label="${this._i18n('input_lastname')}" name="lastname" minlength="1" autofocus value="${this.sessionData.lastname}" spellcheck="false"></sl-input>
        <div class="buttons">
          <sl-button @click="${this.handleSubmitInfos}" id="bt_submit_info" variant="primary">${this._i18n('button_save')}</sl-button>
        </div>
      </form>
    `
  }

}

customElements.define('form-user-account-personal-infos', FormPersonalInfos);