import App from 'App';

/*
function escapeHtml(html) {
  const div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML.replace(/\n/g, '<br>');
}
*/

function notify(message, variant = 'primary', icon = 'check', duration = 5000) {
  message = message.replace(/\n/g, '<br/>');
  const alert = Object.assign(document.createElement('sl-alert'), {
    closable: true,
    variant,
    duration,
    innerHTML: `<m-icon slot="icon" name="${icon}" style="font-size:50px;"></m-icon>${message}`
  });
  
  document.body.append(alert);
  
  alert.addEventListener('click', (ev) => {
    if (ev.target.tagName !== 'SL-ALERT') {
      alert.duration = Infinity;
      alert.setAttribute('duration', Infinity);
    }
  });

  return alert.toast();
}

function errorCode(id, url, detail) {

  if (!codes[id]) id = 'DEFAULT';
  let msg = '<alert-message ';
  msg+=` title="${codes[id][App.config.language].title}"`;
  msg+=` message="${codes[id][App.config.language].text} ${detail || ''}"`
  if (url) msg+=` url="${url}"`;
  msg+='></alert-message>';
  notify(msg, 'danger', 'bug_report', codes[id].duration || 10000);
}

function success(opts) {
  let msg = '<alert-message ';
  if (opts.title) msg+=` title="${opts.title}"`;
  if (opts.message) msg+=` message="${opts.message}"`
  if (opts.url) msg+=` url="${opts.url}"`;
  msg+='></alert-message>';
  notify(msg, opts.style || 'primary', opts.icon || 'check', opts.duration || 5000);
}


const codes = {
  'ENETWORK_ERROR': {
    french:{
      title:'Une erreur réseau est survenue.',
      text:'Merci de vérifier votre connexion internet et de réessayer ultérieurement.',
      help:'Apparaît lorsque l\'application n\'arrive pas à joindre le serveur pendant un call API'
    },
    english:{
      title:'A network error has occurred.',
      text:'Please check your internet connection and try again later.',
      help:'Appears when the application cannot reach the server during an API call'
    }
  },
  'EHTTP_404': {
    french:{
      title:'Une erreur 404 (introuvable) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur retourne une erreur 404'
    },
    english:{
      title:'A 404 error (not found) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server returns a 404 error'
    }
  },
  'EHTTP_405': {
    french:{
      title:'Une erreur 405 (méthode non autorisée) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur a refusé la méthode HTTP utilisée'
    },
    english:{
      title:'A 405 error (method not allowed) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server refused the HTTP method used'
    }
  },
  'EHTTP_504': {
    french:{
      title:'Une erreur 504 (gateway timeout) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur n\'est pas joignable ou a crashé'
    },
    english:{
      title:'A 504 error (gateway timeout) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server is not reachable or has crashed'
    }
  },
  'ESESSION_EXPIRED': {
    french:{
      title:'Session expirée',
      text:'Votre session a expirée, veuillez vous reconnecter',
      help:'Apparaît lorsque la session a expirée'
    },
    english:{
      title:'Session expired',
      text:'Your session has expired, please log in again',
      help:'Appears when the session has expired'
    }
  },
  'ETOKEN': {
    french:{
      title:'Erreur de sécurité',
      text:'Token CSRF incorrecte, merci de réessayer.',
      help:'Apparaît lorsque le token CSRF est incorrecte'
    },
    english:{
      title:'Security error',
      text:'Incorrect CSRF token, please try again.',
      help:'Appears when the CSRF token is incorrect'
    }
  },
  "EREFRESH_TOKEN_ERROR": {
    french:{
      title:'Une erreur est survenue pendant le rafraîchissement de votre session.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque qu\'un problème est survenu pendant le rafraîchissement du token JWT'
    },
    english:{
      title:'An error occurred while refreshing your session.',
      text:'Please try again later.',
      help:'Appears when a problem occurred during the refresh of the JWT token'
    }
  },
  'EVALIDATION_FAILED': {
    french:{
      title:'Une erreur de validation est survenue.',
      text:'Merci de vérifier les champs du formulaire.',
      help:'Apparaît lorsqu\'un champ du formulaire n\'est pas valide'
    },
    english:{
      title:'A validation error has occurred.',
      text:'Please check the form fields.',
      help:'Appears when a form field is not valid'
    }
  },
  'ENOTIFICATION_ALREADY_SUBSCRIBED': {
    french:{
      title:'Erreur: déjà abonné à ce canal',
      text:'Vous êtes déjà abonné à ce canal de notification.',
      help:'Apparaît lorsque l\'utilisateur est déjà abonné à un canal de notification'
    },
    english:{
      title:'Error: already subscribed to this notification channel.',
      text:'You are already subscribed to this notification channel.',
      help:'Appears when the user is already subscribed to a notification channel'
    }
  },
  'EPRODUCT_NOT_FOUND': {
    french:{
      title:'Erreur: produit introuvable',
      text:'Le produit demandé est introuvable.',
      help:'Apparaît lorsque le produit demandé n\'existe pas'
    },
    english:{
      title:'Error: product not found',
      text:'The requested product was not found.',
      help:'Appears when the requested product does not exist'
    }
  },
  'EFILE_TOO_LARGE':{
    french:{
      title:'Erreur: fichier trop volumineux',
      text:'Le fichier est trop volumineux.',
      help:'Apparaît lorsque le fichier envoyé est trop volumineux'
    },
    english:{
      title:'Error: file too large',
      text:'The file is too large.',
      help:'Appears when the file sent is too large'
    }
  },
  'EFILE_NOT_FOUND':{
    french:{
      title:'Erreur: fichier introuvable',
      text:'Le fichier est introuvable.',
      help:'Apparaît lorsque le fichier demandé n\'existe pas'
    },
    english:{
      title:'Error: file not found',
      text:'The file is not found.',
      help:'Appears when the requested file does not exist'
    }
  },
  'DEFAULT': {
    french:{
      title:'Une erreur inconnue est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Message d\'erreur générique utilisé lorsqu\'aucun code d\'erreur envoyé par le serveur ne correspond'
    },
    english:{
      title:'An unknown error has occurred.',
      text:'Please try again later.',
      help:'Generic error message used when no error code sent by the server matches'
    }
  }
}

export default {
  success: success,
  info: (message, duration = 5000) => notify(message, 'primary', 'info', duration),
  error: (message, duration = 10000) => notify(message, 'danger', 'bug_report', duration),
  errorCode,
  codes
}