import { PageElement, html, css } from 'UX';
import { Router } from 'Router';

class LogoutPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .error_container {
          padding:20px;
          text-align:center;
          font-size:1em;
        }

        .error_container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          title:`Goodbye`,
          text:'You have been successfully disconnected.',
          back:`Back to home`,
        }
      },
      french:{
        translation: {
          title:`A bientôt`,
          text:'Vous avez été déconnecté avec succès.',
          back:`Retourner à l'accueil`,
        }
      }
    }
  }


  render() {
    return html`
      <section-header>${this._i18n('title')}</section-header>
      <div class="error_container">
        <p>${this._i18n('text')}</p>
        <br/>
        <sl-button @click="${this.goBack}" size="medium">${this._i18n('back')}</sl-button>
      </div>
    `;
  }

  goBack() {
    Router.go('/');
  }

}

customElements.define('page-user-logout', LogoutPage);