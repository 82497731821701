import { html, css } from 'UX';
import { Fetcher } from 'Utils';

import './edit.js';
import TableBasic from 'Components/TableBasic.js'

const IMG_WIDTH = 80;
const IMG_HEIGHT = 80;

class AdminProductList extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        m-icon[name="visibility"] {
          color:var(--sl-color-primary-500);
        }

        m-icon[name="visibility_off"] {
          color:red;
        }

        table td:first-child {
          padding: 0;
          width:80px;
        }

        table td {
          position: relative;
          height: 70px;
          vertical-align: top;
        }

        sl-dropdown {
          text-align:left;
        }

        sl-menu-item m-icon {
          font-size:1.5em;
        }

        sl-menu-item m-icon[name="delete"] {
          color:var(--sl-color-danger-500);
        }

        sl-menu-item::part(base) {
          font-size:0.9em;
          padding:2px;
          padding-left:5px;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        img-proxy {
          margin:5px;
        }
      `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/products';
    this.urlIdParam = 'idp';
    this.itemModel = { images: [], components: [] };
    this.itemTitleFieldName = 'title_fr';
    this.eventUpdatedName = 'product-updated';

    this._i18nResources = this._i18nMergeResources({
      english:{
        translation: {
          add:'Add a creation',
          empty:'No creation available',
          product:'creation',
          public:'public',
          hidden:'hidden',
          image:'Image',
          new:'New',
          title:'Title',
          price:'Price',
          promo:'Promo',
          stock:'Stock',
        },
      },
      french:{
        translation: {
          add:'Ajouter une création',
          empty:'Aucune création disponible',
          product:'création',
          public:'publique',
          hidden:'cachée',
          new:'Nouveauté',
          image:'Image',
          title:'Titre',
          price:'Prix',
          promo:'Promo',
          stock:'Stock',
        }
      }
    });
  }

  getDiscountPercentage(item) {
    const originalPrice = parseFloat(item.price);
    const discountedPrice = parseFloat(item.price_promo);
    return (((originalPrice - discountedPrice) / originalPrice) * 100).toFixed(0);
  }

  _getItemTitle(item) {
    return item.title_fr;
  }

  async toggleVisibility(event) {
    const itemId = event.target.getAttribute('data-item-id');
    if (!itemId) return;

    const item = this.items.find((p) => p._id === itemId);
    if (!item) return;

    item.enable = !item.enable;

    this.loading = true;
    await Fetcher.put(`private/admin/products/${itemId}/visibility`, { enable: item.enable });
    this.loading = false;
  }

  getFirstImage(item) {
    if (!item.images?.length) return;
    return html`<img-proxy width="${IMG_WIDTH}" height="${IMG_HEIGHT}" src="${item.images[0].src}"></img-proxy>`;
  }

  _getTableHeader() {
    const visibleCount = this.items?.filter(p => p.enable).length || 0;
    const hiddenCount = this.items?.filter(p => !p.enable).length || 0;
    const totalCount = visibleCount + hiddenCount;
    const totalText = totalCount >= 1 ? `${this._i18n('product')}s` : this._i18n('product');
    const visibleText = visibleCount > 1 ? `${this._i18n('public')}s` : this._i18n('public');
    const hiddenText = hiddenCount > 1 ? `${this._i18n('hidden')}s` : this._i18n('hidden');
    return `${totalCount} ${totalText} (${visibleCount} ${visibleText}, ${hiddenCount} ${hiddenText})`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    const euro = String.fromCharCode(8364);

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th>&nbsp;${this._i18n('image')}</th>
            <th>&nbsp;${this._i18n('title')}</th>
            <th class="center">${this._i18n('new')}</th>
            <th class="center">${this._i18n('price')}</th>
            <th class="center">${this._i18n('promo')}</th>
            <th class="center">${this._i18n('stock')}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${this.getFirstImage(item)}</td>
            <td>${item.title_fr}</td>
            <td style="width:60px" class="center">${item.display_as_new ? 'Oui' : ''}</td>
            <td style="width:60px" class="center">${item.price ? `${item.price} ${euro}` : 'N/A'}</td>
            <td style="width:100px" class="center">${item.price_promo ? `${item.price_promo} ${euro} (-${this.getDiscountPercentage(item)}%)` : 'Non'}</td>
            <td style="width:60px" class="center">${item.qte}</td>
            <td class="icon">
              <m-icon 
                data-item-id="${item._id}"
                name="${item.enable ? 'visibility' : 'visibility_off'}"
                nogradient
                title="${item.enable ? 'Visible pour tout le monde' : 'Visible pour les administrateurs uniquement'}"
                @click=${this.toggleVisibility}
              ></m-icon>
            </td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._i18n('modify')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._i18n('del')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderEditComponent() {
    return html`<admin-product-edit id="modal-edit"></admin-product-edit>`;
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
          <ul>
            <li>Cette action est irréversible, réservée aux développeurs et administrateurs.</li>
            <li>Les transactions clients liées à ce produit ainsi que les images seront également supprimées.</li>
            <li>Il est préférable de désactiver le produit si vous souhaitez le rendre invisible plutôt que de le supprimer.</li>
          </ul>
        </div>

        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._i18n('delete')}</sl-button>
      </modal-dialog>
    `;
  }

  render() {    
    return html`
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
    `;
  }
}

customElements.define('page-admin-products-list', AdminProductList);