import { html, css } from 'UX';
import App from 'App';

import './edit.js';
import TableBasic from 'Components/TableBasic.js'

class AdminTagsList extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`      
        m-icon {
          cursor:pointer;
        }

        m-icon[name="more_vert"] {
          padding-top:2px;
          font-size: 20x;
        }

        table td:first-child {
        }

        table td.icon {
          width:30px;
          padding:0;
          text-align:center;
        }

        .center {
          text-align:center;
        }

        sl-dropdown {
          text-align:left;
        }

        sl-menu-item m-icon {
          font-size:1.5em;
        }

        sl-menu-item m-icon[name="delete"] {
          color:var(--sl-color-danger-500);
        }

        sl-menu-item::part(base) {
          font-size:0.9em;
          padding:2px;
          padding-left:5px;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        img-proxy {
          margin:5px;
        }
      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/tags';
    this.urlIdParam = 'idt';
    this.itemModel = { };
    this.itemTitleFieldName = 'title_fr';
    this.eventUpdatedName = 'tag-updated';

    this._i18nResources = this._i18nMergeResources({
      english:{
        translation: {
          add:'Add a tag',
          empty:'No tag available',
          product:'tag',
        },
      },
      french:{
        translation: {
          add:'Ajouter un tag',
          empty:'Aucun tag disponible',
          product:'tag',
        }
      }
    });
  }

  _sortItems(items) {
    return items.sort((a, b) => {
      const titleA = this._getItemTitle(a).toLowerCase();
      const titleB = this._getItemTitle(b).toLowerCase();
      return titleA.localeCompare(titleB);
    });
  }

  _getItemTitle(item) {
    const key = `title_${App.config.lg}`;
    return item[key] || item.title_fr;
  }

  _renderEditComponent() {
    return html`<admin-tag-edit id="modal-edit"></admin-tag-edit>`;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? 'tags' : 'tag';
    return `${totalCount} ${totalText}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <th>&nbsp;Tag</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${this._getItemTitle(item)}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._i18n('modify')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._i18n('del')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
        </div>

        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._i18n('delete')}</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('page-admin-tags-list', AdminTagsList);