import { PageElement, html, css } from 'UX';


class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        m-icon[name="menu"] {
          font-size:30px;
          margin-right:5px;
        }

        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
        },
      },
      french:{
        translation: {
        }
      }
    }
  }


  render() {
    return html`
    <section-header micon="category">
      <a-route href="/private">Administration</a-route> /
      <a-route href="/private/doc/api">Documentations API</a-route>
    </section-header>
    <br/>
    <div class="grid">
      <box-styled layout="row" micon="api" aroute="/api/v2/public/api-docs/">API publique</box-styled>
      <box-styled layout="row" micon="api" aroute="/api/v2/private/api-docs/">API privée</box-styled>
      <box-styled layout="row" micon="api" aroute="/api/v2/__webhooks/api-docs/">Webhooks internes</box-styled>
    </div>
    `;
  }

}

customElements.define('page-doc-api', Page);