import { BaseElement, html, css, setAnimation } from 'UX';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { literal } from 'lit/static-html.js';
import menu from './menu.js';
import App from 'App';
import { Session } from 'Utils';

class MenuLarge extends BaseElement {
  static get styles() {
    return css`
      :host {
        text-align:center;
        height:100%;
        width:100%;
      }

      .container {
        display:flex;
        gap:30px;
        justify-content:flex-start;
        align-items:center;
        height:100%;
        position:relative;
      }
      
      .block, .block_top {
        cursor:pointer;
        padding:2px;
        padding-left:5px;
        padding-right:5px;
        min-width:120px;
        transition: background-color 0.1s;
      }

      .block_top {
        overflow:hidden;
        white-space:nowrap;
      }

      .container.collapsed .block_top {
        padding-top:5px;
        padding-left:0px;
      }

      .collapsed sl-card {
        height: 52px;
        overflow: hidden;
      }

      .block img {
        height:30px;
      }

      .block_top img {
        height:30px;
      }

      .block .text, .block_top .text {
        overflow:hidden;
        font-size:14px;
        color:white;
        text-align:left;
      }

      .block_top .text {
        padding-left:5px;
        display:flex;
      }

      .container.collapsed .block_top .text {
        padding-left:0px;
      }

      .block .flex_dynamic, .block_top .flex_dynamic {
        display: flex;
        flex-direction: column;
        align-items: center;
        position:relative;
      }

      .block .flex_dynamic {
        flex-direction: row;
        position:relative;
      }

      .border:after, .border:before {
        display:block;
        content: '';
        border-bottom: solid 2px #019fb6;  
        transform: scaleX(0);  
        transition: transform 150ms ease-in-out;
      }

      .border:before {
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
      }

      .border:hover:after {
        transition-delay:150ms;
      }

      .border:hover:after, .flex_dynamic:hover:before { transform: scaleX(1); }


      .block .flex_horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        position:relative;
      }

      .block:hover {
        /*background-color: var(--nsys-blue5);*/
        /*border-radius:5px;*/
      }

      .block a {
        text-decoration:none;
      }

      .container.collapsed .flex_dynamic  {
        flex-direction: row;
      }

      .container.collapsed img {
        height:28px;
      }

      sl-card {
        position:absolute;
        width:100vw;
        box-sizing: border-box;
        box-shadow:0px 5px 5px 0px #00000066;
      }

      sl-card::part(base) {
        background-color: #1e5179;
        border:initial;
        border-radius:0px;
        
        width:100% !important;
      }

      sl-card .text {
        color:white;
        padding-right:10px;
      }

      sl-card::part(body) {
        padding:0;
        padding-bottom:4px;
        padding-top:4px;
      }

      .container_top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top:5px;
      }

      .container_top.sub {
        margin-top:5px;
      }

      .divider {
        height:10px;
      }

      .img_container {
        min-width:40px;
        text-align:center;
        overflow:hidden;
      }

      .container.collapsed .img_container {
        height:38px;
      }

      .container.collapsed img {
        margin-top: 5px;
      }

      a-route::part(text) {
        text-decoration:none;
        color:white;
      }
      
      .text a {
        color:white;
        text-decoration:none;
      }

      sl-dropdown {
        height:100%;
        align-items:center;
        display:flex;
      }

      sl-dropdown.right {
        margin-left:auto;
        margin-right:30px;
      }

      sl-dropdown[open] {
        background-color: #1e5179;
      }

      sl-dropdown:hover {
        /*background-color: var(--nsys-blue4);*/
      }

      sl-button::part(base) {
        background-color:initial;
        padding-inline-end:0;
        border:initial !important;
        outline:initial !important;
      }

      sl-button::part(label) {
        line-height:initial;
        font-size:initial;
        margin:initial;
        padding-left:5px;
        padding-left:5px;
      }

      svg {
        float:right;
        margin-left:4px;
      }

      m-icon {
        font-size:30px;
        color:white;
        margin-top:2px;
      }

      /*
      @media (max-width: 1150px) {
        .container_top {
          flex-direction:column;
        }

        
        sl-card {
          position:initial;
          width:initial;
        }
      }
      */

      .force13 {
        font-size:13px !important;
      }

      @media (max-width: 670px) {
        :host {
          display:none;
        }
      }

      @media (max-width: 1150px) {
        .block_top {
          min-width:initial;
        }

        .container {
          gap:5px;
        }

        .block_top .text {
          display:none;
        }

        .block_top img {
          height: 46px;
        }
        
      }
      
    `;
  }

  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResizeDelayed = this.handleResizeDelayed.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLanguageChanged = this.handleLanguageChanged.bind(this);

    this.caret = '<svg width="15" viewBox="0 0 24 24" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" part="svg"><polyline points="6 9 12 15 18 9"></polyline></svg>';
  }


  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResizeDelayed);
    window.addEventListener('language-changed', this.handleLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResizeDelayed);
    window.removeEventListener('language-changed', this.handleLanguageChanged);
  }

  firstUpdated() {
    this.container = this.container || this.shadowRoot.querySelector('.container');
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.container.addEventListener('click', this.handleClick);
    this.handleResize();
    this.setupAnimations();
  }

  setupAnimations() {
    // try to prevent flickering effect failed ...
    // return;
    const dialogs = this.shadowRoot.querySelectorAll('sl-dropdown');
    for (const dialog of dialogs) {
      setAnimation(dialog, 'dropdown.show', null);
      setAnimation(dialog, 'dropdown.hide', null);
    }
  }

  handleLanguageChanged() {
    this.requestUpdate();
  }

  handleResizeDelayed() {
    clearTimeout(this.timerResize);
    this.timerResize = setTimeout(() => this.handleResize(), 10);
  }

  handleResize() {
    if (!this.dropdown) return;

    if (Session.isAuthenticated()) {
      this.container.classList.add('collapsed');
      return;
    }

     /* 
    if (window.innerWidth < 1100) {
      this.dropdown.skidding = 0;
    } else {
      this.dropdown.skidding = -100000;
    }
    */
  }

  handleScroll() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (Session.isAuthenticated()) {
        this.container.classList.add('collapsed');
        return;
      }

      let distance = -2;
      if (window.scrollY > 10) {
        this.container.classList.add('collapsed');
      } else {
        this.container.classList.remove('collapsed');
        distance=6;
      }
      this.dropdown.distance = distance;
    }, 2);
  }

  handleClick(event) {
    let target = event.target;
    const allowed = ['a', 'a-route'];
    while (target != this.container) {
      if (allowed.includes(target.tagName.toLowerCase())) {
        const dropdown = target.parentNode?.parentNode?.parentNode?.parentNode;
        if (dropdown && dropdown.tagName.toLowerCase() === 'sl-dropdown') dropdown.hide();
        break;
      }
      
      target = target.parentNode;
    }
  }

  handleDropdownClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    return html`
      <div class="container">
        ${
          Object.keys(menu).map(key => {

            const menu_item = menu[key];

            if (menu_item.grants) {
              if (!Session.hasGrants(menu_item.grants)) {
                return;
              }
            }

            const icon = menu_item.icon ? `<img src="assets/img/${menu_item.icon}"/>` : `<m-icon name="${menu_item.micon}"></m-icon>`;
            const caret = menu_item.submenu ? this.caret : '';
            let title = menu_item.title_long[App.config.language];
              

            let submenu = '';
            if (menu_item.submenu) {
              Object.keys(menu_item.submenu).map(subkey => {
                const submenu_item = menu_item.submenu[subkey];
                const submenu_icon = submenu_item.icon ? `<img src="assets/img/${submenu_item.icon}"/>` : `<m-icon name="${submenu_item.micon}"></m-icon>`;
                const submenu_title = submenu_item.title_long[App.config.language];
                const url = submenu_item.url || '';
                const target = submenu_item.target || '';
                const tag = url.startsWith('/') ? 'a-route' : 'a';

                submenu+= `
                  <div class="block border">
                    <${tag} href="${url}" target="${target}">
                      <div class="flex_horizontal">
                        <div class="img_container">${submenu_icon}</div>
                        <div class="text force13">${submenu_title}</div>
                      </div>
                    </${tag}>
                  </div>
                `;
              });

              submenu = submenu ? `<sl-card><div class="container_top sub">${submenu}</div></sl-card>` : '';

              return unsafeHTML(`
              <sl-dropdown skidding="-300000" @click="${this.handleDropdownClick}" ${menu_item.float ? `float="${menu_item.float}"` : ''}>
                <div slot="trigger">
                  <div class="block_top">
                    <div class="flex_dynamic border">
                      <div class="img_container">${icon}</div>
                      <div class="text"><span>${title}</span><span>${caret}</span></div>
                    </div>
                  </div>
                </div>
                ${submenu}
              </sl-dropdown>
            `);

            } else {
              const url = menu_item.url || '';
              const target = menu_item.target || '';
              const tag = url.startsWith('/') ? 'a-route' : 'a';

              return unsafeHTML(`
              <sl-dropdown skidding="-300000" @click="${this.handleDropdownClick}" ${menu_item.float ? `class="${menu_item.float}"` : ''}>
                <div slot="trigger">
                  <div class="block_top">
                    <${tag} href="${url}" target="${target}">
                      <div class="flex_dynamic border">
                        <div class="img_container">${icon}</div>
                        <div class="text"><span>${title}</span><span>${caret}</span></div>
                      </div>
                    </${tag}>
                  </div>
                </div>
              </sl-dropdown>
            `);
            }
          })
        }
      </div>`;
  }
}

customElements.define('header-menu-large', MenuLarge);
