import { PageElement, html, css, unsafeHTML } from 'UX';
import { Fetcher, Session } from 'Utils';
import App from 'App';

const IMG_COMPO_WIDTH = 60;
const IMG_COMPO_HEIGHT = 60;

const IMG_MINI_SIZE = 80;
const IMG_MAIN_SIZE = 330;

const BORDER_COLOR = 300;

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`

        .breadcrumb {
          display:flex;
        }

        .breadcrumb m-icon {
          font-size:20px;
          margin-top:2px;
          margin-left:10px;
        }

        .spinner-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-height:200px;
          height:200px;
        }

        .product {
          display:block;
          position:relative;
          border-radius:8px;
          overflow:hidden;
        }

        .product_grid {
          display:grid;
          overflow:hidden;
          grid-template-columns: 320px 1fr;
        }

        .product_first_image {
          border:1px solid var(--sl-color-neutral-${BORDER_COLOR});
          display:flex;
          cursor:pointer;
          justify-content:center;
          align-items:center;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position:relative;
          overflow:hidden; 
          position:relative;
          width:100%;
        }

        .product_images_list {
          display: grid;
          width:${IMG_MAIN_SIZE}px;
          grid-template-columns: repeat(4, 78px);
          margin-top:5px;
          margin-left:auto;
          margin-right:auto;
          align-items:center;
          gap:4px;
          overflow:hidden;
        }

        .product_images_list img-proxy {
          display:flex;
          justify-content:center;
          align-items:center;
        }

        .product_image {
          cursor:pointer;
          border:1px solid var(--sl-color-neutral-${BORDER_COLOR});
        }

        .product_description {
          font-size:1.1em;
          margin:20px;
          margin-top:0px;
        }

        .product_components {
          margin:20px;
          margin-top:0px;
        }

        m-icon[name="edit"] {
          color:red !important;
          cursor:pointer;
        }

        m-icon[name="visibility"] {
          color:var(--sl-color-primary-500);
        }

        m-icon[name="visibility_off"] {
          color:red;
        }

        m-icon[name="arrow_forward_ios"] {
          float:left;
          font-size:0.7em;
          margin-top:5px;
          margin-right:3px;
        }

        sl-carousel::part(base) {
          gap:10px;
          height:90vh;
        }
        
        sl-carousel-item a {
          position:absolute;
          top:0px;
          left:0px;
          right:0px;
          background-color:#000000aa;
          backdrop-filter:blur(3px);
          color:white;
          text-align:center;
          padding:10px;
          color:white;
          font-size:1em;
          text-decoration:none;
          cursor:pointer;
        }

        sl-carousel-item a:hover {
          text-decoration:underline;
        }

        .center {
          display:flex;
          justify-content:center;
          align-items:center;
          flex-direction:column;
          margin-left:auto;
          margin-right:auto;
          gap:20px;
        }

        .error m-icon {
          font-size:70px;
        }

        .error {
          font-size:1.3em;
          color:var(--sl-color-danger-500);
        }

        .components {
          display:flex;
          flex-direction:column;
        }

        .component {
          display:flex;
          gap:10px;
        }

        .component_radius {
          border-radius:50%;
          overflow:hidden;
        }

        markdown-renderer {
          font-size:1em;
        }

        .cursor_pointer {
          cursor:pointer;
          outline:1px solid var(--sl-color-neutral-${BORDER_COLOR});
        }

        table.components {
          width:100%;
          border-collapse: separate; /* Utiliser 'separate' pour que border-spacing fonctionne */
          border-spacing: 0 10px; /* Espace entre les lignes */
          text-align:left;
          margin-left:auto;
          margin-right:auto;
          font-size:0.9em;
        }

        table.components tr {
          width:100%;
          vertical-align:top;
        }

        table.components td {
          width:100%;
          padding-left:10px;
        }

        table.components td:first-child {
          padding: 0;
          width:80px;
        }

        table.components td.icon {
          width:30px;
          padding:0;
          text-align:center;
        }

        .hidden {
          opacity:0.2;
          zoom:0.9;
        }

        section-header:hover .hidden {
          opacity:1;
        }

        /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
        @media (max-width: 670px) {
          .product_grid {
            grid-template-columns: 1fr;
          }

          .product_description {
            margin-top:20px;
          }
        }

        /* Téléphones portables (grands appareils) - Entre iPhone 8 et tablettes */
        @media (min-width: 670px) and (max-width: 900px) {
        }

        /* Tablettes en mode portrait */
        @media (min-width: 768px) and (max-width: 1024px) {
        }

        /* Tablettes en mode paysage */
        @media (min-width: 1024px) and (max-width: 1200px) {
        }

        `
    ];
  }

  static get properties() {
    return {
      product: { type: Object }
    }
  }

  constructor() {
    super();

    this._i18nResources = {
      english:{
        translation: {
          title:'Our creations'
        }
      },
      french:{
        translation: {
          title:'Nos créations'
        }
      }
    }

    this.product = null;
    this.handleLanguageChanged = this.handleLanguageChanged.bind(this);
    this.openCaroussel = this.openCaroussel.bind(this);
    this.onLittleImageClick = this.onLittleImageClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.handleLanguageChanged);
    window.addEventListener('resize', this.handleResize);
  } 

  handleResize() {
    this.requestUpdate();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.handleLanguageChanged);
    window.removeEventListener('resize', this.handleResize);
  }

  handleLanguageChanged(e) {
    this.requestUpdate();
  } 

  async firstUpdated() {
    super.firstUpdated();
    await this.loadProduct();
    this.carousel = this.shadowRoot.querySelector('#modal-product-images');
  } 

  async loadProduct(){
    this.productId = App.router.location.params.id;

    // sitemap
    if (this.productId.includes('@')) {
      this.productId = this.productId.split('@')[1];
    }

    const response = await Fetcher.get(`public/shop/product/${this.productId}`);
    if (!response) return;
    this.product = response.data; 
    document.title = this.getProductTitle(this.product);
  } 

  getProductTitle(product, prefix) {
    if (!product) return '';
    const key = `title_${App.config.lg}`;
    const title = product[key] || product.title_fr;
    if (!title) return '';
    if (prefix) return unsafeHTML(`${prefix} ${product[key] || product.title_fr}`);
    return title;
  }

  getProductImages(product) {
    if (!product.images) return [];
    return product.images.map(image => {
      if (image.src) {
        const title = image.filename.replace(/\.[^/.]+$/, "");
        return html`<img-proxy
          class="cursor_pointer"
          src="${image.src}"
          width="${IMG_MINI_SIZE}"
          height="${IMG_MINI_SIZE}"
          title="${title}"
          @click=${this.onLittleImageClick}
        ></img-proxy>`;
      }
      return null;
    }).filter(image => image !== null);
  }

  onLittleImageClick(ev) {
    this.productImage = this.productImage || this.shadowRoot.querySelector('.product_image');
    this.productImage.src = ev.target.src;
  }

  renderMarkdown() {

    const field = `desc_${App.config.lg}`;
    const content = this.product[field];
    if (!content) return this.product.desc_fr;

    return html`
      <markdown-renderer 
        markdown="${content}">
      </markdown-renderer>
    `;
  }

  renderLoader(){
    if (this.product) return;

    return html`
      <div class="spinner-container">
        <sl-spinner style="font-size: 5rem;"></sl-spinner>
      </div>
    `;
  }

  openCaroussel() {
    this.carousel.label = this.getProductTitle(this.product);
    this.carousel.show();
  }

  getProductStyle(product) {
    let pcss = 'product';
    let style = '';

    if (!product.enable) pcss+=' disabled';
    const str = `class="${pcss}"`;
    return str;
  }


  getComponentFirstImage(component) {
    if (!component.images?.length) return;
    return html`<img-proxy class="component_radius" width="${IMG_COMPO_WIDTH}" height="${IMG_COMPO_HEIGHT}" src="${component.images[0].src}"></img-proxy>`;
  }

  getComponentTitle(component) {
    const key = `title_${App.config.lg}`;
    return component[key] || component.title_fr;
  }

  getComponentDescription(component) {
    const key = `desc_${App.config.lg}`;
    return component[key] || component.desc_fr;
  }
  

  renderComponents() {
    if (!this.product?.components || !this.product?.components.length) return;

    return html`
      <section-header size="small">Composition</section-header>
      <br/>
      <table class="components">
        <tbody>
          ${this.product.components.map((component, index) => html`
            <tr>
              <td>${this.getComponentFirstImage(component)}</td>
              <td>
                <b>${this.getComponentTitle(component)}</b><br/>
                ${this.getComponentDescription(component)}
              </td>
            </tr>
          `)}
        </tbody>
      </table>
    `;
  }

  renderProduct(){
    if (!this.product) return;

    if (!this.product._id) {
      return html`
        <div class="center error">
          <m-icon name="error"></m-icon>
          <div>Création introuvable !</div>
          <a-route href="/public/shop"><sl-button>Retourner à la liste des créations</sl-button></a-route>
        </div>`;
    }

    return html`
      <div ${this.getProductStyle(this.product)}>
        <div class="product_grid">
          <div class="product_images">
            <div class="center" @click=${this.openCaroussel}>
              <img-proxy class="product_image" src="${this.product.images[0].src}" width="${IMG_MAIN_SIZE}" height="${IMG_MAIN_SIZE}"></img-proxy>
            </div>
            <div class="product_images_list">
              ${this.getProductImages(this.product)}
            </div>
          </div>
          <div class="product_description_and_components">
            <div class="product_description">${this.renderMarkdown()}</div>
            <div class="product_components">${this.renderComponents()}</div>
          </div>
        </div>
      </div>
    `;
  }

  async addToBasket() {
    if (!this.product) return;
    const response = await Fetcher.post('public/shop/basket/add', { productId: this.product._id });
    if (!response) return;
    App.router.navigate('/public/shop/basket');
  }

  getPrivateIcons() {
    if (!this.product) return;
    if (!Session.isBoss()) {
      return html`
        <div slot="right">
          <!-- <sl-button variant="warning" size="small" pill @click=${this.addToBasket}>Ajouter au panier</sl-button> -->
        </div>
      `
    }

    return html`
      <div slot="right">
        <div class="hidden">
          ${this.product.enable
            ? html`<m-icon name="visibility" title="Ce produit est visible pour tout le monde"></m-icon>`
            : html`<m-icon name="visibility_off" title="Ce produit n'est visible pour les administrateurs"></m-icon>`
          }
          <a-route href="/private/admin/products?idp=${this.product._id}">
            <m-icon name="edit" title="Editer le produit" nogradient @click="${this.editProduct}"></m-icon>
          </a-route>
        </div>
      </div>
    `;
  }

  getImageBackgroundColor() {
    let bg = getComputedStyle(document.body).getPropertyValue('--page-background-color').replace('#','');
    if (bg.length > 6) bg = bg.substring(0,6);
    return bg;
  }

  renderCaroussel() {
    if (!this.product) return;
    if (!this.product.images) return;
    const w = window.innerWidth-30;
    const h = window.innerHeight-50;
    return this.product.images.map(image => unsafeHTML(`
      <sl-carousel-item class="carousel_item" style="position:relative;">
        <img-proxy src="${image.src}" width="${w}" height="${h}"></img-proxy>
      </sl-carousel-item>
    `));
  }

  render() {
    return html`
      <section-header aroute="/public/shop/" micon="storefront">
        ${this.getProductTitle(this.product)}
        ${this.getPrivateIcons()}
      </section-header>
      <br/>
      ${this.renderLoader()}
      ${this.renderProduct()}
      
      <modal-drawer id="modal-product-images" fullscreen hideButtons>
        <sl-carousel pagination mouse-dragging loop>
          ${this.renderCaroussel()}
        </sl-carousel>
      </modal-drawer>

      <br/><br/><br/>
    `;
  }

}

customElements.define('page-shop-product', Page);