import { LitElement, html, css } from 'lit-element';
import { marked } from 'marked';

class MarkdownRenderer extends LitElement {
  static styles = css`
    :host {
      display: block;
      font-size: 0.9em;
      text-align: left;
      white-space: break-spaces;
      width:100%;
    }

    h1 {
      margin-top:0px;
    }

    h2 {
      margin-top:0px;
    }

    h3 {
      margin-top:0px;
    }

    h4 {
      margin-top:0px;
    }

    p {
      margin:0px;
      padding:0px;
    }

    pre {
      background-color: #f3f3f3;
      padding: 10px;
      border-radius: 4px;
    }

    br {
      height:0px;
    }

    strong {
      padding:0px;
      margin:0px;
    }

    ul {
      margin-top:-25px;
      margin-bottom:0px;
      line-height:20px;
    }
    
    li {
      margin: -10px 0;
    }

    td {
      vertical-align:top;
      border-bottom:1px solid var(--sl-color-neutral-300);
    }

    th {
      border-bottom:1px solid var(--sl-color-neutral-300);
    }

    tr td:first-child {
      font-weight: bold;
      min-width:200px;
    }

    code {
      font-family: 'Courier New', Courier, monospace;
      background-color: #f3f3f3;
      padding: 2px 4px;
      border-radius: 4px;
    }
  `;

  static properties = {
    markdown: { type: String },
  };

  constructor() {
    super();
    this.markdown = '';
  }

  render() {
    // Utilise `marked` pour convertir le markdown en HTML
    const renderedHtml = marked(this.markdown);
    return html`<div .innerHTML="${renderedHtml}"></div>`;
  }
}

customElements.define('markdown-renderer', MarkdownRenderer);