import { LitElement, html, css } from 'lit-element';

class BaseEl extends LitElement {
  // Styles (optionnel)
  static styles = css`
    div {
      padding: 16px;
      background-color: lightgray;
      border-radius: 4px;
      cursor: pointer;
    }
  `;

  // Template (affichage)
  render() {
    return html`
      <div @click=${this._handleClick}>
        <slot></slot> <!-- Permet d'insérer du contenu dans le composant -->
      </div>
    `;
  }

  // Méthode pour gérer le clic
  _handleClick(e) {
    // Le clic est déjà natif, donc pas besoin d'émettre manuellement l'événement
    console.log("BaseEl cliqué!");
  }
}

customElements.define('base-el', BaseEl);

// Usage
// <base-el @click=${this.doStuff}></base-el>