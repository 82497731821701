import { Manager } from 'socket.io-client';
import Logger from './Logger.js';

const log = new Logger('Socket', 'debug');
const protocol = 'wss:'
const hostname = window.location.hostname;
const port = window.location.port;
const isStandardPort = (port === '80' || port === '443' || !port);
const url = isStandardPort 
  ? `${protocol}//${hostname}` 
  : `${protocol}//${hostname}:${port}`;


const manager = new Manager(url, {
  forceNew: true,
  rejectUnauthorized: false,
  path:'/io',
  transports: ['websocket'],
  reconnectionDelayMax: 10000,
});

const channels = {};

function subscribe(channel) {

  const socket = manager.socket(channel);

  if (ENV === 'dev') {
    //log.debug(`${channel}: connecting`);

    //socket.on('connect',              () =>       { log.debug(`${channel}: connected`, socket.id); });
    //socket.on('disconnect',           () =>       { log.debug(`${channel}: disconnected`); });
    socket.io.on('reconnect',          attempt =>  { log.warn(`${channel}: reconnect ${attempt}`); });
    socket.io.on('error',              error =>    { log.error(`${channel}: ${error}`) });
    /*
    socket.io.on('reconnect_attempt',  attempt =>  { log.debug(`${channel}: reconnect_attempt ${attempt}`); });
    socket.io.on('reconnect_error',    error =>    { log.error(`${channel}: reconnect_error: `, error.message); });
    socket.io.on('reconnect_failed',   error =>    { log.error(`${channel}: reconnect_failed`, error); });
    socket.io.on('ping', () => { log.debug(`${channel}: ping`); });
    */
  }

  channels[channel] = socket;

  return socket;
}

function unsubscribe(channel) {
  if (channels[channel]) {
    channels[channel].close();
    delete channels[channel];
  }
}



export default {
  manager,
  subscribe,
  unsubscribe

}