import { PageElement, html, css } from 'UX';
import { Fetcher } from 'Utils';
import merge from 'deepmerge';

class TableBasic extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        
        .loader_container {
          height:55px;
          align-content:center;
        }

        m-icon {
          cursor:pointer;
          font-size:0.9em;
        }

        .itemTitle {
          padding-left:5px;
          padding-right:5px;
          font-weight:bold;
        }

        table {
          width:100%;
          border-collapse: collapse;
          font-size:0.9em;
          text-align:left;
          margin-left:auto;
          margin-right:auto;
        }

        table.disabled {
          pointer-events:none;
          filter: grayscale(100%);
          opacity:0.5;
        }

        table thead {
          font-weight:bold;
          height:40px;
          align-items:center;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        table th {
          border: 1px solid var(--sl-color-neutral-300);
          padding-left: 10px;
        }

        table tr {
          cursor:pointer;
        }

        table td {
          padding: 7px;
          border:1px solid var(--sl-color-neutral-300);
        }

        table td.icon {
          width:30px;
          text-align:center;
          font-size:1.8em;
        }

        table tbody tr:nth-child(even) td {
          
        }

        table tbody tr:nth-child(odd) td {
          background-color: var(--sl-color-neutral-50);
        }

        .center {
          text-align:center;
        }

        sl-dropdown {
          text-align:left;
          height: 22px;
          overflow: hidden;
        }

        sl-menu-item m-icon {
          font-size:1.5em;
        }

        sl-menu-item m-icon[name="delete"] {
          color:var(--sl-color-danger-500);
        }

        sl-menu-item::part(base) {
          font-size:0.85em;
          padding:2px;
          padding-left:5px;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.loading = true;
    this.apiEndpoint = null; // private/admin/items
    this._logEnable = false;
    this.urlIdParam = 'id';
    this.itemModel = {};
    this.itemTitleFieldName = 'title_fr';
    this.eventUpdatedName = 'item-updated';
    this.items = null;
    
    this._i18nResources = {
      english:{
        translation: {
          add:'Add',
          refresh:'Refresh',
          cancel:'Cancel',
          delete:'Delete permanently',
          empty:'No item available',
          product:'product',
          modify:'Modify',
          del:'Delete',
          del_confirm:'Are you sure you want to delete'
        },
      },
      french:{
        translation: {
          add:'Ajouter',
          refresh:'Rafraîchir',
          cancel:'Annuler',
          delete:'Supprimer définitivement',
          empty:'Aucun élement disponible',
          product:'produit',
          modify:'Modifier',
          del:'Supprimer',
          del_confirm:'Êtes-vous sûr de vouloir supprimer'
        }
      }
    };
  }

  _i18nMergeResources(resources) {
    return merge(this._i18nResources, resources);
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadItems = this.loadItems.bind(this);
    window.addEventListener(this.eventUpdatedName, this.loadItems);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(this.eventUpdatedName, this.loadItems);
  }

  _sortItems(items) {
    // override me
    return items;
  }

  async loadItems() {
    this.loading = true;
    if (!this.apiEndpoint) {
      throw new Error(`${this.constructor.name}: you MUST set property apiEndpoint  (ex: api/items)`);
    }
    const response = await Fetcher.get(this.apiEndpoint);
    const items = response?.data;
    console.log(items);
    this.items = this._sortItems(items);
    this.loading = false;
  }

  async updated(changedProperties) {
    if (changedProperties.has('loading')) {
      const table = this.shadowRoot.querySelector('table');
      if (table) {
        table.classList.toggle('disabled', this.loading);
      }
    }
  }

  async visibleCallback() {
    super.visibleCallback();

    this.modalItemEdit = this.shadowRoot.querySelector('#modal-edit');
    this.modalItemDelete = this.shadowRoot.querySelector('#modal-delete');


    if (ENV === 'dev') {
      if (!this.modalItemEdit) {
        console.warn(`${this.constructor.name}: could not find #modal-edit in the shadowRoot`);
      }
      if (!this.modalItemDelete) {
        console.warn(`${this.constructor.name}: could not find #modal-delete in the shadowRoot`);
      }
    }

    if (this.items === null) {
      await this.loadItems();
    }
    this._checkUrlForItemId();
  }

  _checkUrlForItemId() {
    const urlParams = new URLSearchParams(window.location.search);
    const itemId = urlParams.get(this.urlIdParam);
    if (itemId) {
      const item = this.items.find(p => p._id === itemId);
      if (item) {
        this._editItem(null, item);
      }
    }
  }

  _renderLoader() {
    return html`<div class="loader_container"><sl-progress-bar style="--height: 6px;" indeterminate></sl-progress-bar></div>`;
  }

  _renderNoItem() {
    if (this.loading || this.items?.length) return;
    return html`<div class="center">
      <br/><br/>
      ${this._i18n('empty')}
      <br/><br/>
      <sl-button variant="primary" @click="${this._addItem}">${this._i18n('add')}</sl-button>
    </div>`;
  }

  _addItem() {
    this.modalItemEdit.item = { ...this.itemModel };
  }

  _editItem(ev, item) {
    this._log.info('_editItem', item, ev);
    if (ev?.target?.tagName === 'M-ICON') return;
    const editedItem = merge({ ...item }, this.itemModel);
    if (!this.modalItemEdit) {
      if (ENV === 'dev') {
        console.warn(`${this.constructor.name}: you MUST implement method _renderEditComponent`);
      }
      return;
    }
    this.modalItemEdit.item = editedItem;
  }

  async _deleteItem() {
    this.modalItemDelete.loadingButtons();
    const url = `${this.apiEndpoint}/${this.item._id || this.item[this.itemTitleFieldName]}`;
    const response = await Fetcher.delete(url);
    this.modalItemDelete.resetButtons();
    this.modalItemDelete.hide();
    delete this.item;
    await this.loadItems();
  }

  _deleteItemConfirm(ev, item) {
    ev.stopPropagation();
    this.modalItemDelete.querySelector('.itemTitle').textContent = this._getItemTitle(item);
    this.modalItemDelete.show();
    this.item = item;
  }

  _getItemTitle(item) {
    throw new Error(`${this.constructor.name}: you MUST implement method _getItemTitle`);
  }

  _getTableHeader() {
    // you can override me
    return '';
  }

  _renderItems() {
    throw new Error(`${this.constructor.name}: you MUST implement method _renderItems`);
  }

  _renderHeader() {
    return html`
      <section-header size="small">
        ${this.loading 
          ? this._renderLoader()
          : html`
            ${this._getTableHeader()}
            <m-icon slot="right" size="big" name="add" @click=${this._addItem}></m-icon>`
        }
      </section-header>`
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          ${this._i18n('del_confirm')} &laquo;<span class="itemTitle"></span>&raquo;?
        </div>
        <sl-button slot="bt1" variant="text" close="true">${this._i18n('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._i18n('delete')}</sl-button>
      </modal-dialog>
    `;
  }

  render() {    
    return html`
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
    `;
  }

}

export default TableBasic;