import { LitElement, html, css } from 'lit-element';

class BasketIcon extends LitElement {
  static get properties() {
    return {
      iconName: { type: String },
      emailFirstLetter: { type: String },
    };
  }
  
  static get styles() {
    return css`
       :host {
        display:inline-block;
        transition:color 0.3s;
        cursor:pointer;
      }

      sl-button {
        margin-right:10px;
      }

      sl-button::part(label) {
        padding-left:5px;
        padding-right:10px;
        display:flex;
      }

      m-icon {
        font-size:22px;
        float:left;
        padding-right:3px;
        padding-top:2px;
      }

      /* Smartphones (portrait) */
      @media only screen and (max-width: 480px) {
        sl-button .text {
          display:none;
        }

        sl-button::part(label) {
          padding-right:2px;
          background-color:transparent;
        }

        sl-button::part(base) {
          background-color:transparent;
          color:white;
          border:0px;
        }

        m-icon {
          font-size:27px;
          margin-top:-1px;
        }
      }

      /* Smartphones (landscape) */
      @media only screen and (min-width: 481px) and (max-width: 767px) {
        sl-button .text {
          display:none;
        }
        sl-button::part(label) {
          padding-right:2px;
        }
        sl-button::part(base) {
          background-color:transparent;
          color:white;
          border:0px;
        }
        m-icon {
          font-size:27px;
          margin-top:-1px;
        }
      }

      /* Tablettes (portrait) */
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
      }

      /* Tablettes (landscape) */
      @media only screen and (min-width: 1025px) and (max-width: 1280px) {
      }
    `;
  }

  constructor() {
    super();
    this.iconName = '';
    this.emailFirstLetter = '';
  }

  async connectedCallback() {
    super.connectedCallback();
    /*
    const session = await sessionService.getUserSession();
    this.iconName = session.authenticated ? 'authenticated-icon' : 'login';
    if (session.authenticated && session.email) {
      this.emailFirstLetter = session.email.charAt(0).toUpperCase();
    }
    */
  }

  render() {
    return html`
      <sl-button size="small" pill>
        <m-icon name="shopping_bag"></m-icon>
        <span class="text">Mon panier</span>
      </sl-button>
    `;
  }
}

customElements.define('basket-icon', BasketIcon);