import { PageElement, html, css, unsafeHTML } from 'UX';
import { Fetcher, Session } from 'Utils';
import App from 'App';

const IMGPROXY_SIZE_MOBILE_NEW = 338;
const IMGPROXY_SIZE_PC_NEW = 298;

const IMGPROXY_SIZE_MOBILE = 169;
const IMGPROXY_SIZE_PC = 169;

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --shadow-offset-negative: -1px;
          --shadow-offset-positive: 1px;
        }

        .spinner-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-height:200px;
          height:200px;
        }

        .no_product {
          display: flex;
          justify-content: center;
          align-items: baseline;
          width: 100%;
          background:url(assets/img/visuals/trounoir.jpg) no-repeat;
          background-size: cover;
        }

        .no_product .text  {
          background: linear-gradient(to right, transparent 00%, #111111bb 20%, #111111bb 80%, transparent 100%);
          padding:10px;
          margin-top:60px;
          margin-bottom:60px;
          color:white;
          font-family:Calibri;
          text-transform:uppercase;
          text-shadow: 1px 1px 1px #000000;
          font-size:2em;
          text-align:center;
          display:flex;
          justify-content:center;
          align-items:top;
          flex-direction:column;
        }

        .products {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
          margin:10px;
          gap:20px;
          margin-right: 10px;
        }

        .products_news {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
          margin:10px;
          gap:20px;
          margin-right: 10px;
        }

        .product_container {
          border:1px solid var(--sl-color-neutral-300);
          align-items:center;
          text-align:center;
          position:relative;
          overflow:hidden;
        }

        .product {
          text-align: center;
          position:relative;
          margin:10px;
          box-shadow:  var(--neuromorph-box-shadow);
        }

        .product.disabled .product_image::before {
          content: '\\e8f5';
          font-family: "Material Symbols Sharp";
          font-weight: normal;
          font-style: normal;
          font-size: 20px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          overflow-wrap: normal;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizelegibility;
          font-feature-settings: "liga";
          user-select: none;
          color:red;
          position:absolute;
          right:2px;
          bottom:2px;
          background-color:#FFFFFF55;
          padding:5px;;
          border-radius:50%;
        }

        .products .product_title,
        .products_news .product_title {
          font-weight:600;
          width:100%;
          margin-top:10px;
          height:32px;
          text-transform:uppercase;
          position:relative;
          text-align:center;
          align-items:center;
        }

        .products_news .product_title .text {
          display:block;
          flex:1;
          font-size:1.2em;
          width:100%;
        }

        .product .product_title .text {
          display:block;
          flex:1;
          font-size:0.8em;
          width:100%;
        }

        .product_image {
          display:flex;
          justify-content:center;
          align-items:center;
          min-height:calc(200px * 1.2);
          padding:2px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position:relative;
          overflow:hidden; 
          position:relative;
          width:100%;
        }

        /*
        .product_image::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 0px;
          box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.7);
          z-index: 1;
        }
        */

        .product_image img {
          width:100%;
        }

        .promo {
          position:absolute;
          top:0px;
          right:0px;
          left:0px;
          background-color:#000000ee;
          display:flex;
          justify-content:center;
          align-items:center;
          opacity:0.9;
          font-size:1em;
          /*color:#ff00ff;*/
          color:#ffff00;
          font-weight:bold;
          height:0px;
          overflow:hidden;
          transition:height 0.3s;
        }

        .product_container:hover .promo {
          height:50px;
        }

        m-icon[name="hotel_class"] {
          position:absolute;
          font-size: 25px;
          z-index:2;
          top:10px;
          right:-10px;
          transform-origin: center;
          color:#ff00ff;
          animation: spin 10s linear infinite, colorChange 5s linear infinite;
        }

        @keyframes spin {
          from {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }

        @keyframes colorChange {
          0% {
            color: blue;
          }
          40% {
            color: fuchsia;
          }
          60% {
            color: fuchsia;
          }
          100% {
            color: blue;
          }
        }
        
        .price_promo {
          font-size:1.5em;
        }

        box-styled.disabled {
          opacity:0.5;
        }

        .hidden {
          opacity:0.2;
          zoom:0.9;
        }

        section-header:hover .hidden {
          opacity:1;
        }
        `
    ];
  }

  static get properties() {
    return {
      products: { type: Array }
    }
  }

  constructor() {
    super();

    this._i18nResources = {
      english:{
        translation: {
          title:'Our creations',
          no_product:'No creation is available at the moment.<br/>The creation is on pause.',
          promotion_text:'Promotion !',
          new_product:'New creations'
        }
      },
      french:{
        translation: {
          title:'Nos créations',
          no_product:'Aucune création n\'est disponible pour le moment.<br/>La création est en pause.',
          promotion_text:'Promotion !',
          new_product:'Nouveautés'
        }
      }
    }

    this.products = null;
    this.handleLanguageChanged = this.handleLanguageChanged.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.loading = true;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.handleLanguageChanged);
    window.addEventListener('resize', this.handleResize);
  } 

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.handleLanguageChanged);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.requestUpdate();
    }, 50);
  }

  handleLanguageChanged(e) {
    this.requestUpdate();
  } 

  async firstUpdated() {
    super.firstUpdated();
    await this.loadProducts();
  } 

  async loadProducts(){
    const response = await Fetcher.post('public/shop/products/list')
    this.loading = false;
    if (!response) return;

    this.products = response.data; 
  } 

  getProductTitle(product) {
    const key = `title_${App.config.lg}`;
    return product[key] || product.title_fr; 
  }

  getProductStyle(product) {
    let pcss = '';
    if (!product.enable) pcss=' disabled';
    if (pcss.length) return `class="${pcss}"`;
    return '';
  }

  getDiscountPercentage(product) {
    const originalPrice = parseFloat(product.price);
    const discountedPrice = parseFloat(product.price_promo);
    return (((originalPrice - discountedPrice) / originalPrice) * 100).toFixed(0);
  }

  getProductPromo(product) {
    if (!product.price_promo) return '';
    const discount = this.getDiscountPercentage(product);
    return `<div class="promo"><div>Offre ArtGoOne<br/><span class="price_promo">-${discount}%</span></div></div>`;
  }

  getProductPromoIcon(product) {
    if (!product.price_promo) return '';
    return `<m-icon name="hotel_class" title="${this._i18n('promotion_text')}"></m-icon>`;
  }

  getPrivateIcons() {
    if (!Session.isBoss()) return;
    return html`
      <div slot="right" class="hidden">
        <a-route href="/private/admin/products">
          <m-icon name="edit" title="Modifier les créations" nogradient></m-icon>
        </a-route>
      </div>
    `;
  }

  renderLoader() {
    if (this.products) return;
    return html`
      <div class="spinner-container">
        <sl-spinner style="font-size: 5rem;"></sl-spinner>
      </div>
    `;
  }

  getImagesSizeNew() {
    if (window.innerWidth < 670) return IMGPROXY_SIZE_MOBILE_NEW;
    return IMGPROXY_SIZE_PC_NEW;
  }

  getImagesSize() {
    if (window.innerWidth < 670) return IMGPROXY_SIZE_MOBILE;
    return IMGPROXY_SIZE_PC;
  }

  renderProducts(){
    if (!this.products) return;

    // filter products having only display_as_new flag
    const products = this.products.filter(product => !product.display_as_new);
    if (!products.length) return;

    return html`
      <section-header micon="storefront">
        ${this._i18n('title')}
        ${this.getPrivateIcons()}
      </section-header>
      <br/>
      <div class="products">
        ${products.map(product => unsafeHTML(`
          <a-route href="/public/shop/product/${product._id}" ${product.enable ? '' : 'title="Uniquement visible par les administrateurs"'}>
            <div class="product_container" ${this.getProductStyle(product)}>
              ${this.getProductPromoIcon(product)}
              <img-proxy
                width="${this.getImagesSize()}"
                height="${this.getImagesSize()}"
                src="${product.images[0]?.src}"
                enlarge=1
                extend=0
                resize="fill"
              ></img-proxy>
              <div class="product_title">
                <div class="text">${this.getProductTitle(product)}</div>
              </div>
              ${this.getProductPromo(product)}
              <br/>
            </div>
          </a-route>
        `))}
      </div>
    `;
  }

  renderProductsNews(){
    if (!this.products) return;

    // filter products having only display_as_new flag
    const products = this.products.filter(product => product.display_as_new);
    if (!products.length) return;

    return html`
      <section-header micon="storefront">
          ${this._i18n('new_product')}
          ${this.getPrivateIcons()}
      </section-header>
      <br/>
      <div class="products_news">
        ${products.map(product => unsafeHTML(`
          <a-route href="/public/shop/product/${product._id}" ${product.enable ? '' : 'title="Uniquement visible par les administrateurs"'}>
            <div class="product_container" ${this.getProductStyle(product)}>
              ${this.getProductPromoIcon(product)}
              <img-proxy
                width="${this.getImagesSizeNew()}"
                height="${this.getImagesSizeNew()}"
                src="${product.images[0]?.src}"
                enlarge=1
                extend=0
                resize="fill"
              ></img-proxy>
              <div class="product_title">
                <div class="text">${this.getProductTitle(product)}</div>
              </div>
              ${this.getProductPromo(product)}
              <br/>
            </div>
          </a-route>
        `))}
      </div>
      <br/><br/>
    `;
  }

  renderNoProduct() {
    if (this.loading) return;
    if (this.products && this.products.length) return;

    return html`
      <div class="no_product">
        <span class="text">${this._i18n('no_product')}</span>
      </div>
    `;
  }

  render() {
    return html`
      ${this.renderLoader()}
      ${this.renderNoProduct()}
      ${this.renderProductsNews()}
      ${this.renderProducts()}
    `
  }
}

customElements.define('page-shop', Page);