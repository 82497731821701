export default {
  cti:{
    title_long:{
      english:'Our creations',
      french:'Nos créations'
    },
    title_short:{
      english:'Achievements',
      french:'Réalisations'
    },
    url:'/public/shop',
    //icon:'soc.png',
    micon:'storefront',
    /*
    submenu:{
    }
    */
  },
  myspace:{
    grants:['authenticated'],
    float:'right',
    title_long:{
      english:'My space',
      french:'Mon espace'
    },
    title_short:{
      english:'My space',
      french:'Mon espace'
    },
    micon:'deployed_code_account',
    url:'/private',
  }
}
