import { BaseElement, html, css } from 'UX';
import App from 'App';

/*
const LANGUAGES = {
  'albanian':'🇦🇱',
  'arabic':'🇸🇦',
  'azeri':'🇦🇿',
  'bengali':'🇧🇩',
  'bulgarian':'🇧🇬',
  'cebuano':'🇵🇭',
  'croatian':'🇭🇷',
  'czech':'🇨🇿',
  'danish':'🇸🇪',
  'dutch':'🇳🇱',
  'english':'🇺🇸',
  'estonian':'🇪🇪',
  'farsi':'🇮🇷',
  'finnish':'🇫🇮',
  'french':'🇫🇷',
  'german':'🇩🇪',
  'hausa':'🇨🇫',
  'hawaiian':'🏴󠁵󠁳󠁨󠁩󠁿',
  'hindi':'🇮🇳',
  'hungarian':'🇭🇺',
  'icelandic':'🇮🇸',
  'indonesian':'🇮🇩',
  'italian':'🇮🇹 ',
  'kazakh':'🇰🇿',
  'kyrgyz':'🇰🇬',
  'latin':'🇪🇺',
  'latvian':'🇱🇻',
  'lithuanian':'🇱🇹',
  'macedonian':'🇲🇰',
  'mongolian':'🇲🇳',
  'nepali':'🇳🇵',
  'norwegian':'🇳🇴',
  'pashto':'🇵🇰',
  'pidgin':'🇳🇪',
  'polish':'🇵🇱',
  'portuguese':'🇵🇹',
  'romanian':'🇷🇴',
  'russian':'🇷🇺',
  'serbian':'🇷🇸',
  'slovak':'🇸🇰',
  'slovene':'🇸🇮',
  'somali':'🇸🇴',
  'spanish':'🇪🇸',
  'swahili':'🇰🇪',
  'swedish':'🇸🇪',
  'tagalog':'🇵🇭',
  'turkish':'🇹🇷',
  'ukrainian':'🇺🇦',
  'urdu':'🇵🇰',
  'uzbek':'🇺🇿',
  'vietnamese':'🇻🇳',
  'welsh':'🏴󠁧󠁢󠁷󠁬󠁳󠁿'
}
*/
const LANGUAGES_AVAILABLE = {
  'english':{
    flag:'🇺🇸',
    short:'EN',
    french:'Anglais',
    english:'English',
  },
  'french':{
    flag:'🇫🇷',
    short:'FR',
    french:'Français',
    english:'French',
  }
}

class LanguagesSelector extends BaseElement {
  static get properties() {
    return {
      language: { type: String }
    }
  }

  static get styles() {
    return css`
      :host {
        display: block;
        color:gray;
        margin-right:10px;
      }

      sl-menu {
        border:0px;
      }

      sl-button::part(base) {
        font-size:25px;
        color:var(--sl-color-gray-200);
        width: 50px;
      }

      sl-menu-item::part(base) {
        line-height:25px;
      }

      sl-menu-item::part(label) {
        font-size:0.85em;
      }

      .flag {
        font-size:15px;
      }

      m-icon {
        font-size: 33px;
      }

      @media print {
        :host {
          display:none;
        }
      }
    `
  }

  constructor() {
    super();
    this.language = localStorage.getItem(App.config.localKeys.language) || 'french';
  }

  visibleCallback() {
    super.visibleCallback();
    this.sendEvent();
  }

  sendEvent() {
    const customEvent = new CustomEvent('language-changed', {
      bubbles: true,
      composed: true,
      detail: { language: this.language },
    });

    this.dispatchEvent(customEvent);
  }

  updateLanguage(language, ev) {
    if (ev) ev.stopPropagation();
    this.language = language;
    localStorage.setItem(App.config.localKeys.language, language);
    App.config.language = this.language;
    this.parentNode.parentNode.hide();
    this.sendEvent();
  }

  render() {
    return html`
        <sl-menu size="small">
          ${Object.entries(LANGUAGES_AVAILABLE).map(([code, obj]) => 
            html`<sl-menu-item size="small" suffix="bla" class="menu_item" type="checkbox" ?checked="${this.language === code}" @click="${(e) => this.updateLanguage(code, e)}">
              ${obj[this.language]}
              <span class="flag" slot="suffix" name="heart">${obj.flag}</span>
            </sl-menu-item>`
          )}
        </sl-menu>
    `;
  }
}

customElements.define('languages-selector', LanguagesSelector);

