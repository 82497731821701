import { PageElement, html, css, unsafeHTML } from 'UX';
import { Session } from 'Utils';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import './form_personal_infos.js';
import './security_auth.js';
import './security_activity.js';
import './session_infos.js';
import './notifications.js';

class UserAccountPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display:block;
          height:100%;
        }

        sl-tree {
          --indent-guide-width: 1px;
          min-width:230px;
          padding:0px;
          margin:0px;
        }

        sl-tree-item::part(label) {
          font-size:14px;
        }

        .mycontainer {
          display:flex;
          height:100%;
        }

        .tabcontent {
          width:100%;
          height:100%;
          padding-left:20px;
          border-left:1px solid var(--sl-color-neutral-300);
        }

        sl-dropdown {
          margin-top:3px;
          cursor:pointer;
          text-transform:initial;
        }

        sl-menu-item::part(label) {
          font-size:13px;
        }

        sl-menu-item::part(checked-icon) {
          width:5px;
        }

        .hidden {
          display:none;
        }

        m-icon[name="lock"] {
          font-size: 1.2em;
          position:absolute;
          right:5px;
        }

        @media (max-width: 670px) {
          sl-tree {
            display:none;
          }
        }

        @media (min-width: 670px) {
          sl-dropdown {
            display:none;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.handlePopState = this.handlePopState.bind(this);
    this.showInstallDialog = this.showInstallDialog.bind(this);
    this._i18nResources = {
      english:{
        translation: {
          title: 'My account',
          menu_personalinfos: 'Personal informations',
          menu_security: 'Account security',
          menu_security_auth: 'Authentication',
          menu_security_activity: 'Activity',
          menu_notifications: 'Notifications',
          menu_session: 'Current session'

        }
      },
      french:{
        translation: {
          title: 'Mon compte',
          menu_personalinfos: 'Informations personnelles',
          menu_security: 'Sécurité du compte',
          menu_security_auth: 'Authentification',
          menu_security_activity: 'Activité',
          menu_notifications: 'Notifications',
          menu_session: 'Session en cours<m-icon name="lock" title="Visible uniquement par les administrateurs"></m-icon>'
        }
      }
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('show-install-dialog', this.showInstallDialog);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('show-install-dialog', this.showInstallDialog);
  }

  showInstallDialog() {
    this.shadowRoot.querySelector('modal-dialog').open = true;
  }

  _onLanguageChanged(ev) {
    super._onLanguageChanged(ev);
  }

  handlePopState = () => {
    const hash = window.location.hash.replace(/^#/, '');
    //console.log('handlePopState', hash);
    this.updateContent(hash);
  }

  async firstUpdated() {
    super.firstUpdated();
    this.initTree();
  }

  initTree() {
    let hash = window.location.hash ? window.location.hash.toString().replace(/^#/,'') : 'personalinfos';
    const qs = `sl-tree-item[value="${hash}"]`;
    const treeItem = this.shadowRoot.querySelector(qs);
    if (treeItem) {
      treeItem.selected = true;
      if (treeItem.parentNode.tagName === 'SL-TREE-ITEM') {
        treeItem.parentNode.expanded = true;
      }
    }
    //console.log('initTree', hash);
    this.updateContent(hash);
  }

  toggleForm(form, readonly) {
    const elements = getFormControls(form);
    for (let i = 0, len = elements.length; i < len; ++i) {
      //elements[i].readonly = readonly;
      elements[i].disabled = readonly;
    }
  }

  async handleSubmitInfos(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    const form = this.shadowRoot.querySelector('#personalinfos');
    this.toggleForm(form, true);

    const bt = this.shadowRoot.querySelector('#bt_submit_info');
    bt.disabled = true;
    bt.loading = true;

    setTimeout(() => {
      this.shadowRoot.querySelector('#save_info_success').open = true;
      bt.loading = false;
      bt.disabled = false;
      this.toggleForm(form, false);
    }, 1000);
  }

  handleTreeItemClick(ev) {
    const firstChild = ev.target.querySelector('sl-tree-item');
    if (firstChild) {
      ev.target.expanded = true;
      ev.target.selected = false;
      firstChild.selected = true;
      this.updateContent(firstChild.getAttribute('value'));  
    } else {
      this.updateContent(ev.target.getAttribute('value'));
    }    
  }

  updateContent(hash) {
    if (!hash) return;
    hash = hash.replace(/^#/, '');

    if (hash === this.previousHash) {
      return;
    }

    this.previousHash = hash;
    window.location.hash = hash;
    if (this.currentTabContent) {
      this.currentTabContent.hide();
    }

    const newTab = this.shadowRoot.querySelector(`#${hash}`);
    if (newTab) {
      //console.log('updateContent', hash);
      newTab.show();
      this.currentTabContent = newTab;
    }
  }

  goInstall() {
    document.location.href = '/private/doc/faq#installpwa?'+Math.random();
  }

  render() {

    let menu = [
      { value: 'personalinfos'},
      { value: 'security', sub: [
        { value: 'security_auth' },
        { value: 'security_activity' },
      ]},
      { value: 'notifications' },
    ]

    if (Session.isBoss()) {
      menu[1].sub.push({ value: 'session' });
    }


    const menuCopy = JSON.parse(JSON.stringify(menu));

    return html`
      <section-header backroute="/private" micon="account_circle">${this._i18n('title')}
        
        <sl-dropdown slot="right">
          <m-icon slot="trigger" name="more_vert"></m-icon>
          <sl-menu @click="${this.handleTreeItemClick}">
          ${menu.map(item => html`
            <sl-menu-item value="${item.value}">
              ${unsafeHTML(this._i18n(`menu_${item.value}`))}
              ${item.sub ? html`
                <sl-menu slot="submenu">
                  ${item.sub.map(subItem => {
                    return unsafeHTML(`
                      <sl-menu-item value="${subItem.value}">${this._i18n(`menu_${subItem.value}`)}</sl-menu-item>
                    `)})}
                </sl-menu>
              ` : ''}
            </sl-menu-item>
          `)}
          </sl-menu>
        </sl-dropdown>
      </section-header>

      <br/>
      <div class="mycontainer">
        
        <sl-tree @click="${this.handleTreeItemClick}">
          ${menuCopy.map(item => html`
          <sl-tree-item expanded value="${item.value}">${unsafeHTML(this._i18n(`menu_${item.value}`))}
          ${item.sub ? item.sub.map(subItem => unsafeHTML(`
            <sl-tree-item value="${subItem.value}">${this._i18n(`menu_${subItem.value}`)}</sl-tree-item>
          `)) : ''}
          </sl-tree-item>
        `)}
        </sl-tree>

        <div class="tabcontent">
          <form-user-account-personal-infos id="personalinfos"></form-user-account-personal-infos>
          <form-user-account-security-auth id="security_auth"></form-user-account-security-auth>
          <form-user-account-security-activity id="security_activity"></form-user-account-security-activity>
          <form-user-account-notifications id="notifications"></form-user-account-notifications>
          <form-user-account-session-infos id="session"></form-user-account-session-infos>
        </div>

      </div>

      <modal-dialog label="Prérequis notifications">
        <br/>
        <p>Pour bénéficier des notifications, vous devez installer le site sur votre terminal.</p>
        <br/>
        <sl-button slot="bt1" variant="primary" @click="${this.goInstall}">En savoir plus</sl-button>
      </modal-dialog>
    `
  }
}

customElements.define('page-user-account', UserAccountPage);