import { PageElement, html, css } from 'UX';
import { Router } from 'Router';

class SectionHeaderTestPage extends PageElement {
  
  render() {
    const text1 = 'left icon';
    const text2 = 'without icon';
    const text3 = 'both LR icon';
    const text4 = 'left icon right button';
    const button = html`<sl-button slot="right" size="small">Button</sl-button>`;
    const ricon = html`<m-icon slot="right" name="edit"></m-icon>`;

    return html`
      <section-header micon="error">big: ${text1}</section-header><br/>
      <section-header micon="error" size="medium">medium: ${text1}</section-header><br/>
      <section-header micon="error" size="normal">normal: ${text1}</section-header><br/>
      <section-header micon="error" size="small">small: ${text1}</section-header><br/>
      <section-header micon="error" size="vsmall">vsmall: ${text1}</section-header><br/>
      <br/>
      <section-header>big: ${text2}</section-header><br/>
      <section-header size="medium">medium: ${text2}</section-header><br/>
      <section-header size="normal">normal: ${text2}</section-header><br/>
      <section-header size="small">small: ${text2}</section-header><br/>
      <section-header size="vsmall">vsmall: ${text2}</section-header><br/>
      <br/>
      <section-header micon="error">big: ${text3}${ricon}</section-header><br/>
      <section-header micon="error" size="medium">medium: ${text3}${ricon}</section-header><br/>
      <section-header micon="error" size="normal">medium: ${text3}${ricon}</section-header><br/>
      <section-header micon="error" size="small">small: ${text3}${ricon}</section-header><br/>
      <section-header micon="error" size="vsmall">vsmall: ${text3}${ricon}</section-header><br/>
      <br/>
      <section-header micon="error">big: ${text4}${button}</section-header><br/>
      <section-header micon="error" size="medium">medium: ${text4}${button}</section-header><br/>
      <section-header micon="error" size="normal">normal: ${text4}${button}</section-header><br/>
      <section-header micon="error" size="small">small: ${text4}${button}</section-header><br/>
      <section-header micon="error" size="vsmall">vsmall: ${text4}${button}</section-header><br/>
      <br/>
    `;
  }
}

customElements.define('page-test-section-header', SectionHeaderTestPage);