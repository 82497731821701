import { BaseElement, html, css } from 'UX';

class ModalDialog extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          position:fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1901;
          opacity: 0;
          display:flex;
          justify-content:center;
          align-items:center;
          transition: opacity 0.3s, backdrop-filter 2s, background-color 0.3s;
          backdrop-filter: blur(0px) grayscale(0%);
          visibility: hidden;
          font-family: Calibri, sans-serif;
        }

        :host(.visible) {
          visibility: visible;
          opacity:1;
          backdrop-filter: blur(0px)  grayscale(100%);
          background-color:#222222aa;
        }

        .container {
        }

        .container .popup {
          visibility:hidden;
          min-width:30vw;
          z-index:2001;
          background-color:var(--page-background-color);
          box-shadow:0 4px 8px rgba(0,0,0,0.5);
          border-radius:5px;
          min-height:10vh;
          transition:opacity 0.3s;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
            transform: translateY(0);
          }
          to {
            opacity: 0;
            transform: translateY(-10px);
          }
        }

        .fade-in {
          animation: fadeIn 0.3s ease-out;
        }

        .fade-out {
          animation: fadeOut 0.3s ease-out;
        }

        :host(.visible) .container .popup {
          visibility:visible;
        }

        .header {
          display:flex;
          justify-content:space-between;
          align-items:center;
          font-family:Calibri;
          text-transform:uppercase;
          font-weight:bold;
          padding:15px;
          gap:20px;
          font-size:20px;
        }

        .header span {
          white-space:nowrap;
        }

        m-icon {
          cursor:pointer;
        }

        m-icon[name="close"] {
          font-size:30px;
        }

        .xcontent {
          padding:30px;
          font-size:17px;
          min-height:20px;
        }

        .buttons {
          padding:20px;
          padding-top:0px;
          display:flex;
          justify-content:space-between;
        }

        @media (max-width: 670px) {
          .container .popup {
            width: 95vw;
            min-height:200px;
          }

          m-icon {
            font-size:50px;
          }
        }
        `
    ];
  }

  static get properties() {
    return {
      open: { type: Boolean, reflect: true },
      noHeader: { type: Boolean, reflect: true },
      modal: { type: Boolean, reflect: false },
      label: { type: String },
    }
  }

  constructor() {
    super();
    this.open = false;
    this.modal = false;
    this.label = '';
    this.noHeader = false;
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  firstUpdated() {
    super.firstUpdated();
    this.initButtons();
    //document.body.after(this);
  }

  initButtons() {
    this.buttons = this.querySelectorAll('sl-button');
    for (const button of this.buttons) {
      const attrClose = button.getAttribute('close');
      if (attrClose === 'true') {
        //console.log('button', button.innerHTML, 'will hide');
        button.addEventListener('click', this.hide.bind(this));
      } else if (attrClose === 'false') {
        //console.log('button', button.innerHTML, 'will not close');
      } else {
        const originalClickHandler = button.onclick;
        if (typeof originalClickHandler === 'function') {
          //console.log('button', button.innerHTML, 'has a click handler');
          button.addEventListener('click', async (event) => {
            await originalClickHandler.call(button, event);
            await this.hide();
          });
        }
      }
    }
  }

  loadingButtons() {
    for (const button of this.buttons) {
      button.disabled = true;
      if (button.getAttribute('variant') !== 'text') {
        button.loading = true;
      }
    }
  }

  resetButtons() {
    for (const button of this.buttons) {
      button.disabled = false;
      button.loading = false;
    }
  }

  connectedCallback() {
    super.connectedCallback();   
  }

  validateFixedPosition(element) {
    if (!element) return;
    let currentElement = element instanceof ShadowRoot ? element.host : element;
    const visitedElements = new Set();

    while (currentElement) {
      if (!currentElement) return;
      if (visitedElements.has(currentElement)) {
        break; // Éviter les boucles infinies
      }
      visitedElements.add(currentElement);

      //console.log(currentElement.tagName, currentElement.className, currentElement);
      const computedStyle = window.getComputedStyle(currentElement);

      if (computedStyle.position === 'fixed' || computedStyle.position === 'absolute') {
        return currentElement;
      }

      if (computedStyle.position !== 'static' || computedStyle.transform !== 'none') {
        return currentElement;
      }

      if (currentElement.parentElement) {
        currentElement = currentElement.parentElement;
      } else if (currentElement.getRootNode().host) {
        currentElement = currentElement.getRootNode().host;
      } else {
        break;
      }
    }

    return;
  }

  adjustPosition() {
    /*
    const rect = this.getBoundingClientRect();
    const parentRect = this.parentElement.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    this.style.top = `${rect.top + scrollTop - parentRect.top}px`;
    this.style.left = `${rect.left + scrollLeft - parentRect.left}px`;
    //console.log(this.style.top, this.style.left);
    */
  }

  handleMouseWheel(ev) {
    const path = ev.composedPath();
    if (path.includes(this)) return;
    ev.preventDefault();
  }

  handleKeydown(ev) {
    if (ev.key === 'Escape') {
      this.hide();
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('open')) {  
      if (this.open) {
        this.classList.add('visible');
        this.installEvents();
        this.adjustPosition();
      } else {
        this.classList.remove('visible');
        this.removeEvents();
      }
    }
  }

  installEvents() {
    window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false });
    window.addEventListener('keydown', this.handleKeydown);
  }

  removeEvents() {
    window.removeEventListener('mousewheel', this.handleMouseWheel);
    window.removeEventListener('keydown', this.handleKeydown);
  }

  show() {
    //this.initButtons();
    this.resetButtons();
    this.open = true;
    this.shadowRoot.querySelector('.container').classList.add('open');

    const popup = this.shadowRoot.querySelector('.popup');
    popup.classList.add('fade-in');
    popup.addEventListener('animationend', () => {
      popup.classList.remove('fade-in');
    }, { once: true });

    this.installEvents();

    //window.dispatchEvent(new CustomEvent('overlay-show'));

    /*
    const fuckedEl = this.validateFixedPosition(this.parentNode);
    if (fuckedEl) {
      //console.warn('Un des éléments parents a une position autre que "static", ce qui peut interférer avec "position: fixed".', fuckedEl);
    } else {
      //console.log('Tous les éléments parents ont une position "static".');
    }

    this.adjustPosition();
    */
  
  }

  async hide() {
    const popup = this.shadowRoot.querySelector('.popup');
    popup.classList.add('fade-out');
    popup.addEventListener('animationend', () => {
      popup.classList.remove('fade-out');
      this.open = false;
      this.shadowRoot.querySelector('.container').classList.remove('open');
      this.removeEvents();
      this.dispatchEvent(new CustomEvent('after-hide'));
    }, { once: true });
  }

  render() {
    return html`
      <div class="container">
        <div class="popup" ?open="${this.open}">
          ${this.label
            ? html`
                <div class="header">
                  <slot name="micon"></slot>
                  <span>${this.label}</span>
                  ${!this.modal ? html`<m-icon name="close" @click="${this.hide}"></m-icon>` : ''}
                </div>
                <hr-custom></hr-custom>
              `
            : ''
          }
          <div class="xcontent">
            <slot></slot>
          </div>
          <div class="buttons">
            <slot name="bt1"></slot>
            <slot name="bt2"></slot>
            <slot name="bt3"></slot>
          </div>
        </div>
      </div>
    `;
  }

}

customElements.define('modal-dialog', ModalDialog);