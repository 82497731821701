import { html, css } from 'UX';
import { Fetcher, Session } from 'Utils';
import emojiFlags from 'emoji-flags';
import TableBasic from 'Components/TableBasic.js'

class Page extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        .list {
          width:100%;
          margin-left:auto;
          margin-right:auto;
          display: grid;
          grid-template-columns: 30px 100px 150px 1fr 60px 60px 40px 30px;
          gap:5px;
          font-size: 0.8em;
        }

        .list.header {
          font-weight:bold;
          border-bottom:1px solid var(--sl-color-neutral-300);
          border-top:1px solid var(--sl-color-neutral-300);
          margin-bottom:5px;
        }

        .list.header div {
          padding:3px;
          box-shadow: -1px 0 0 0 var(--sl-color-neutral-300);
        }

        .list.header div:last-child {
          border-right:1px solid var(--sl-color-neutral-300);
        }

        .list.header div:first-child {
          border-left:1px solid var(--sl-color-neutral-300);
        }

        .list div {
          padding-left:3px;
          padding-right:3px;
          display:flex;
          align-items:center;
          cursor:pointer;
        }

        .list div.right {
          text-align:right;
        }

        .list div.middle {
          justify-content: space-around;
          text-align:center;
        }

        .list div.visibility m-icon {
          font-size:20px;
        }

        .list.product:hover{
          background-color: var(--sl-color-neutral-100);
        }

        .list .center {
          text-align:center;
        }
      `
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/website/sessions';   
    this._i18nResources = {
      english:{
        translation: {
          title:'Current sessions',
          refresh:'Refresh',
          cancel:'Cancel',
          add:'Add a product',
          edit:'Edit a product',
          delete:'Delete permanently',
          no_sessions:'No sessions found',
          save:'Save'
        },
      },
      french:{
        translation: {
          title:'Sessions en cours',
          refresh:'Rafraîchir',
          cancel:'Annuler',
          add:'Ajouter un produit',
          edit:'Modifier un produit',
          delete:'Supprimer définitivement',
          no_sessions:'Aucune session en cours',
          save:'Enregistrer'
        }
      }
    }
  }
  
  connectedCallback() {
    super.connectedCallback();
    Session.sockets.boss.emit('joinRoom', 'sessions');
    Session.sockets.boss.on('session_change', (data) => {
      this.handleSessionChange(data);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    Session.sockets.boss.emit('leaveRoom', 'sessions');
  }

  handleSessionChange(data) {
    const { event, id, session } = data;
    switch (event) {
      case 'set':
      case 'touch':
        this.updateSession(id, session);
        break;
      case 'destroy':
        this.removeSession(id);
        break;
    }
  }

  updateSession(id, session) {
    if (!this.sessions) return;
    const index = this.sessions.findIndex(s => s._id === id);
    if (index !== -1) {
      this.sessions[index] = session;
    } else {
      this.sessions.push(session);
    }
    this.requestUpdate();
  }

  removeSession(id) {
    this.sessions = this.sessions.filter(s => s._id !== id);
    this.requestUpdate();
  }

  async loadData() {
    this.loading = true;
    const response = await Fetcher.get(`private/admin/website/sessions`);
    this.sessions = response?.data;
    this.loading = false;
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.loadData();
  }

  renderLoader() {
    if (!this.loading) return;
    return html`<sl-spinner></sl-spinner>`;
  }

  renderNoData() {
    if (this.loading || this.sessions?.length) return;
    return html`<div class="center">
      ${this._i18n('no_sessions')}
      <br/><br/>
    </div>`;
  }

  getFlag(countryCode) {
    const flag = emojiFlags[countryCode];
    return flag ? flag.emoji : '🏳️'; 
  }

  formatHourMinute(date) {
    return new Date(date).toLocaleTimeString();
  }


  renderData() {
    if (!this.sessions?.length) return;

    return html`
      <div class="list header">
        <div>&nbsp;</div>
        <div>IP</div>
        <div>Utilisateur</div>
        <div>Page</div>
        <div class="center">Dernière requête</div>
        <div class="center">Regarde<br/>le site</div>
        <div>Status</div>
        <div>&nbsp;</div>
      </div>
      
      ${this.sessions.map((data) => html`
        <div class="list list_item">
          <div>${this.getFlag(data.session.geo?.country)}</div>
          <div>${data.session.ip}</div>
          <div>${data.session.userPublicData?.email ? data.session.userPublicData.email : 'anonyme'}</div>
          <div>${data.session.currentPage}</div>
          <div>${this.formatHourMinute(data.session.lastSeen).toLocaleString()}</div>
          <div>${data.session.hidden ? 'Non' : 'Oui'}</div>
          <div>${data.session.status}</div>
          <!--
          <div>
            <sl-dropdown>
              <m-icon slot="trigger" name="more_vert"></m-icon>
              <sl-menu>
                <sl-menu-item @click="${() => this.editProduct(product)}">Modifier</sl-menu-item>
                <sl-menu-item @click="${() => this.deleteProductConfirm(product)}">Supprimer</sl-menu-item>
              </sl-menu>
            </sl-dropdown>
          </div>
          -->
        </div>
      `)}
      
    `;
  }

  render() {
    return html`
      <section-header backroute="/private" micon="bring_your_own_ip">
        ${this._i18n('title')}
        <div slot="right" class="flex_button">
          ${this.renderLoader()}
        </div>
      </section-header>
      <br/>
      ${this.renderNoData()}
      ${this.renderData()}
    `;
  }

}

customElements.define('page-admin-website-sessions', Page);