import { BaseElement, html, css } from 'UX';
import { Fetcher, Session, Notify } from 'Utils';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import localStyles from './styles.js';

class SecurityAuth extends BaseElement {
  static get styles() {
    return [
      localStyles,
      css`
        .buttons {
          margin-top:40px;
          text-align:right;
        }

        .flex {
          display:flex;
          justify-content:space-between;
          line-height:50px;
          width:100%;
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          title: 'Authentication',
          configure: 'Configure ways to sign in.',
          basic:'Current method',
          password: 'email and password',
          unknow: 'Unknow',
          update:'Update your password',
          created:'Created at',
          notify:{
            email:{
              title:'An email has been sent to you',
              message:'It contains a link that will allow you to change your password',
            }
          }
        }
      },
      french:{
        translation: {
          title: 'Authentification',
          configure: 'Configurez les méthodes d\'authentification.',
          basic:'Méthode actuelle',
          password: 'email et mot de passe',
          unknow: 'Inconnu',
          update:'Modifier votre mot de passe',
          created:'Créé le',
          notify:{
            email:{
              title:'Un email vous a été envoyé',
              message:'Il contient un lien qui vous permettra de changer votre mot de passe',
            }
          }
        }
      }
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.session = await Session.get();
    const response = await Fetcher.get('private/user/account/signingin');
    if (response && response.data) {
      this.signingin = response.data;
      this.signingin.createdDate = new Date(this.signingin.createdDate).toLocaleString();
      this.signingin.typeText = this.signingin.type === 'password' ? this._i18n('password') : this._i18n('unknow')
    }
    this.requestUpdate();
  }

  async handlePasswordUpdate(ev) {

    ev.stopPropagation();
    ev.preventDefault();

    const bt = this.shadowRoot.querySelector('#bt_change_password');
    bt.disabled = true;
    bt.loading = true;

    const response = await Fetcher.post('private/user/account/change_password');

    if (response.ok) {
      Notify.success({
        title:this._i18n('notify.email.title'),
        message:this._i18n('notify.email.message'),
        duration:10000
      });
    }

    bt.loading = false;
    bt.disabled = false;
  }


  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }


  render() {
    if (!this.session) return;
    return html`
      <section-header size="medium" micon="password">${this._i18n('title')}</section-header>
      <br/>
      <form @submit="${this.handleSubmitInfos}" class="hidden">
        <div class="comment">${this._i18n('configure')}</div><br/>
        <h3>${this._i18n('basic')} : ${this.signingin?.typeText}</h3>
        <sl-button @click="${this.handlePasswordUpdate}" id="bt_change_password">${this._i18n('update')}</sl-button>
        <br/>
        <!-- <h3>Authentification de deux facteurs</h3> -->
      </form>
    `
  }

}

customElements.define('form-user-account-security-auth', SecurityAuth);