import { css } from 'lit-element';

export default css`

/*
search-bar, sl-breadcrumb, sl-select, sl-option, sl-popup {
  --sl-font-size-big: 10px;
  --sl-font-size-medium: 12px;
  --sl-font-size-small: 12px;
  --sl-input-font-size-small: var(--sl-font-size-small);
}
*/
a {
  color:var(--a-color);
  text-decoration: none;
}

.content_page.nomargin {
  margin-top:0px;
}

.content_large.collapsed {
  margin-top:40px;
}

.content_page.collapsed {
  margin-top:80px;
}

.background {
  /*background-color:var(--page-background-color);*/
  background:var(--page-background-gradient);
  border-radius:5px;
  margin-left:5px;
  margin-right:10px;
  margin-top:7px;
  border:1px solid var(--nsys-blue3s);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.blur {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}


/* Téléphones portables (petits appareils) - iPhone 8 et similaires */
@media (max-width: 670px) {
  .img_icons {
    width:40px;
  }
}

/* Téléphones portables (grands appareils) - Entre iPhone 8 et tablettes */
@media (min-width: 670px) and (max-width: 900px) {
}

/* Tablettes en mode portrait */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Tablettes en mode paysage */
@media (min-width: 1024px) and (max-width: 1200px) {
}


@media print {
  .background {
    border:0px;
    background:initial;
  }

  .
}

          
`;
