import { PageElement, html, css } from 'UX';

class Error404 extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .error_container {
          padding:20px;
          text-align:center;
          font-size:1em;
        }

        .error_container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          title:'404 - Page not found',
          text:'The page you are looking for does not exist or no longer exists.',
          back:'Back',
        }
      },
      french:{
        translation: {
          title:'404 - Page introuvable',
          text:'La page que vous cherchez n\'existe pas ou n\'existe plus.',
          back:'Retour',
        }
      }
    }
  }

  render() {
    return html`
      <section-header micon="error">${this._i18n('title')}</section-header>
      <br/>
      <div class="error_container">
        <m-icon name="scan_delete"></m-icon>
        <p>${this._i18n('text')}</p>
        <br/>
        <sl-button @click="${this.goHome}" size="medium">${this._i18n('back')}</sl-button>
      </div>
    `;
  }

  goHome() {
    window.history.back();
  }

}

customElements.define('error-not-found', Error404);