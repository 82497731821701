import { LitElement, html, css } from 'lit-element';

/*
<context-menu>
  <m-icon slot="trigger" name="more_vert"></m-icon>
  <sl-menu>
    <sl-menu-item data-line-number="${count}" @click="${this.updateAttribute}">Modifier${iconModify}</sl-menu-item>
    <sl-menu-item data-line-number="${count}" @click="${this.deleteAttributeConfirm}">Supprimer${iconDelete}</sl-menu-item>
    <sl-divider></sl-divider>
    <sl-menu-item data-line-number="${count}" @click="${this.moveAttributeUp}" ?disabled="${count === 0}">Monter${iconMoveUp}</sl-menu-item>
    <sl-menu-item data-line-number="${count}" @click="${this.moveAttributeDown}" ?disabled="${count === this.item.attributes.length-1}">Descendre${iconMoveDown}</sl-menu-item>
  </sl-menu>
</context-menu>
*/


class ContextMenu extends LitElement {
  static get styles() {
    return css`
      .container {
        position:absolute;
        top:0px;
        left:0px;
        display:none;
        width:max-content;
      }

      .container.visible {
        display:block;
      }
    `;
  }

  firstUpdated() {
    this.container = this.shadowRoot.querySelector('.container');
  }

  toggleMenu(ev) {
    if (!this.open) {
      this.open = true;
      this.container.classList.add('visible');
      this.container.style.top = `${ev.pageY}px`;
      this.container.style.left = `${ev.pageX}px`;
    } else {
      this.open = false;
      this.container.classList.remove('visible');
    }
  }

  render() {
    return html`
      <slot name="trigger" @click="${this.toggleMenu}"></slot>
      <div class="container"><slot name="content"></slot></div>
    `;
  }
}

customElements.define('context-menu', ContextMenu);