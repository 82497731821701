import { BaseElement, html, css } from 'UX';

class Element extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1021;
          opacity: 0;
          /*
          background-color:#000000;
          transition: opacity 0.3s, backdrop-filter 10s, background-color 5s;
          */
          backdrop-filter: blur(0px) grayscale(0%);
          display:none;
        }

        :host(.visible) {
          display:block;
          opacity:1;
          z-index: 2;
          /*
          background-color:rgba(0,0,0,0.5);
          backdrop-filter: blur(0px)  grayscale(100%);
          */
        }
        `
    ];
  }

  static get properties() {
    return {
      open: { type: Boolean, reflect: true },
    }
  }

  constructor() {
    super();
    this.open = false;
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);    
  }

  updated(changedProperties) {
    if (changedProperties.has('open')) {
      if (this.open) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  show() {
    this.classList.add('visible');
    this.installListeners();
  }

  hide() {
    this.classList.remove('visible');
    this.removeListeners();
  }

  installListeners() {
    window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false });
    window.addEventListener('keydown', this.handleKeydown);

    this.initialDocumentOverflow = document.documentElement.style.overflow;
    this.initialBodyOverflow = document.body.style.overflow;

    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight = `${scrollbarWidth}px`;
  }

  removeListeners() {
    window.removeEventListener('mousewheel', this.handleMouseWheel);
    window.removeEventListener('keydown', this.handleKeydown);

    document.documentElement.style.overflow = this.initialDocumentOverflow;
    document.body.style.overflow = this.initialBodyOverflow;
    document.body.style.marginRight = '';
  }
  
  handleMouseWheel(ev) {
    const path = ev.composedPath();
    if (!path.includes(this)) return;
    ev.preventDefault();
  }

  handleKeydown(ev) {
    if (ev.key === 'Escape') {
      this.hide();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('overlay-show', () => { this.open = true } );
    window.addEventListener('overlay-hide', () => { this.open = false } );
  }
  
  render() {
    return html`&nbsp;`;
  }

}

customElements.define('modal-overlay', Element);