import { BaseElement, html, css } from 'UX';
import { Fetcher } from 'Utils';

class SelectMultiple extends BaseElement {
  static get styles() {
    return css`
      sl-option::part(base) {
        font-size:1em;
        font-family:Calibri;
        padding:3px;
      }
    `
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      items: { type: Array, reflect:false },
      name: { type: String },
      size: { type: String },
      values: { type: String },
      placeholder: { type: String },
      multiple: { type: Boolean },
    };
  }

  constructor() {
    super();
    this._logEnable = false;
    this.apiEndpoint = null; // private/admin/items
    this.loading = true;
    this.name ='';
    this.size = 'medium';
    this.placeholder = '';
    this.items = [];
    this.value = [];
    this.multiple = false;
  }

  async updated(changedProperties) {
    if (changedProperties.has('items')) {
      this._log.debug('updated: is array', Array.isArray(this.items), this.items);
      let value = '';
      if (!this.items) return;
      for (const v of this.items) {
        value+=v._id.toString() + ' ';
      }
      
      this.value = value.trim();
      this.requestUpdate();
    }
  }
  
  sortLoadedItems(items) {
    // override me
    return items;
  }

  async visibleCallback() {
    await this._loadItems();
  }
  
  async _loadItems() {
    this.loading = true;
    const response = await Fetcher.get(this.apiEndpoint);
    const loadedItems = response?.data;
    this.loadedItems = this.sortLoadedItems(loadedItems);
    this.displayedItems = JSON.parse(JSON.stringify(this.loadedItems));
    this.loading = false;
    this.select = this.select || this.shadowRoot.querySelector('sl-select');
  }

  async sendEvent() {
    this.items = this.records;
    this.dispatchEvent(new CustomEvent('items-changed', { detail: this.records }));
  }  

  _onChange(ev) {
    this.records = [];
    for (const value of ev.target.value) {
      this.records.push(this.loadedItems.find((item) => item._id === value));
    }
    this.sendEvent();
    this.requestUpdate();
  }

  render() {
    // Do not remove that, because select initiale value will not work without it
    if (!this.displayedItems) return html`<sl-select size="${this.size}" placeholder="..."></sl-select>`;
    if (!this.displayedItems.length) return html`Aucun élement disponible, veuillez en ajouter.`;
    
    return html`
      <sl-select placement="bottom" placeholder="${this.placeholder}"
        size="${this.size}"
        @sl-change=${this._onChange}
        value=${this.value}
        ${this.name ? `name="${this.name}"` : ''}
        multiple
        clearable
      >${this.displayItems()}
      </sl-select>
    `;
  }
}

export default SelectMultiple;