import { html, css } from 'UX';
import App from 'App';
import SelectMultiple from 'Components/SelectMultiple.js';

class SelectTags extends SelectMultiple {
  constructor() {
    super();
    this.apiEndpoint = 'private/admin/tags';
  }

  sortLoadedItems(items) {
    items.sort((a, b) => {
      if (a.title_fr < b.title_fr) return -1;
      if (a.title_fr > b.title_fr) return 1;
      return 0;
    });
    return items;
  }

  getItemTitle(item) {
    let key = `title_${App.config.lg}`;
    const title = item[key] || item.title_fr;
    return html`${title}`;
  }


  displayItems() {
    if (!this.loadedItems?.length) return '';

    return html`
      ${this.loadedItems.map((item) => html`
        <sl-option value="${item._id.toString()}">
          ${this.getItemTitle(item)}</div>          
        </sl-option>
      `)}
    `;
  }
  
  render() {
    // Do not remove that, because select initiale value will not work without it
    if (!this.loadedItems) return html`<sl-select size="${this.size}" placeholder="..."></sl-select>`;
    if (!this.loadedItems.length) return html`Aucun tag disponible, veuillez en ajouter.`;
    
    return html`
      <sl-select placement="bottom" placeholder="${this.placeholder}"
        size="${this.size}"
        @sl-change=${this._onChange}
        value=${this.value}
        ${this.name ? `name="${this.name}"` : ''}
        multiple
        clearable
      >${this.displayItems()}
      </sl-select>
    `;
  }

}

customElements.define('select-tags', SelectTags);