import { BaseElement, html, css } from 'UX';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Session } from 'Utils';
import './SessionIcon.js';
import './SettingsIcon.js';
import './DebugScreenSize.js';
import './Menus/Large.js';
import './Menus/Short.js';
import Logo from './Logo.svg.js';

class Header extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          
        }

        .header_container {
          position: fixed;
          width: 100%;
          top: 0px;
          z-index:1011;
          box-shadow:0px 5px 5px 0px #00000044;
        }
        
        .debug {
          position:fixed;
          left:10px;
          bottom:10px;
          background-color:black;
          z-index:1200;
          font-size:0.6em;
          padding:10px;
          color:white;
        }
        
        .header {
          position:relative;
          display:flex;
          justify-content: space-between;
          align-items: center;
          height:86px;
          /*transition: height 0.3s, background-color 0.3s;*/
          border-bottom:1px solid var(--nsys-blue3s);
          background-color: var(--nsys-blue5);
        }

        .header.collapsed {
          height: 46px;
          background-color: var(--nsys-blue5);
          border-bottom:1px solid var(--nsys-blue3s);
        }

        .short_menu_and_logo {
          display:flex;
          align-items: center;
          flex-grow: 1;
        }

        .red {
          line-height:20px;
          font-size:0.75em;
          font-weight:bold;
          font-family:Calibri, sans-serif;
          display:flex;
          justify-content: space-between;
          white-space:nowrap;
          text-decoration:none;
          height:20px;
          opacity:1;
          /*transition: opacity 0.3s;*/
          position:absolute;
          z-index:2;
        }

        .red a {
          color:white;
          text-decoration:none;
        }

        .red a:hover {
          text-decoration:underline;
        }

        .red.hidden {
          opacity:0;
        }

        .urgence {
          padding-left:10px;
          padding-right:20px;
          background-color:red;
          color:white;
          text-decoration:none;
          line-height:22px;
          font-weight:bold;
          font-family:Calibri, sans-serif;
        }

        .white {
          width:200px;
          color:black;
          flex-grow: 1;
          text-align:center;
        }

        .white a {
          color:var(--nsys-black);
          text-decoration:none;
          padding-right:30px;
        }

        .white a:hover {
          text-decoration:underline;
        }

        .separator {
          border-left: 15px solid red;
          width:0px;
          background-color:transparent;
          position:relative;
          transform:rotate(0.5turn);
        }

        .separator:before {
          content: '.';
          width: 1px;
          display: block;
          color: red;
          border-left: 40px solid red;
          position: absolute;
          left: -28px;
          transform: skewX(-45deg);
          top: 0px;
          overflow: hidden;
        }

        .logo {
          min-width:220px;
          flex-grow: 1;
        }

        .logo svg {
          height:60px;
          display:block;
          transition: height 0.3s;
        }

        .logo.collapsed svg {
          height:40px;
        }

        .title {
          white-space: nowrap;
          font-style:italic;
          line-height:40px;
          padding-left:30px;
          padding-right:30px;
          display:inline-block;
        }

        .center_container {
          width:100%;
          padding-left:20px;
          height:100%;
          display: flex;
          justify-content: flex-start;
        }

        .collapsed .center_container {
          padding-left:0px;
        }

        .right_container {
          display:flex;
          align-items: center;
          line-height:10px;
          /*margin-right:var(--fix-scroll-margin-right);*/
          margin-right:17px;
          max-width:220px;
          flex-grow: 1;
          justify-content: flex-end;
        }

        @media print {
          :host {
            position: relative;
            display:none;
          }
        
          .logo .st0 {
            fill: #495154;
          }

          .red {
            display:none;
          }
        }

        @media (max-width: 670px) {
          .logo {
            min-width:0px;
          }

          .red {
            font-size:0.6em;
          }
        }

    `];
  }

  constructor() {
    super();

    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handlePopstate = this.handlePopstate.bind(this);

    this._i18nResources = {
      english:{
        translation: {
          emergency: 'Emergency'
        }
      },
      french:{
        translation: {
          emergency: 'Urgence'
        }
      }
    }
  }

  handleThemeChanged(event) {
    this.logo = this.logo || this.shadowRoot.querySelector('#logo');
    const themeChangeEvent = new CustomEvent('theme-changed', {
      detail: { isDark: event.detail.isDark },
      bubbles: true, // L'événement traverse le DOM
      composed: true // L'événement traverse le Shadow DOM
    });
    this.dispatchEvent(themeChangeEvent);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('popstate', this.handlePopstate);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('popstate', this.handlePopstate);
  }

  firstUpdated() {
    super.firstUpdated();
    this.handleScroll();
  }

  handleResize() {
    this.handleScroll();
  }

  handleScroll() {
    this.redDiv = this.redDiv || this.shadowRoot.querySelector('.red');
    this.header = this.header || this.shadowRoot.querySelector('.header');
    this.logo = this.logo || this.shadowRoot.querySelector('.logo');
    let max = 0;
    if (window.innerWidth >= 670) {
      max = 20;
    }

    if (Session.isAuthenticated()) {
      this.header.classList.add('collapsed');
      this.logo.classList.add('collapsed');
      document.body.classList.add('css_connected');
      return;
    }

    //this.shadowRoot.querySelector('.debug').innerText = window.scrollY + ' >= ' + max;

    if (window.scrollY >= max) {
      //this.redDiv.classList.add('hidden');
      this.header.classList.add('collapsed');
      this.logo.classList.add('collapsed');
      document.body.classList.add('header_collapsed');
    } else {
      if (window.scrollY >= 0) {
        //this.redDiv.classList.remove('hidden');
        this.header.classList.remove('collapsed');
        this.logo.classList.remove('collapsed');
        document.body.classList.remove('header_collapsed');
      }
    }
  }

  handlePopstate() {
    this.requestUpdate();
  }

  render() {
    const showRed = !Session.isAuthenticated() && window.location.pathname === '/public';

    return html`
      <div class="header_container">
        <div class="header">
          <div class="short_menu_and_logo">
            <header-menu-short></header-menu-short>
            <a href="/" class="logo">${unsafeHTML(Logo)}</a>
          </div>
          <div class="center_container">
            <header-menu-large></header-menu-large>
          </div>
          <div class="right_container">
            <!-- <basket-icon></basket-icon> -->
            <session-icon></session-icon>
            <settings-icon></settings-icon>
          </div>
        </div>
        ${ showRed ? html`<div class="red">
          <div class="urgence">Site en cours de réalisation</div>
          <div class="separator">&nbsp;</div>
          <div class="white">&nbsp;</div>
        </div>` : ''}
      </div>
      <!-- <div class="debug">bla</div> -->
    `;
  }
}

customElements.define('app-header', Header);