import { BaseElement, html, css } from 'UX';

class SettingsIcon extends BaseElement { 
  static get styles() {
    return css`
      :host {
        display: block;
        margin-right:5px;
        --size:30px;
      }

      sl-button.round::part(base) {
        border-radius:50%;
        height:var(--size);
        width:var(--size);
      }

      sl-button.round::part(label) {
        padding:0px;
        display:flex;
        justify-content:center;
        align-items:center;
      }

      m-icon {
        font-size: 33px;
      }

      sl-card::part(body) {
        width:170px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        padding:0px;
        padding-bottom:10px;
        padding-left:10px;
        padding-right:10px;
        text-align:left;
        font-size:0.9em;
      }

      .big {
        font-size:50px;
        border-radius:50%;
        margin:0px;
        padding:0px;
      }

      .identity {
        font-size:12px;
        margin:0px;
        padding:0px;
      }

      .white {
        color:white;
      }

      .menu > * {
        width:100%;
        font-size:  0.9em;
      }

      p {
        border-bottom:1px solid var(--sl-color-gray-200);
        margin:0px;
        line-height:30px;
      }

      sl-menu-item::part(base) {
        font-size:0.5em;
      }
    `;
  }

  constructor() {
    super();
    this._i18nResources = {
      english:{
        translation: {
          language:'Language',
          theme:'Theme'
        }
      },
      french:{
        translation: {
          language:'Langue',
          theme:'Theme'
        }
      }
    }
  }

  hideDropdown() {
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.dropdown.hide();
  }

  render() {
    return html`
      <sl-dropdown>
        <m-icon slot="trigger" clickable name="more_vert" class="white round"></m-icon>
        <sl-card class="menu">
          <p>${this._i18n('language')}</p>
          <languages-selector></languages-selector>
          <p>${this._i18n('theme')}</p>
          <theme-selector></theme-selector>
        </sl-card>
      </sl-dropdown>
    `;
  }
}

customElements.define('settings-icon', SettingsIcon);