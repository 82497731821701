import { LitElement, html, css } from 'lit-element';
import { Router } from 'Router';
import RoutesManager from './routes.js';
import App from 'App';
import { Session, Fetcher } from 'Utils';
import 'web-particles';

import './styles/main.scss';

import '@shoelace-style/shoelace';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';

import './components/index.js';



class MainApp extends LitElement {
  static get styles() {
    return css`
      :host {
        display:block;
        --deco-border-color: #2581bf66;
        --deco-border-size: 1px;
        --deco-shadow-color: #16436633;
      }

      #outlet {
        width:100%;
        height:100%;
      }

      .bar {
        background-color:#2c2c2c36;
        display:flex;
        flex-grow:1;
      }

      .bar.left {
        flex-grow:1;
        border-right:var(--deco-border-size) solid var(--deco-border-color);
        box-shadow: inset -20px 10px 20px 0px var(--deco-shadow-color);
      }

      .bar.right {
        flex-grow:1;
        border-left:var(--deco-border-size) solid var(--deco-border-color);
        box-shadow: inset 20px 10px 20px 0px var(--deco-shadow-color);
      }

      .bar.center {
        max-width:994px;
        width:994px;
        background-color:var(--page-background-color);
        /*backdrop-filter:blur(10px);*/
        display:block;
        min-height:90vh;
        overflow:hidden;
      }

      .spacer {
        display:block;
        height:var(--bar-margin-top);
        width:100%;
      }

      @media print {

        .bar {
          background-color:initial;
        }

        .bar.left {
          border-right:0px;
        }

        .bar.right {
          border-left:0px;
        }

        .bar.center {
          background-color:initial;
        }

        #outlet {
          margin-top:0px;
        }

        web-particles {
          display:none;
        }

        web-particles canvas {
          display:none;
        }

      }

      scrollable-component {
        --viewport-overflow-x: hidden;
      }

      web-particles canvas {
        opacity:0 !important;
        transition:opacity 3s ease-in-out;
      }

      web-particles.visible canvas {
        opacity:0.5 !important;
      }

      /* iphone 8 */
      @media (max-width: 670px) {
        .bar.left,
        .bar.right {
          display:none;
        }
      }

      @media (max-width: 900px) {
        .bar.center {
          min-width:initial;
        }
        
      }
    `;
  }

  constructor() {
    super();
    this.reverseAnimation = false;
    this.handleNavigationChange = this.handleNavigationChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    //this._storeScrollbarWidth();
  }

  async firstUpdated() {
    this.router = App.router = new Router(this.shadowRoot.getElementById('outlet'));
    this.routesManager = new RoutesManager(this.router);
    window.addEventListener('vaadin-router-location-changed', this.handleNavigationChange);
    this.addParticles();
  }

  async addParticles() {
    setTimeout(async () => {
      await this.loadTSParticles();
      const particles = document.createElement('web-particles');
      particles.url = 'assets/webparticles.json';

      const appHeader = this.shadowRoot.querySelector('app-header');
      appHeader.insertAdjacentElement('afterend', particles);

      setTimeout(() => {
        particles.classList.add('visible');
      }, 200);
    }, 1000);
  }

  loadTSParticles() {
    return new Promise((resolve, reject) => {
      if (window.tsParticles) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = 'assets/tsparticles/2.5.3/tsparticles.bundle.min.js';
        script.onload = async () => {
          resolve();
        }
        script.onerror = () => {
          console.error('Failed to load tsparticles');
          resolve();
        }
        document.head.appendChild(script);
      }
    });
  }

  performUpdate() {
    (async () => {
      await Session.get();
      super.performUpdate();
    })();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('vaadin-router-location-changed', this.handleNavigationChange);
  }

  handleNavigationChange(e) {
    this.scroller = this.scroller || this.shadowRoot.querySelector('scrollable-component');
    this.scroller.viewport.scrollTo(0, 0);
    
    document.title = e.detail.location.route.title;

    let path = e.detail.location.route.path;
    if (path === '(.*)') path = document.location.pathname;

    clearTimeout(this.timeoutPageInform);
    this.timeoutPageInform = setTimeout(() => {
      Fetcher.get(`public/user/status/page?p=${encodeURIComponent(path)}`);
    }, 500);
  }

  _getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.position= 'fixed';
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.top = '70px';
    outer.style.left = '10px';
    outer.style.width = '100px';
    outer.style.height = '100px';
    outer.style.border = '1px solid red';
    outer.style.backgroundColor = 'green';
    outer.style.zIndex = '100000';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  _storeScrollbarWidth() {
    this._scrollbarWidth = this._scrollbarWidth || this._getScrollbarWidth();
    document.body.style.setProperty('--doc-scrollbar-width', `${this._scrollbarWidth-2}px`);
  }


  // https://github.com/Julien-Marcou/Scrollable-Component

  render() {
    return html`
      <scrollable-component style="height:100vh;">
        <style>
          scrollable-component {
            --viewport-overflow-x: hidden;
            --scrollbar-width: var(--scrollable-width);
            --scrollbar-thumb-border-radius: var(--scrollable-radius);
            --scrollbar-padding:var(--scrollable-padding);
            --scrollbar-thumb-border-width:var(--scrollable-thumb-border-width);
            --scrollbar-thumb-border-style:var(--scrollable-thumb-border-style);
            --scrollbar-thumb-fill-color:var(--scrollable-bar-thumb-fill-color);
            --scrollbar-track-fill-color:var(--scrollable-track-fill-color);
            --scrollbar-thumb-border-color:var(--scrollable-thumb-border-color);
          }
        </style>
        <app-header></app-header>     

        <div class="bar">
          <div class="bar left"></div>
          <div class="bar center">
            <div class="spacer"></div>
            <div id="outlet"></div>
          </div>
          <div class="bar right"></div>
        </div>
        <app-footer></app-footer>
        <modal-overlay></modal-overlay>
      </scrollable-component>
    `;
  }
}

customElements.define('main-app', MainApp);

// Attendre que les icônes soient chargées avant de rendre l'application
document.fonts.ready.then(() => {
  const app = document.createElement('main-app');
  document.body.appendChild(app);
});