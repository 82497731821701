import Notify from './Notify.js';
import Sleep from './Sleep.js';

const Fetcher = {

  basePath: '/api/v1',

  get:async(url) => {
    if (!url) {
      throw new Error('Fetcher: url is missing');
    }

    if (url[0] !== '/') {
      url = Fetcher.basePath + '/' + url;
    }

    const response = await fetch(url);
    return await Fetcher.handleErrorOrGetJson(url, response);
  },

  getToken:async() => {
    const url = Fetcher.basePath+'/public/token';
    const response = await fetch(url);
    const json = await Fetcher.handleErrorOrGetJson(url, response);
    return json?.tok;
  },

  doRequest:async(method, url, data = {}, form) => {
    if (!url) {
      throw new Error('Fetcher: url is missing');
    }

    if (url[0] !== '/') {
      url = Fetcher.basePath + '/' + url;
    }
      
    let attempts = 0;
    while (attempts < 3) {

      const token = await Fetcher.getToken();
      if (!token) {
        attempts++;
        // Attendre 200ms avant de réessayer
        await Sleep(200);
        continue;
      }

      // Let the time for server side session sync
      await Sleep(100);

      data.csrfToken = token;

      const response = await fetch(url, {
        method: method || 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.status !== 403) {
        return await Fetcher.handleErrorOrGetJson(url, response);
      }

      if (response.status === 403) {
        attempts++;
        // Attendre 200ms avant de réessayer
        await Sleep(200);
        continue;
      }
    }

    // Gérer le cas où après 3 tentatives, la requête échoue toujours
    throw new Error(`Fetcher: Failed to post after 3 attempts`);
    await Sleep(1000);
  },

  post:async(url, data = {}, form) => {
    return await Fetcher.doRequest('POST', url, data, form);
  },

  delete:async(url, data = {}, form) => {
    return await Fetcher.doRequest('DELETE', url, data, form);
  },

  put:async(url, data = {}, form) => {
    return await Fetcher.doRequest('PUT', url, data, form);
  },

  upload: async (url, formData) => {
    if (url[0] !== '/') {
      url = Fetcher.basePath + '/' + url;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        return await Fetcher.handleErrorOrGetJson(url, response);
      }

      return await response.json();
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error('Fetcher: Failed to upload file');
    }
  },

  postOrPut:async(url, data = {}, id) => {
    if (id) return await Fetcher.put(`${url}/${id}`, data);
    return await Fetcher.post(url, data);
  },

  handleErrorOrGetJson:async (url, response) => {
    // swagger or backend response
    if (response.status === 404) {
      Notify.errorCode("EHTTP_404", url);
      return;
    }

    // swagger response
    if (response.status === 405) {
      Notify.errorCode("EHTTP_405", url);
      return;
    }

    // unexpected backend response
    if (response.status === 504) {
      Notify.errorCode("EHTTP_504", url);
      return;
    }

    const json = await response.json();

    if (response.status !== 200) {
      
      if (json.error) {
        
        // application error

        // form validation error
        if (json.error === 'EVALIDATION_FAILED') {
          return json;
        }
        
        // other
        Notify.errorCode(json.error, url, json.detail);
        if (json.error === 'EREFRESH_TOKEN_ERROR') {
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        return;
      }

      if (json.name && json.status) {
        // swagger error
        Notify.error(`<br/><strong>Swagger error: ${json.status} ${json.name}</strong><br/><br/>${url}`, 'danger', 'bug_report');
        return;
      }

      throw new Error('unknown error', json);
    }

    return json;
  }
}

export default Fetcher;